import {
  IonContent,
  IonLoading,
  IonPage,
  IonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { Form } from "reactstrap";
import actions from "../../actions/auth";
import Container from "../../components/Shared/Container";
import Input from "../../components/Shared/Input";
import { authConstants } from "../../helpers/constants";

const Login: React.FC = (props: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);

  const dispatch = useDispatch();
  const onSubmit = async (e) => {
    e.preventDefault();
    props.login({ email, password }, props.history, props.cart.restaurant_id);
  };

  useEffect(() => {
    if (props.state.user) {
      setEmail("");
      setPassword("");
    }
  }, [props.state.user]);

  useEffect(() => {
    props.state.errorMsg &&
      props.history?.location?.pathname == "/auth/login" &&
      setShowToast(true);
  }, [props.state.errorMsg]);

  return (
    <IonPage>
      <IonContent>
        <Container>
          <div
            className="text-center text-muted mb-5 px-5 login-web"
            style={{ marginTop: "20%" }}
          >
            <img
              src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
              alt="Chicko Chicken"
              onClick={() => props.history.replace("/choose_restaurant")}
            />
          </div>
          <Form role="form" onSubmit={onSubmit} className="form-login-web">
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              placeholder="Email"
              icon={require("../../assets/img/icons/common/mail.svg").default}
            ></Input>
            <Input
              type="password"
              name="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
              icon={require("../../assets/img/icons/common/lock.svg").default}
            ></Input>

            {/* {props.state.errorMsg && (
              <UncontrolledAlert color="warning">
                <strong>{props.state.errorMsg}</strong>
              </UncontrolledAlert>
            )} */}
            <div
              className="text-left text-muted mb-4"
              style={{ lineHeight: "14px" }}
            >
              <span>
                <Link
                  style={{ color: "#707070", fontSize: 14 }}
                  to="/auth/recover"
                  replace={true}
                >
                  Forgot Password?
                </Link>
              </span>
            </div>
            <div className="text-center">
              <button
                type="submit"
                style={{
                  backgroundColor: "#0058df",
                  color: "#fff",
                  width: "100%",
                  borderRadius: 25,
                  height: 48,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                LOGIN
              </button>
            </div>
          </Form>

          <div className="text-center text-muted my-4">
            <span style={{ color: "#707070", fontSize: 15 }}>
              Don't have an account?
              <Link
                className="ml-2"
                style={{ color: "#0058df" }}
                to="/auth/register"
              >
                Sign up
              </Link>
            </span>
          </div>
        </Container>

        <IonLoading isOpen={props.state.request} message={"Please wait..."} />
        <IonToast
          isOpen={
            showToast && props.history?.location?.pathname == "/auth/login"
          }
          onDidDismiss={() => {
            dispatch({ type: authConstants.LOGIN_FAILURE });
            setShowToast(false);
          }}
          message={props.state.errorMsg}
          duration={3500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return { state: state.auth, cart: state.cart };
};

export default connect(mapStateToProps, actions)(Login);
