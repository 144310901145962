import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";

const FullPageLoader: React.FC = () => {
  return (
    <Row
      className="position-fixed fixed-top bg-white h-100"
      style={{ width: "100vw", marginLeft: 0 }}
    >
      <Col className="d-flex align-items-center">
        <Row style={{ width: "100vw", justifyContent: "center" }}>
          <img
            className="img-fluid"
            src={require("../../assets/img/icons/common/loading.gif").default}
            alt="loading"
            style={{
              transform: "scale(1)",
              height: 280,
            }}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default FullPageLoader;
