import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import cart from "./cart";
import info from "./info";

export default combineReducers({
  auth,
  cart,
  alert,
  info,
});
