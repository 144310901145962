import { IonContent, IonLoading, IonPage, IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { Form } from "reactstrap";
import actions from "../../actions/auth";
import Container from "../../components/Shared/Container";
import Input from "../../components/Shared/Input";
import { authConstants } from "../../helpers/constants";

const Register: React.FC = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    props.register({ name, email, phone, password }, props.history);
  };

  useEffect(() => {
    props.state.errorMsg &&
      props.history?.location?.pathname == "/auth/register" &&
      setShowToast(true);
  }, [props.state.errorMsg]);

  return (
    <IonPage>
      <IonContent>
        <Container>
          <div
            className="text-center text-muted mb-5 px-5 login-web"
            style={{ marginTop: "20%" }}
          >
            <img
              src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
              alt="Chicko Chicken"
              onClick={() => props.history.push("/choose_restaurant")}
            />
          </div>
          <Form role="form" onSubmit={onSubmit} className="form-login-web">
            <Input
              type="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
              placeholder="Name"
              icon={require("../../assets/img/icons/common/userIcon-register.svg").default}
            ></Input>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              placeholder="Email"
              icon={require("../../assets/img/icons/common/mail.svg").default}
            ></Input>
            {/* <Input
              type="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required={true}
              placeholder="Phone"
              icon={require("../../assets/img/icons/common/phone-register.svg")}
            ></Input> */}
            <PhoneInput
              country={"ca"}
              containerStyle={{
                height: 54,
                borderRadius: 20,
                width: "100%",
                boxShadow: "0px 2px 6px #00000033",
                marginBottom: 15,
              }}
              inputStyle={{
                height: "100%",
                width: "100%",
                border: "none",
                borderRadius: 20,
                fontSize: 16,
                color: "#333333",
              }}
              buttonStyle={{
                backgroundColor: "#fff",
                border: "none",
                marginLeft: 8,
                borderRadius: 20,
              }}
              searchStyle={{ width: "calc(100vw - 55px)" }}
              value={phone}
              onChange={(v) => setPhone(v)}
              inputProps={{
                name: "phone",
                required: true,
              }}
            />
            <Input
              type="password"
              name="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
              icon={require("../../assets/img/icons/common/lock.svg").default}
            ></Input>

            {/* {props.state.errorMsg && (
              <UncontrolledAlert color="warning">
                <strong>{props.state.errorMsg}</strong>
              </UncontrolledAlert>
            )} */}

            <div className="text-center">
              <button
                type="submit"
                style={{
                  backgroundColor: "#0058df",
                  color: "#fff",
                  width: "100%",
                  borderRadius: 25,
                  height: 48,
                  fontSize: 18,
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                SIGNUP
              </button>
            </div>
          </Form>

          <div className="text-center text-muted my-4">
            <span style={{ color: "#707070", fontSize: 15 }}>
              Already have an account?
              <Link
                className="ml-2"
                style={{ color: "#0058df" }}
                to="/auth/login"
              >
                Login
              </Link>
            </span>
          </div>
        </Container>

        <IonLoading isOpen={props.state.request} message={"Please wait..."} />
        <IonToast
          isOpen={
            showToast && props.history?.location?.pathname == "/auth/register"
          }
          onDidDismiss={() => {
            dispatch({ type: authConstants.LOGIN_FAILURE });
            setShowToast(false);
          }}
          message={props.state.errorMsg}
          duration={3500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return { state: state.auth };
};

export default connect(mapStateToProps, actions)(Register);
