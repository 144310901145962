import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import PrivateRoute from "../../helpers/privateRoutes";
import Login from "../auth/Login";
import Logout from "../auth/Logout";
import GiftCards from "../giftcards/GiftCards";
import Home from "./Home";
import Orders from "./Orders";
import Profile from "./Profile";
import Rewards from "./Reward";
import Search from "./Search";

const menu = require("../../assets/img/icons/common/menu.svg").default;
const menuActive = require("../../assets/img/icons/common/menuActive.svg").default;
const rewards = require("../../assets/img/icons/common/reward.svg").default;
const rewardsActive = require("../../assets/img/icons/common/gift-cart-active.svg").default;
const giftCart = require("../../assets/img/icons/common/gift-cart.svg").default;
const giftCartActive = require("../../assets/img/icons/common/reward-active.svg").default;
const orders = require("../../assets/img/icons/common/orders.svg").default;
const ordersActive = require("../../assets/img/icons/common/ordersActive.svg").default;
const profile = require("../../assets/img/icons/common/profile.svg").default;
const profileActive = require("../../assets/img/icons/common/profileActive.svg").default;

interface RouteProps {
  match?: any;
  history?: any;
}

const DashboardIndex: React.FC<RouteProps> = ({ match, history }) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    switch (history?.location?.pathname) {
      case "/dashboard/home":
        return setTab(0);
      case "/dashboard/rewards":
        return setTab(1);
      case "/dashboard/gift_cards":
        return setTab(2);
      case "/dashboard/orders":
        return setTab(3);
      case "/dashboard/profile":
        return setTab(4);
    }
    return () => { };
  }, [history?.location?.pathname]);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
        <Route path={`${match.url}/home`} component={Home} />
        <PrivateRoute
          path={`${match.url}/search`}
          component={Search}
          exact={true}
        />

        <PrivateRoute
          path={`${match.url}/rewards`}
          component={Rewards}
          exact={true}
        />
        <PrivateRoute
          path={`${match.url}/orders`}
          component={Orders}
          exact={true}
        />
        <PrivateRoute
          path={`${match.url}/gift_cards`}
          component={GiftCards}
          exact={true}
        />
        <PrivateRoute path={`${match.url}/profile`} component={Profile} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="ht-tab">
        <IonTabButton
          tab="home"
          href={`${match.url}/home`}
          onClick={() => setTab(0)}
          style={tab == 0 ? { color: "#0058df" } : { color: "#222222" }}
        >
          <IonIcon src={tab == 0 ? menuActive : menu} className="tab-menu" />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="rewards"
          href={`${match.url}/rewards`}
          onClick={() => setTab(1)}
          style={tab == 1 ? { color: "#0058df" } : { color: "#222222" }}
        >
          <IonIcon
            src={tab == 1 ? rewardsActive : rewards}
            className="tab-menu"
          />
          <IonLabel>Rewards</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="search"
          href={`${match.url}/gift_cards`}
          onClick={() => setTab(2)}
          style={tab == 2 ? { color: "#0058df" } : { color: "#222222" }}
        >
          <IonIcon
            src={tab == 2 ? giftCartActive : giftCart}
            className="tab-menu"
          />
          <IonLabel>Gift Cards</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="orders"
          href={`${match.url}/orders`}
          onClick={() => setTab(3)}
          style={tab == 3 ? { color: "#0058df" } : { color: "#222222" }}
        >
          <IonIcon
            src={tab == 3 ? ordersActive : orders}
            className="tab-menu"
          />
          <IonLabel>Orders</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="profile"
          href={`${match.url}/profile`}
          onClick={() => {
            setTab(4);
          }}
          style={tab == 4 ? { color: "#0058df" } : { color: "#222222" }}
        >
          <IonIcon
            src={tab == 4 ? profileActive : profile}
            className="tab-menu"
          />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default DashboardIndex;
