import { IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
// reactstrap components
import { Row } from "reactstrap";
import actions from "../../actions/cart";

interface PackCounterProps {
  dish: any;
  restaurant_id: number;
  hide_addon?: boolean;
  free?: any;
  stock?: any;
  quantity?: any;
}

const PackBoxCounter: React.FC<PackCounterProps> = ({
  dish,
  restaurant_id,
  hide_addon = false,
  free = false,
  stock,
  quantity,
}) => {
  const { id: dish_id, price, name, is_veg, addons_dish, points } = dish;
  //Selectors
  const getDishState = (store) => store.cart.dishes ?? [];

  const findDishById = (store, id) => {
    // const index = getDishState(store).findIndex((dish: any) => dish.id === id)
    // const index = getDishState(store).reduce((result, dish) => {
    //     if (dish.id == id)
    //         result.push(dish.id);
    //     return result
    // }, [])
    // let index: any = []
    // getDishState(store).map((dish: any) => {
    //     if (dish.id == id)
    //         index.push(dish.id);
    // })
    const index = getDishState(store)
      .map((dish, index) => (dish.id === id ? index : ""))
      .filter(String);
    const findIndex = getDishState(store)[index[0]];
    return index.length > 0 && findIndex ? findIndex.count * index.length : 0;
  };

  //Redux Hooks
  const count = useSelector((state: RootStateOrAny) =>
    findDishById(state, dish_id)
  );
  const dispatch = useDispatch();

  const increment = (id_cart) => {
    if (hide_addon == false && addons_dish.length > 0) {
      dispatch(
        actions.addons_show({
          dish_id,
          name,
          is_veg,
          price,
          restaurant_id,
          addons_dish,
          points,
        })
      );
    } else {
      const amount = isNaN(price) ? parseFloat(price.substr(1)) : price;
      dispatch(
        actions.increment({
          dish_id,
          name,
          is_veg,
          amount,
          restaurant_id,
          quantity: 1,
          id_cart,
          points,
        })
      );
    }
  };

  const decrement = (id_cart) => {
    if (count > 0)
      dispatch(actions.decrement({ dish_id, restaurant_id, id_cart }));
  };

  return (
    <>
      {/* <Row className="pack-box m-0">
                <Ripples className="col-4 p-0">
                    <Button className="pack-btn shadow-none m-0 px-0" onClick={decrement}></Button>
                </Ripples>
                <small className="pack-count col-4 p-0">{count}</small>
                {(hide_addon == false || (addons_dish && addons_dish.length < 1)) && (
                    <Ripples className="col-4 p-0">
                        <Button className="pack-btn-plus alt shadow-none m-0 px-2" onClick={increment}></Button>
                    </Ripples>
                )}
            </Row>
            {(hide_addon == false && addons_dish && addons_dish.length > 0) && (
                <Row className="m-0 mt-1">
                    <small className="w-100 p-0 text-warning text-center">Addons</small>
                </Row>
            )} */}

      {hide_addon == false || addons_dish ? (
        <>
          <div className="h-form-de-in__payment h-flex h-between a-center">
            <div
              className="h-pl-13"
              onClick={() => {
                dispatch(actions.changed());
                decrement(dish.id_cart);
              }}
            >
              <label className="h-font-22">-</label>
            </div>
            <div className="">
              <label className="number">{dish.count}</label>
            </div>

            {free ? (
              <div className="h-pr-10">
                <label className="h-font-22" style={{ color: "#cecece" }}>
                  +
                </label>
              </div>
            ) : (
              <div
                className="h-pr-10"
                style={quantity >= stock ? { color: "#cecece" } : {}}
                onClick={() => {
                  if (quantity >= stock) {
                  } else {
                    dispatch(actions.changed());
                    increment(dish.id_cart);
                  }
                }}
              >
                <label className="h-font-22">+</label>
              </div>
            )}
          </div>
          {/* <Row className="pack-box m-0">
            <Ripples className="col-4 p-0">
              <Button
                className="pack-btn shadow-none m-0 px-0"
                onClick={() => decrement(dish.id_cart)}
              ></Button>
            </Ripples>
            <small className="pack-count col-4 p-0">{dish.count}</small>

            <Ripples className="col-4 p-0">
              <Button
                className="pack-btn-plus alt shadow-none m-0 px-2"
                onClick={() => increment(dish.id_cart)}
              ></Button>
            </Ripples>
          </Row> */}
          {hide_addon == false && addons_dish && addons_dish.length > 0 && (
            <Row className="m-0 mt-1">
              <small className="w-100 p-0 text-warning text-center">
                Addons
              </small>
            </Row>
          )}
        </>
      ) : (
        <h4 onClick={() => decrement(dish.id_cart)}>
          <IonIcon
            icon={trashOutline}
            className="text-muted mr-1 align-text-top"
          />
        </h4>
      )}
    </>
  );
};

export default PackBoxCounter;
