import {
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonRouter
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { setInterval } from "timers";
import SavedAddress from "../../components/Addresses/saved-credit-cards";
import Header from "../../components/Shared/Header";

const ManageAddress: React.FC = (props: any) => {
  useEffect(() => { }, [props.auth.user?.stripe_id]);
  const router = useIonRouter();

  return (
    <IonPage>
      <Header
        name="Manange Cards"
        click={() => {
          if (props.history?.location?.state?.from) props.history.goBack();
          else router.push("/dashboard/profile", "back", "push");
        }}
      ></Header>

      <IonContent className="bg-secondary edit-profile">

          <div className="font-weight-bold col-12 pt-20" style={{ fontSize: 22 }}>
            Credit/Debit Cards
          </div>
          <small className="col-12" style={{ fontSize: 16, color: "#565656" }}>
            Select a card to set as default
          </small>
          <Row className="h-plr-15">
            <Col className="col-12">
              <SavedAddress history={props.history} />
            </Col>
          </Row>
   
        {/* <Button className="position-fixed fixed-bottom w-100 bg-success p-3 m-0 text-white" href="/add-address">Add new Address</Button> */}
      </IonContent>
    </IonPage>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAddress);
