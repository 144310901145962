import { IonAlert } from "@ionic/react";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import cartActions from "../../actions/cart";
import actions from "../../actions/info";
import chicko from "../../assets/img/intro/logo-chicko-chicken.png";

interface RouteProps {
  src?: string;
  name?: string;
  description?: string;
  price?: number;
  history?: any;
  restaurant?: any;
  address?: any;
  lat?: any;
  lng?: any;
}

const RestaurantMap: React.FC<RouteProps> = (props: any) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const {
    id,
    name,
    open_time,
    close_time,
    cooking_time,
    for_two,
    is_veg,
    phone,
    addresses,
    distance,
    time_change,
  } = props.restaurant;
  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 10,
        padding: "10px 15px",
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        position: "relative",
        boxShadow: "0px 2px 6px #00000033",
        zIndex: 1,
        height: 135,
      }}
      onClick={() => {
        setTimeout(function () {
          dispatch(cartActions.change_restaurant());
          dispatch(actions.set_restaurant_address(props.name));
          dispatch(actions.set_restaurant_id(id));
          dispatch(cartActions.change_restaurant_id(id));
          dispatch(cartActions.change_cooking_time(cooking_time));
          dispatch(cartActions.change_time_change(time_change));
          dispatch(
            cartActions.change_time({
              open_time,
              close_time,
            })
          );
          dispatch(actions.set_restaurant_street(props.address));
          dispatch(actions.set_restaurant_phone(phone));
          props.history.replace(`/dashboard/home`);
        }, 175);
      }}
    >
      <div
        style={{
          backgroundImage: "url(" + chicko + ")",
          height: 80,
          width: 80,
          backgroundSize: "95%",
          borderRadius: 15,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      <div style={{ width: "calc(100% - 90px)", padding: "0 10px 0 15px" }}>
        <EllipsisOne>{props.name}</EllipsisOne>
        <Ellipsis>{addresses[0].street}</Ellipsis>
        <Ellipsis>{Number(distance).toFixed(2)}km</Ellipsis>
        <a
          href={
            "http://maps.google.com/maps?saddr=" +
            ((props.info.type == "Delivery To"
              ? props.lat + "," + props.lng
              : props.auth.default_address
              ? props.auth.default_address.lat +
                "," +
                props.auth.default_address.lng
              : props.info.coordinate.lat + "," + props.info.coordinate.lng) +
              "&daddr=" +
              (props.info.type == "Delivery To"
                ? props.auth.default_address
                  ? props.auth.default_address.lat +
                    "," +
                    props.auth.default_address.lng
                  : props.info.coordinate.lat + "," + props.info.coordinate.lng
                : props.lat + "," + props.lng) +
              "&dirflg=d")
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            color: "#fff",
            background: "#5e72e4",
            padding: "5px 20px",
            borderRadius: 5,
            display: "inline-flex",
            marginTop: 5,
          }}
        >
          DIRECTION
        </a>
      </div>
      <Arrow></Arrow>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="ht-alert"
        header={"Confirm"}
        message={"Your cart will be reset. Do you want to change restaurant?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Ok",
            handler: (e) => {
              setTimeout(function () {
                dispatch(cartActions.change_restaurant());
                dispatch(actions.set_restaurant_address(props.name));
                dispatch(actions.set_restaurant_id(id));
                dispatch(cartActions.change_restaurant_id(id));
                dispatch(cartActions.change_cooking_time(cooking_time));
                dispatch(cartActions.change_time_change(time_change));
                dispatch(
                  cartActions.change_time({
                    open_time,
                    close_time,
                  })
                );
                dispatch(actions.set_restaurant_street(props.address));
                dispatch(actions.set_restaurant_phone(phone));
                props.history.replace(`/dashboard/home`);
              }, 175);
            },
          },
        ]}
      />
    </div>
  );
};

const Arrow = styled.div`
  postition: relative;
  width: 8px;
  &:after {
    content: "";
    position: absolute;
    height: 9px;
    width: 9px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(-45deg);
    top: calc(50%);
  }
`;
const EllipsisOne = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 18px;
  font-size: 16px;
  color: #000;
  font-weight: bold;
`;
const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
  font-size: 14px;
  color: #707070;
  margin: 3px 0;
`;
const Price = styled.div`
  font-size: 15px;
  margin-top: 5px;
`;

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    info: state.info,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantMap);
