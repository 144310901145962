import { IonAlert, IonContent, IonPage, IonToast } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import actions from "../../actions/auth";
import black from "../../assets/img/icons/common/black-giftcard.svg";
import blue from "../../assets/img/icons/common/blue-giftcard.svg";
import red from "../../assets/img/icons/common/red-giftcard.svg";
import yellow from "../../assets/img/icons/common/yellow-giftcard.svg";
import Header from "../../components/Shared/Header";
import { alertActions } from "../../actions/alert";
import authActions from "../../actions/auth";
import axios from "../../helpers/axiosInterceptor";

const GiftCardCheckOut: React.FC = (props: any) => {
  //const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [giftcard, setGiftcard] = useState<any>({});
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const [showToast, setShowToast] = useState(false);
  const textAreaRef = useRef<any>(null);
  const dispatch = useDispatch();
  const colors = [red, blue, yellow, black];
  const [showToast2, setShowToast2] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);

  useEffect(() => {
    // axios.get("coupons", {
    //   params: {
    //     id: props.match.params.id
    //   }
    // }).then((res) => {
    //   setGiftcard(res);
    //   console.log(res)
    // })

    if (props.history?.location?.state?.id) {
      setGiftcard(props.history?.location?.state);
    }
  }, [props.history?.location?.state]);

  const GiftCardPayment = () => {
    dispatch(alertActions.page_loader(true));

    axios
      .post("/stripe/gift_card_payment", {
        coupon_id: giftcard.id,
        stripe_id: props.auth.user.stripe_id,
        card_id: props.auth.default_card.card_id,
      })
      .then((data: any) => {
        if (data?.status == "fail") {
          dispatch(alertActions.page_loader(false));
          dispatch(alertActions.error(data?.message));
        } else {
          setShowToast2(true);
          dispatch(
            actions.gift_card_payment(
              {
                user_id: user.id,
                coupon_id: giftcard.id,
                trans_id: data.id,
                client_id: data.client_secret,
              },
              props.history
            )
          );
        }
      })
      .catch((e) => {
        dispatch(alertActions.page_loader(false));
        dispatch(alertActions.error(e.data.message));
      });
  };

  return (
    <IonPage>
      <Header
        name="Gift Cards Checkout"
        click={() => {
          props.history.goBack();
        }}
        back={true}
      ></Header>
      <IonContent className="ht-pt-80">
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Copied"
          animated
          duration={1000}
          cssClass="ht-toast"
          color="primary"
        />
        {giftcard ? (
          <GiftCard
            color={
              giftcard.discount == 20
                ? colors[0]
                : giftcard.discount == 30
                  ? colors[1]
                  : giftcard.discount == 50
                    ? colors[2]
                    : colors[3]
            }
          >
            <div className="p p1">
              <img
                src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
                alt=""
                className="logo"
              />
              <div className="description">
                You can use or share this gift card with a friend. This gift
                card can redeem{" "}
                <span className="coins">{giftcard.discount} COINS</span>
              </div>
            </div>
            <div className="p p2">
              <div className="price">
                <span>$</span>
                {Number(giftcard.price).toFixed(0)}
              </div>
              {/* <button className="purchase" onClick={()=>props.history.push('/gift_cards/')}>DETAILS</button> */}
            </div>
          </GiftCard>
        ) : null}
        <GiftCardGuide>
          <div className="title">Terms and Conditions</div>
          <div className="content">
            You can use this gift card to get coins or share it with a friend.
            Coins can be used in all Chicko Chicken restaurants. Orders with
            coins can also get reward points. This gift card cannot be exchanged
            for cash or return after purchased.
          </div>
        </GiftCardGuide>
        {props.auth.default_card ? (
          <Direction>
            <div className="visa">
              {"**** **** **** " + props.auth.default_card.card_number}
            </div>

            <button
              className="copy"
              onClick={() =>
                props.history.push("/credit_cards", {
                  from: "gift_cards_checkout",
                })
              }
            >
              CHANGE
            </button>
          </Direction>
        ) : (
          <Direction>
            <div className="visa">No Credit Card</div>

            <button
              className="copy"
              onClick={() =>
                props.history.push("/credit_card/add", {
                  from: "gift_cards_checkout",
                })
              }
            >
              ADD
            </button>
          </Direction>
        )}
        <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
          <Total>
            <div className="total">Total</div>
            <div className="money">${giftcard.price}</div>
          </Total>
          <div className="h-btn-alws-bottom button-inner">
            <button
              type="submit"
              className="btn-border-rdu-30"
              color="primary"
              onClick={() => {
                if (props.auth.default_card?.card_number) setShowAlert(true);
                else setShowAlert2(true);
              }}
            >
              PAY NOW
            </button>
          </div>
        </div>
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => {
            setShowToast2(false);
          }}
          message="Payment successful!"
          duration={3500}
          color="primary"
        />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="ht-alert"
          header={"Confirm"}
          //subHeader={'Information'}
          message={
            "Are you sure you want to pay <strong>$" +
            giftcard.price +
            "</strong> with this <strong>* " +
            props.auth.default_card?.card_number +
            "</strong> card?"
          }
          buttons={[
            {
              text: "Cancel",
            },
            {
              text: "OK",
              handler: () => {
                GiftCardPayment();
              },
            },
          ]}
        />
        <IonAlert
          isOpen={showAlert2}
          onDidDismiss={() => setShowAlert2(false)}
          cssClass="ht-alert"
          header={"Confirm"}
          //subHeader={'Information'}
          message={"Please add a credit card!"}
          buttons={[
            {
              text: "OK",
              handler: () => {
                props.history.push("/credit_card/add", {
                  from: "gift_cards_checkout",
                });
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};
const Total = styled.div`
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  .total {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  .money {
    font-size: 25px;
  }
`;
const Direction = styled.div`
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 15px;
  margin: 20px auto 10px;
  max-width: 374px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .visa {
    color: #5d5d5d;
  }
  .copy {
    width: 90px;
    height: 26px;
    background-color: #c81021;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
  }
`;

const GiftCardGuide = styled.div`
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 15px;
  margin: 20px auto 10px;
  max-width: 374px;
  .title {
    color: #707070;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-botton: 5px;
  }
  .content {
    color: #707070;
    text-align: justify;
  }
`;

const GiftCard = styled.div`
  border-radius: 20px;
  background-image: ${(props) => "url(" + props.color + ")"};
  background-size: 100% 100%;
  padding-left: 63px;
  padding-top: 5px;
  max-width: 374px;
  margin: 20px auto 10px;
  .p {
    display: flex;
    align-items: center;
    &.p1 {
      padding: 10px 0;
      .description {
        color: #707070;
        font-size: 13px;
        padding-right: 20px;
      }
      .coins {
        color: #3b7fd4;
        font-size: 15px;
        font-weight: bolder;
        margin-left: 3px;
      }
    }
    &.p2 {
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      .price {
        font-size: 35px;
        font-weight: bolder;
        span {
          font-size: 27px;
        }
      }
      .purchase {
        height: 35px;
        border-radius: 30px;
        background-color: ${(props) =>
    props.tab == 0 ? "#FF8039" : "#C81021"};
        color: #fff;
        padding: 0 20px;
        font-size: 15px;
      }
    }
  }
  .logo {
    height: 40px;
    margin: 10px 17px 10px 18px;
  }
`;

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardCheckOut);
