import { IonContent, IonPage, useIonViewDidLeave } from "@ionic/react";
import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { Input, InputGroup } from "reactstrap";
import styled from "styled-components";
import actionsLocation from "../../actions/info";
import Header from "../../components/Shared/Header";

const AddAddress: React.FC = (props: any) => {
  const [searchAddress, setSearchAddress] = useState("");
  const dispatch = useDispatch();

  const handleSelect = (address) => {
    setSearchAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        props.history.push({
          pathname: "/add-address",
          state: {
            address: address,
            coordinate: latLng,
            redirectTo: "/dashboard/home",
          },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  useIonViewDidLeave(() => {
    setSearchAddress("");
  });

  const reverseGeoCoder = async (lat: number, lng: number, apiKey) => {
    dispatch(actionsLocation.set_location({ lat, lng }));
    await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=" +
        apiKey
    )
      .then((response) => response.json())
      .then((responseJson) => {
        const results = responseJson.results;
        results.some((c) => {
          let foundAddress = false;
          switch (c.types[0]) {
            case "street_address":
              dispatch(actionsLocation.set_address(c.formatted_address));
              foundAddress = true;
              break;
            case "route":
            case "locality":
              dispatch(actionsLocation.set_address(c.formatted_address));
              foundAddress = true;
              break;
            default:
              break;
          }
          return <Redirect to={`/add-address`} />;
        });
      });
  };

  const handleChange = (address) => {
    setSearchAddress(address);
  };

  return (
    <IonPage>
      <Header name="Add Address" click={() => props.history.goBack()}></Header>
      <IonContent>
        <div className="h-flex">
          <div className="col-12 pt-15">
            <div
              style={{
                height: 55,
                borderRadius: 35,
                backgroundColor: "rgb(255, 255, 255)",
                marginBottom: 20,
                padding: "0 0px 0 45px",
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
              onClick={() => {
                props.history.push({
                  pathname: "/add-address",
                  state: {
                    address: props.info.address,
                    coordinate: props.coordinate,
                    redirectTo: "/dashboard/home",
                  },
                });
              }}
            >
              <div className="h-find_icon" style={{ top: "auto", left: 15 }}>
                <img
                  className=""
                  src={require("../../assets/img/icons/common/map_position.svg").default}
                  style={{ height: 17 }}
                />
              </div>
              Use current position
            </div>
            <PlacesAutocomplete
              value={searchAddress}
              onChange={handleChange}
              onSelect={handleSelect}
              debounce={1000}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <InputGroup
                    style={{
                      height: 55,
                      borderRadius: 35,
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="h-find_icon" style={{ top: 13 }}>
                      <img
                        className=""
                        src={require("../../assets/img/icons/common/find_icon.svg").default}
                      />
                    </div>
                    <Input
                      style={{
                        color: "#000",
                        margin: "0px 35px 0px 45px",
                        fontSize: 16,
                        height: 35,
                        border: "none",
                        backgroundColor: "white",
                      }}
                      placeholder="Search"
                      type="text"
                      {...getInputProps({
                        placeholder: "Enter area,street name, locality...",
                      })}
                    />

                    {searchAddress && (
                      <div
                        className="h-remove_icon"
                        onClick={() => setSearchAddress("")}
                        style={{ top: 13 }}
                      >
                        <img
                          src={require("../../assets/img/icons/common/remove_text_icon.svg").default}
                        />
                      </div>
                    )}
                  </InputGroup>
                  <div style={{ boxShadow: "none" }}>
                    {suggestions.map((suggestion, idx) => {
                      //setListSuggestions(suggestion);
                      return (
                        <div
                          className="h-form-restaurant h-bgr-fff h-mt-10"
                          key={idx}
                          style={idx == 0 ? { marginTop: 30 } : {}}
                        >
                          <div className="h-flex h-between">
                            <div
                              className="h-flex h-flex__nowrap"
                              style={{
                                width: "calc(100% - 30px)",
                                padding: "0 15px",
                              }}
                            >
                              <div className="h-flex a-center">
                                <img
                                  src={require("../../assets/img/icons/common/location_icon.svg").default}
                                />
                              </div>
                              <div
                                className="h-restaurant-address h-pl-10 pt-10 h-pb-10"
                                {...getSuggestionItemProps(suggestion)}
                                style={{ display: "flex", width: "100%" }}
                              >
                                <label
                                  className="h-bold"
                                  style={{ display: "flex", width: "100%" }}
                                >
                                  {" "}
                                  {suggestion.formattedSuggestion.mainText}
                                </label>
                                <label className="h-color-707070">
                                  {suggestion.formattedSuggestion.secondaryText}
                                </label>
                              </div>
                            </div>
                            <div
                              className="h-flex a-center h-select__custom h-mr-3"
                              style={{ width: 30, position: "relative" }}
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            {/* <div className=" h-flex h-between h-form-search-address pb-15">
              <div className="h-find_icon">
                <img
                  className=""
                  src={require("../../assets/img/icons/common/find_icon.svg")}
                />
              </div>
              <div className="h-remove_icon ">
                <img
                  src={require("../../assets/img/icons/common/remove_text_icon.svg")}
                />
              </div>
              <input
                className="h-max h-input-form__search__address"
                type="text"
                name=""
                id=""
                placeholder=""
              />
            </div>
            <div className="h-form-restaurant h-bgr-fff h-mt-10">
              <div className="h-flex h-between">
                <div className="col-10 h-flex h-flex__nowrap">
                  <div className="h-flex a-center">
                    <img
                      src={require("../../assets/img/icons/common/location_icon.svg")}
                    />
                  </div>
                  <div className="h-restaurant-address h-pl-10 pt-10 h-pb-10">
                    <label className="h-bold">Burnaby (Edmonds)</label>
                    <label className="h-color-707070">
                      7343 Edmonds St,Burnaby
                    </label>
                  </div>
                </div>
                <div className="col-2 h-flex a-center h-select__custom h-mr-3"></div>
              </div>
            </div>
            <div className="h-form-restaurant h-bgr-fff h-mt-10">
              <div className="h-flex h-between">
                <div
                  className=" h-flex h-flex__nowrap"
                  style={{ width: "calc(100% - 30px)", padding: "0 15px" }}
                >
                  <div className="h-flex a-center">
                    <img
                      src={require("../../assets/img/icons/common/location_icon.svg")}
                    />
                  </div>
                  <div className="h-restaurant-address h-pl-10 pt-10 h-pb-10">
                    <label className="h-bold">Burnaby (Edmonds)</label>
                    <label className="h-color-707070">
                      7343 Edmonds St,Burnaby
                    </label>
                  </div>
                </div>
                <div
                  className="h-flex a-center h-select__custom h-mr-3"
                  style={{ width: 30, position: "relative" }}
                ></div>
              </div>
            </div>
            <div className="h-form-restaurant h-bgr-fff h-mt-10">
              <div className="h-flex h-between">
                <div className="col-10 h-flex">
                  <div className="h-flex a-center">
                    <img
                      src={require("../../assets/img/icons/common/location_icon.svg")}
                    />
                  </div>
                  <div className="h-restaurant-address h-pl-10 pt-10 h-pb-10">
                    <label className="h-bold">Burnaby (Edmonds)</label>
                    <label className="h-color-707070">
                      7343 Edmonds St,Burnaby
                    </label>
                  </div>
                </div>
                <div className="col-2 h-flex a-center h-select__custom h-mr-3"></div>
              </div>
            </div>
            <div className="h-form-restaurant h-bgr-fff h-mt-10">
              <div className="h-flex h-between">
                <div className="col-10 h-flex h-flex__nowrap">
                  <div className="h-flex a-center">
                    <img
                      src={require("../../assets/img/icons/common/location_icon.svg")}
                    />
                  </div>
                  <div className="h-restaurant-address h-pl-10 pt-10 h-pb-10">
                    <label className="h-bold">Burnaby (Edmonds)</label>
                    <label className="h-color-707070">
                      7343 Edmonds St,Burnaby
                    </label>
                  </div>
                </div>
                <div className="col-2 h-flex a-center h-select__custom h-mr-3"></div>
              </div>
            </div>
            <div className="h-form-restaurant h-bgr-fff h-mt-10">
              <div className="h-flex h-between">
                <div className="col-10 h-flex h-flex__nowrap">
                  <div className="h-flex a-center">
                    <img
                      src={require("../../assets/img/icons/common/location_icon.svg")}
                    />
                  </div>
                  <div className="h-restaurant-address h-pl-10 pt-10 h-pb-10">
                    <label className="h-bold">Burnaby (Edmonds)</label>
                    <label className="h-color-707070">
                      7343 Edmonds St,Burnaby
                    </label>
                  </div>
                </div>
                <div className="col-2 h-flex a-center h-select__custom h-mr-3"></div>
              </div>
            </div>
            <div className="h-form-restaurant h-bgr-fff h-mt-10">
              <div className="h-flex h-between">
                <div className="col-10 h-flex h-flex__nowrap">
                  <div className="h-flex a-center">
                    <img
                      src={require("../../assets/img/icons/common/location_icon.svg")}
                    />
                  </div>
                  <div className="h-restaurant-address h-pl-10 pt-10 h-pb-10">
                    <label className="h-bold">Burnaby (Edmonds)</label>
                    <label className="h-color-707070">
                      7343 Edmonds St,Burnaby
                    </label>
                  </div>
                </div>
                <div className="col-2 h-flex a-center h-select__custom h-mr-3"></div>
              </div>
            </div> */}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
  font-size: 14px;
  color: #707070;
`;
const Arrow = styled.div`
  display: block;
  height: 9px;
  width: 9px;
  border-right: 1px solid #858585;
  border-bottom: 1px solid #858585;
  transform: rotate(45deg);
  position: relative;
  top: -5px;
`;

const mapStateToProps = (state) => {
  return {
    api_key: state.info.api_key,
    coordinate: state.info.coordinate,
    info: state.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
