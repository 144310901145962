import React from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import actions from "../../actions/cart";
import actionsLocation from "../../actions/info";

interface RouteProps {
  src?: string;
  name?: string;
  description?: string;
  price?: any;
  history?: any;
  addon?: any;
  match?: any;
  restaurant_id?: string;
  hide_addon?: any;
  addons_category_name?: string;
  id?: any;
  is_used?: any;
  is_my?: any;
  id_user_reward?: any;
  possible?: boolean;
}

const RewardCart: React.FC<RouteProps> = ({
  restaurant_id,
  hide_addon = false,
  ...props
}) => {
  const { id, price, name, is_veg, addons_dish, description, image, points } =
    props.addon;

  //Selectors
  const getDishState = (store) => store.cart.dishes ?? [];

  const findDishById = (store, id) => {
    // const index = getDishState(store).findIndex((dish: any) => dish.id === id)
    // const index = getDishState(store).reduce((result, dish) => {
    //     if (dish.id == id)
    //         result.push(dish.id);
    //     return result
    // }, [])
    // let index: any = []
    // getDishState(store).map((dish: any) => {
    //     if (dish.id == id)
    //         index.push(dish.id);
    // })
    const index = getDishState(store)
      .map((dish, index) => (dish.id === id ? index : ""))
      .filter(String);
    const findIndex = getDishState(store)[index[0]];
    return index.length > 0 && findIndex ? findIndex.count * index.length : 0;
  };

  //Redux Hooks
  const count = useSelector((state: RootStateOrAny) => findDishById(state, id));
  const dispatch = useDispatch();

  const increment = () => {
    dispatch(actionsLocation.set_addon_type(props.addons_category_name));
    if (hide_addon == false) {
      dispatch(
        actions.addons_show({
          dish_id: id,
          name,
          is_veg,
          price,
          restaurant_id,
          addons_dish,
          description,
          free: true,
          image,
          points,
          id_user_reward: props.id_user_reward,
        })
      );
    } else {
      const amount = isNaN(price) ? parseFloat(price.substr(1)) : price;
      dispatch(
        actions.increment({
          id,
          name,
          is_veg,
          amount,
          restaurant_id,
          image,
          points,
          id_user_reward: props.id_user_reward,
        })
      );
    }
  };

  const decrement = () => {
    if (count > 0) dispatch(actions.decrement({ id, restaurant_id }));
  };

  return (
    <Hflex>
      <div onClick={props.possible ? increment : () => {}}>
        <HflexBetween>
          <Hero>
            <HeroSpan1>
              <div
                style={{
                  backgroundImage: "url(" + props.src + ")",
                  width: 120,
                  height: 120,
                  backgroundSize: "cover",
                  borderRadius: "50%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  opacity: props.possible ? 1 : 0.38,
                }}
              ></div>
            </HeroSpan1>
            <HeroBold>{props.name}</HeroBold>
            <HeroSpan2>
              {props.price ? props.price + " POINTS" : null}
            </HeroSpan2>
          </Hero>
        </HflexBetween>
      </div>
      {props.is_used ? (
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 30px)",
            height: "calc(100% - 15px)",
            backgroundColor: "#fff",
            top: 15,
            left: 15,
            borderRadius: 10,
            opacity: 0.7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#fff",
              fontWeight: 14,
              height: 58,
              width: 58,
              borderRadius: 29,
              backgroundColor: "#000",
              lineHeight: "58px",
              textAlign: "center",
              position: "relative",
              bottom: 8,
              opacity: 0.4,
            }}
          >
            USED
          </span>
        </div>
      ) : null}
    </Hflex>
  );
};
const Hflex = styled.div`
  padding: 14px 7px 0px 7px;
  width: 50%;
  position: relative;
`;

const HflexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  --color: var(--ion-color-step-550, #737373);
  border-radius: 10px;
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 1%) 0px 3px 1px -2px, rgb(0 0 0 / 1%) 0px 2px 2px 0px,
    rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  width: 100%;
  padding: 0 15px;
  height: 223px;
`;
const HeroSpan1 = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const HeroBold = styled.label`
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
`;
const HeroSpan2 = styled.label`
  font-weight: bold;
  color: #045ade;
  padding-bottom: 20px;
  padding-top: 5px;
  font-size: 12px;
`;
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardCart);
