import axios from "../helpers/axiosInterceptor";
import { authConstants } from "../helpers/constants";
import { alertActions } from "./alert";
import actions from "./auth";

export const login = (formData, history, res_id) => async (dispatch) => {
  try {
    dispatch(alertActions.page_loader(true));
    const response = await axios.post("/login", formData);
    const user_data = response.data;

    await dispatch({ type: authConstants.LOGIN_SUCCESS, payload: user_data });

    const fcm_token = localStorage.getItem("fcm_token");
    if (fcm_token) {
      await axios.post("/user/fcm_token", { fcm_token });
    }

    if (user_data.user.phone_verified) {
      if (res_id) history.replace("/dashboard/home");
      else history.replace("/choose_restaurant");
      setTimeout(() => {
        dispatch(alertActions.page_loader(false));
      }, 1000);
    } else history.replace("/auth/verify");
  } catch (e: any) {
    // const response = e.response.data;
    dispatch({
      type: authConstants.LOGIN_FAILURE,
      payload: e.data.errors,
    });
    dispatch(alertActions.page_loader(false));
  }
};

export const register = (formData, history) => async (dispatch) => {
  try {
    dispatch(alertActions.page_loader(true));
    const response = await axios.post("/register", formData);
    const data = response.data;
    dispatch({ type: authConstants.REGISTER_SUCCESS, payload: data });
    dispatch(alertActions.page_loader(false));

    if (data.user.verification_required) {
      history.replace("/auth/verify");
    } else history.replace("/");
  } catch (e: any) {
    dispatch({
      type: authConstants.REGISTER_FAILURE,
      payload: e.data.errors,
    });
    dispatch(alertActions.page_loader(false));
  }
};

export const verify = (formData, history) => async (dispatch) => {
  try {
    dispatch(alertActions.page_loader(true));
    const response = await axios.post("/verify_otp", formData);
    // const auth_token = response.data.auth_token;

    // dispatch({ type: authConstants.VERIFY_SUCCESS, payload: auth_token });

    await dispatch({ type: authConstants.LOGOUT });
    setTimeout(() => {
      history.push("/auth/login");
      dispatch(
        alertActions.success("Verify successfull, please login to continue!")
      );
      setTimeout(() => {
        dispatch(alertActions.page_loader(false));
      }, 500);
    }, 1000);
  } catch (e: any) {
    //const response = e.response.data;
    dispatch({ type: authConstants.VERIFY_FAILURE, payload: e.data.errors });
    dispatch(alertActions.page_loader(false));
  }
};

export const logout = () => async (dispatch) => {
  dispatch(alertActions.page_loader(true));
  await dispatch({ type: authConstants.LOGOUT });
  setTimeout(() => {
    window.location.pathname = "/auth/login";
  }, 100);
};

function update_avatar(payload) {
  return { type: authConstants.UPDATE_AVATAR, payload: payload };
}

function update_point_percentage(payload) {
  return { type: authConstants.UPDATE_POINT_PERCENTAGE, payload: payload };
}

function update_user(payload) {
  return { type: authConstants.UPDATE_USER, payload: payload };
}

function set_address(payload) {
  return { type: authConstants.SET_DEFAULT_ADDRESS, payload: payload };
}

function set_card(payload) {
  return { type: authConstants.SET_DEFAULT_CARD, payload: payload };
}

function set_fcm_token(payload) {
  return { type: authConstants.SET_FCM_TOKEN, payload: payload };
}

const gift_card_payment = (formData, history) => async (dispatch) => {
  try {
    dispatch(alertActions.page_loader(true));

    await axios.post("/coupon/payment", formData);
    dispatch({ type: authConstants.GIFT_CARD_PAYMENT, payload: "" });
    history.push({ pathname: "/dashboard/gift_cards", tab: "1" });
  } catch (e: any) {
    const response = e.response.data;
  }
};

const redeem = (formData, history) => async (dispatch) => {
  try {
    dispatch(alertActions.page_loader(true));
    const verify = await axios.post("/coupon/verify", formData);
    if (verify) {
      dispatch({ type: authConstants.REDEEM, payload: verify.data });
      dispatch(alertActions.page_loader(false));
    } else {
      dispatch(alertActions.page_loader(false));
    }
  } catch (e: any) {
    dispatch(alertActions.page_loader(false));
    if (e.data.message) {
      dispatch(alertActions.error(e.data.message));
    } else dispatch(alertActions.error(e.data.errors));
  }
};

export default {
  login,
  verify,
  register,
  logout,
  update_avatar,
  set_address,
  set_fcm_token,
  gift_card_payment,
  redeem,
  update_user,
  set_card,
  update_point_percentage,
};
