import { IonAlert, IonContent, IonFab, IonLabel, IonPage } from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import actions from "../../actions/auth";
// reactstrap components
import Header from "../../components/Shared/Header";

const EditProfile: React.FC = (props: any) => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setName(props.user?.name ? props.user?.name : "");
    setPhone(props.user?.phone ? props.user?.phone : "");
    return () => { };
  }, [props.user]);

  const update_profile = () => {
    axios
      .post("/user/profile/update", {
        name,
        phone,
      })
      .then((res) => {
        setShowAlert(true);
        dispatch(
          actions.update_user({
            name,
            phone,
          })
        );
      });
  };
  return (
    <IonPage>
      <Header name="User Details" click={() => props.history.goBack()}></Header>
      <IonContent className="edit-profile">
        <div className="h-flex pt-20">
          <div className="col-12">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              Name
            </IonLabel>
            <input
              type="text"
              className="h-input-form2"
              placeholder="Enter your name"
              value={name}
              name="name"
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="h-flex pt-20">
          <div className="col-12">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              Phone Number
            </IonLabel>
            <input
              type="text"
              className="h-input-form2"
              name="phone"
              placeholder="(012) 345-6789"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></input>
          </div>
        </div>
        {/* <div className="h-flex pt-20">
          <div className="col-12">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              Card Number
            </IonLabel>
            <input
              type="number"
              className="h-input-form2"
              placeholder="**** **** **** ****"
            ></input>
          </div>
        </div>
        <div className="h-flex pt-20">
          <div className="col-6">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              {" "}
              Exp
            </IonLabel>
            <IonItem className="h-input-form">
              <IonDatetime
                displayFormat="YYYY/MM/DD"
                placeholder="YYYY/MM/DD"
                value={selectedDate}
                onIonChange={(e) => setSelectedDate(e.detail.value!)}
                style={{ height: 50, position: "relative", left: -16, top: 4 }}
              ></IonDatetime>
            </IonItem>
          </div>
          <div className="col-6">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              CVC
            </IonLabel>
            <input
              type="number"
              className="h-input-form2"
              placeholder="***"
            ></input>
          </div>
        </div> */}
        <br />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="ht-alert"
          header={"Alert"}
          message={"Update successfully"}
          buttons={[
            {
              text: "OK",
              handler: () => {
                props.history.goBack();
              },
            },
          ]}
        />
        <IonFab
          vertical="bottom"
          horizontal="end"
          slot="fixed"
          className="ht-ion-fab"
        >
          <div className="h-btn-alws-bottom button-inner">
            <button
              className="btn-border-rdu-30"
              color="primary"
              onClick={update_profile}
            >
              SAVE
            </button>
          </div>
        </IonFab>
      </IonContent>

    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
