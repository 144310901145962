import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import Addons from "../../components/MenuCards/addons";
import Header from "../../components/Shared/Header";
import ProgressBar from "../../components/Shared/ProgressBar";
import RewardCart from "../../components/Shared/RewardCart";
import Reward from "../../components/Shared/Rewards";
import FeaturedSkeletonText from "../../components/skeleton_text/featured_restaurant";
import axios from "../../helpers/axiosInterceptor";

const Rewards: React.FC = (props: any) => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const dispatch = useDispatch();
  const [user, setUser] = useState<any>({});
  const [tab, setTab] = useState(0);
  const [dishesList, setDishesList] = useState([]);
  const [myReward, setMyReward] = useState<any>([]);
  const [statusMyReward, setStatusMyReward] = useState<any>([]);
  const [loaded, setLoaded] = useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const [page, setPage] = useState(1);

  async function fetchData(temp) {
    axios
      .get("/reward/user?page=" + temp)
      .then(async (res: any) => {
        if (res && res.data && res.data.length > 0) {
          setMyReward([...myReward, ...res.data]);
          setDisableInfiniteScroll(res.data.length < 10);
        } else {
          setDisableInfiniteScroll(true);
        }
      })
      .catch((err) => console.error(err));
  }

  async function searchNext($event: CustomEvent<void>) {
    let temp = page + 1;
    setPage(temp);
    await fetchData(temp);

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  //const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    setLoaded(false);
    axios
      .get(`/rewards`)
      .then((res: any) => {
        setDishesList(res);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setLoaded(true);
      });
    return () => {};
  }, [props.restaurant_id]);

  useIonViewDidEnter(() => {
    setLoaded(false);
    axios
      .get(`/rewards`)
      .then((res: any) => {
        setDishesList(res);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setLoaded(true);
      });
    let cart, dishes: any;
    const storage = JSON.parse(localStorage.getItem("persist:storage") as any);
    if (storage) {
      cart = JSON.parse(storage.cart);
      dishes = cart.dishes;
    }

    let used_points = 0;
    dishes?.forEach((val) => {
      if (val.free) {
        used_points += val.points;
      }
    });
    axios.get("/user").then((res) => {
      res.data.rewards -= used_points;
      setUser(res.data);
      axios.get("/reward/user").then((res: any) => {
        setMyReward(res.data);
        setLoaded(true);
        setTimeout(() => {
          dispatch(alertActions.page_loader(false));
        }, 100);
      });
    });
  });

  useEffect(() => {
    let used_points = 0;
    props.cart.dishes?.forEach((val) => {
      if (val.free) {
        used_points += val.points;
      }
    });
    axios.get("/user").then((res) => {
      res.data.rewards -= used_points;
      setUser(res.data);
      axios.get("/reward/user").then((res: any) => {
        setMyReward(res.data);
        setLoaded(true);
        setTimeout(() => {
          dispatch(alertActions.page_loader(false));
        }, 100);
      });
    });
  }, [props.cart.dishes.length]);

  useEffect(() => {
    if (props.location.tab) {
      setTab(props.location.tab);
      scrollToTop();
      setTimeout(() => {
        dispatch(alertActions.page_loader(false));
        dispatch(alertActions.success("Redeem successfull"));
      }, 100);
    } else if (props.location.state?.tab) {
      setTab(props.location.state.tab);
    }
  }, [myReward]);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };
  return (
    <IonPage>
      <Header
        name={" Rewards"}
        click={() => props.history.goBack()}
        back={false}
      ></Header>
      <IonContent ref={contentRef} scrollEvents={true}>
        {/* <TopGiftCards>
          <div className="p1">
            <div className={tab == 0 ? "active" : ""} onClick={() => setTab(0)}>
              Redeemable
            </div>
            <div className={tab == 1 ? "active" : ""} onClick={() => setTab(1)}>
              My Rewards
            </div>
          </div>
        </TopGiftCards> */}
        <div className="padding-status-bar"></div>
        <ProgressTop>
          <div className="padding-status-bar reward"></div>
          <ProgressBar
            percent={user?.rewards ? +user?.rewards / 10 : 0}
          ></ProgressBar>
          <div style={{ fontWeight: "bold", marginTop: 5 }}>
            {user?.rewards}/1000
          </div>
        </ProgressTop>
        <div
          className="h-flex h-flex__wrap"
          style={{ marginTop: 100, padding: "0 8px" }}
        >
          {loaded ? (
            tab == 0 ? (
              dishesList.map((val: any, idx) => (
                <RewardCart
                  id={val.id}
                  src={val.image}
                  name={val.name}
                  price={Number(val.points).toFixed(0)}
                  history={props.history}
                  key={idx}
                  possible={Number(val.points) <= +user?.rewards}
                  addon={val}
                  restaurant_id={props.restaurant_id}
                  is_used={false}
                  is_my={true}
                  id_user_reward={val.id}
                />
              ))
            ) : myReward.length > 0 ? (
              <>
                {myReward.map((val: any, idx) => {
                  return (
                    <RewardCart
                      id={val.reward.id}
                      src={val.reward.image}
                      name={val.reward.name}
                      price={Number(val.reward.points).toFixed(0)}
                      history={props.history}
                      key={idx}
                      addon={val.reward}
                      restaurant_id={props.restaurant_id}
                      is_used={val.is_used}
                      is_my={true}
                      id_user_reward={val.id}
                    />
                  );
                })}
                <IonInfiniteScroll
                  threshold="100px"
                  disabled={disableInfiniteScroll}
                  onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
                  style={{ marginTop: 20 }}
                >
                  <IonInfiniteScrollContent loadingText="Loading..."></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </>
            ) : (
              <div
                style={{
                  marginTop: "calc(50vh - 250px)",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <h4 className="font-weight-light text-center text-muted">You have no orders</h4> */}
                  <img
                    src={require("../../assets/img/icons/common/empty_reward.png").default}
                    style={{ height: 174, width: 174, marginBottom: 20 }}
                    alt="pan"
                  />
                  <div style={{ fontSize: 22, color: "#707175" }}>
                    You have no rewards here
                  </div>
                  {/* <h1><IonIcon icon={receiptOutline} /></h1> */}
                </Col>
              </div>
            )
          ) : (
            <>
              {" "}
              <FeaturedSkeletonText />
              <FeaturedSkeletonText />
              <FeaturedSkeletonText />
            </>
          )}
        </div>
        <Addons
          name={props.restaurant_name}
          history={props.history}
          free={true}
        />
      </IonContent>
    </IonPage>
  );
};
const TopGiftCards = styled.div`
  background-color: #fff;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 49px;
  .p1 {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    justify-content: center;
    > div {
      width: 115px;
      text-align: center;
      color: #999999;
      padding: 10px 0;
      &.active {
        color: #045ade;
        font-weight: bold;
        border-bottom: 2px solid #045ade;
      }
    }
  }
  .p2 {
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
    align-items: center;

    .p2-1-2 {
      font-size: 28px;
      font-weight: bold;
    }
    .p2-2-1 {
      color: #fff;
      background-color: #045ade;
      height: 38px;
      width: 122px;
      border-radius: 20px;
    }
  }
`;
const ProgressTop = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 0px 20px;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 50px;
  padding-bottom: 8px;
  box-shadow: 0px 2px 3px #0000001a;
`;
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart,
    restaurant_id: state.info.restaurant_id,
    restaurant_name: state.info.restaurant_address,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
