import { useIonViewWillEnter } from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import actions from "../../actions/auth";
import cartActions from "../../actions/cart";

const Logout: React.FC = (props: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cartActions.change_restaurant());
    dispatch(actions.logout());
  }, []);

  return <Redirect to="/auth/login" />;
};

export default Logout;
