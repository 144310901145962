import {
  IonAlert,
  IonContent,
  IonFab,
  IonLabel,
  IonLoading,
  IonPage,
} from "@ionic/react";
import React, { useState } from "react";
// reactstrap components
import { Container } from "reactstrap";
import Header from "../../components/Shared/Header";
import axios from "../../helpers/axiosInterceptor";

const ChangePassword: React.FC = (props: any) => {
  const [current, setCurrent] = useState("");
  const [renewPass, setRenewPass] = useState("");
  const [newPass, setNewPas] = useState("");
  const [error, setError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [showAlert4, setShowAlert4] = useState(false);

  const passwordChange = async () => {
    if (current == "" || newPass == "" || renewPass == "") {
      setShowAlert4(true);
    } else if (newPass != renewPass) {
      setShowAlert3(true);
    } else {
      setShowLoading(true);
      try {
        const response = await axios.post("/password/change", {
          old: current,
          new: newPass,
          renew: renewPass,
        });

        if (response.data) {
          setShowAlert1(true);
          setShowLoading(false);
        } else {
          setShowAlert2(true);
          setShowLoading(false);
        }
        // setShowAlert(true)
        // setShowLoading(false)
      } catch (e) {
        setError(true);
        setShowLoading(false);
      }
    }
  };

  return (
    <IonPage>
      <Header
        name="Change Password"
        click={() => props.history.goBack()}
      ></Header>
      <IonContent className="bg-secondary edit-profile">
        <div className="h-flex pt-20">
          <div className="col-12">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              Current Password
            </IonLabel>
            <input
              className="h-input-form2"
              required
              placeholder="Current password"
              type="password"
              name="old"
              onChange={(e) => setCurrent(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="h-flex pt-20">
          <div className="col-12">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              New Password
            </IonLabel>
            <input
              className="h-input-form2"
              required
              placeholder="New password"
              type="password"
              name="new"
              onChange={(e) => setNewPas(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="h-flex pt-20">
          <div className="col-12">
            <IonLabel className="h-bold h-font-18 mg-b5" color="dark">
              Confirm New Password
            </IonLabel>
            <input
              className="h-input-form2"
              required
              placeholder="Retype new password"
              type="password"
              name="renew"
              onChange={(e) => setRenewPass(e.target.value)}
            ></input>
          </div>
        </div>

        <IonFab
          vertical="bottom"
          horizontal="end"
          slot="fixed"
          className="ht-ion-fab"
        >
          <div className="h-btn-alws-bottom button-inner">
            <button
              type="button"
              className="btn-border-rdu-30"
              color="primary"
              onClick={passwordChange}
            >
              SAVE
            </button>
          </div>
        </IonFab>



      </IonContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Password Mailed"}
        message={"We have e-mailed the password to your <strong>" + "</strong>"}
        buttons={[
          {
            text: "Login now",
            handler: () => {
              props.history.goBack();
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="ht-alert"
        header={"Alert"}
        message={"Password changed"}
        buttons={[
          {
            text: "OK",
            handler: () => {
              props.history.goBack();
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showAlert2}
        onDidDismiss={() => setShowAlert2(false)}
        cssClass="ht-alert"
        header={"Alert"}
        message={"Your current password incorrect or internet error."}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showAlert3}
        onDidDismiss={() => setShowAlert3(false)}
        cssClass="ht-alert"
        header={"Alert"}
        message={"New password and confirm new password are not the same!"}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showAlert4}
        onDidDismiss={() => setShowAlert4(false)}
        cssClass="ht-alert"
        header={"Alert"}
        message={"Please enter full information!"}
        buttons={["OK"]}
      />
    </IonPage>
  );
};

export default ChangePassword;
