import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/cart";
import { IonItem } from "@ionic/react";
interface RouteProps {
  src?: string;
  name?: string;
  description?: string;
  price?: number;
  history?: any;
  food?: any;
  match?: any;
}

const Arrow = styled.div`
  postition: relative;
  width: 8px;
  &:after {
    content: "";
    position: absolute;
    height: 9px;
    width: 9px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(-45deg);
    top: calc(50%);
  }
`;
const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
  font-size: 14px;
  color: #707070;
`;
const Price = styled.div`
  font-size: 15px;
  margin-top: 5px;
`;

const FoodCategory: React.FC<RouteProps> = (props: any) => {
  const { id } = props.food;
  const dispatch = useDispatch();

  return (
    <IonItem className="ht-ion-item" href="javascript:void(0)" detail={false}>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 20,
          padding: 10,
          display: "flex",
          alignItems: "center",
          position: "relative",
          width: "100%",
        }}
        onClick={() => {
          dispatch(alertActions.page_loading(true));
          setTimeout(function () {
            props.history.push(`/food/${props.restaurantID}/${id}`);
          }, 175);
        }}
      >
        <div
          style={{
            backgroundImage: "url(" + props.src + ")",
            height: 100,
            width: 100,
            backgroundSize: "cover",
            borderRadius: 15,
            backgroundPosition: "center",
          }}
        ></div>
        <div style={{ width: "calc(100% - 118px)", padding: "0 10px 0 15px" }}>
          <h6
            style={{
              marginBottom: 2,
              fontWeight: "bold",
              fontSize: 17,
              textTransform: "uppercase",
            }}
          >
            {props.name}
          </h6>
          <Ellipsis>{props.description}</Ellipsis>
          {props.price ? <Price>${props.price}</Price> : null}
        </div>
        <Arrow></Arrow>
      </div>
    </IonItem>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantID: state.info.restaurant_id,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodCategory);
