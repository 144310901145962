import {
  IonInput,
  IonItem,
  IonLabel,
  IonContent,
  IonDatetime,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
} from "@ionic/react";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../components/Shared/Header";
import axios from "../../helpers/axiosInterceptor";
// reactstrap components

const EditCard: React.FC = (props: any) => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [validDate, setValidDate] = useState("");
  const [cvc, setCvc] = useState("");

  const add_credit_card = () => {
    axios
      .post("/credit_card/add", {
        card_number: cardNumber,
        expiration_date: validDate,
        cvc,
        card_holder: cardHolder,
      })
      .then((res) => {
        console.log(res);
      });
  };

  return (
    <IonPage>
      <Header
        name="Credit / Debit Card"
        click={() => props.history.goBack()}
      ></Header>

      <IonContent>
        <br />
        <div className="h-flex">
          <div className="col-12" style={{ marginBottom: 20 }}>
            <IonLabel className="h-bold" color="dark">
              Card holder
            </IonLabel>
            <IonInput
              inputmode="text"
              type="text"
              pattern="[0-9\s]{13,19}"
              className="h-input-form"
              placeholder="Card holder name"
              value={cardHolder}
              maxlength={19}
              onIonChange={(e: any) => {
                setCardHolder(e.target.value);
              }}
            ></IonInput>
          </div>
        </div>
        <div className="h-flex">
          <div className="col-12">
            <IonLabel className="h-bold" color="dark">
              Card Number
            </IonLabel>
            <IonInput
              inputmode="numeric"
              type="tel"
              pattern="[0-9\s]{13,19}"
              className="h-input-form"
              placeholder="**** **** **** ****"
              value={cardNumber}
              maxlength={19}
              onIonChange={(e: any) => {
                var x = e.target.value
                  .replace(/\D/g, "")
                  .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
                e.target.value =
                  (x[1] ? x[1] : "") +
                  (x[2] ? " " + x[2] : "") +
                  (x[3] ? " " + x[3] : "") +
                  (x[4] ? " " + x[4] : "");

                setCardNumber(e.target.value);
              }}
            ></IonInput>
          </div>
        </div>
        <br />
        <div className="h-flex">
          <div className="col-6">
            <IonLabel className="h-bold" color="dark">
              {" "}
              Exp
            </IonLabel>
            <IonItem className="h-input-form datetime">
              <IonDatetime
                max="2030-10-31"
                displayFormat="YY/MM"
                pickerFormat="YY/MM"
                placeholder="YY/MM"
                onIonChange={(e) => {
                  let date = new Date(e.detail.value!);
                  console.log(date);
                  setValidDate(
                    date.getFullYear().toString().slice(-2) +
                      "/" +
                      date.getDate()
                  );
                }}
                style={{ height: 53, paddingLeft: 0 }}
                className="h-datetime"
              ></IonDatetime>
            </IonItem>
          </div>
          <div className="col-6">
            <IonLabel className="h-bold" color="dark">
              CVC
            </IonLabel>
            <IonInput
              type="tel"
              className="h-input-form"
              placeholder="***"
              maxlength={3}
              value={cvc}
              onIonChange={(e: any) => setCvc(e.target.value)}
            ></IonInput>
          </div>
        </div>
      </IonContent>
      <div className="h-btn-alws-bottom button-inner">
        <button
          className="btn-border-rdu-30"
          color="primary"
          type="button"
          onClick={add_credit_card}
        >
          SAVE
        </button>
      </div>
    </IonPage>
  );
};
export default EditCard;
