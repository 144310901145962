import { useIonRouter, useIonViewWillEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { Col } from "reactstrap";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/auth";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import axios from "../../helpers/axiosInterceptor";

interface RoutesIF {
  history: any; // Change the required prop to an optional prop.
}

const SavedAddress: React.FC<RoutesIF> = ({ history }) => {
  const [addresses, setAddresses] = useState([]);
  const [defaultAddres, setDefaultAddres] = useState(0);
  const [load, setLoad] = useState(false);

  const dispatch = useDispatch();
  const router = useIonRouter();
  useIonViewWillEnter(() => {
    setLoad(false);
    axios
      .get(`/credit_cards`)
      .then((res) => {
        setAddresses(res.data);
        res.data.map((data) => {
          if (data.is_primary) {
            setDefaultAddres(data.id);
          }
        });
        if (res.data.length == 0) {
          dispatch(actions.set_card(null));
        }
        dispatch(alertActions.page_loader(false));
        setLoad(true);
      })
      .catch((err) => {
        dispatch(alertActions.page_loader(false));
        dispatch(alertActions.error("There are errors, please try again!"));
        setLoad(true);
      });
  });

  const changeDefaultAddress = (id, last4) => {
    setLoad(false);
    axios
      .put(`/credit_card/update`, { id })
      .then((res) => {
        const data = res.data;
        dispatch(
          actions.set_card({
            id: id,
            card_number: data.card_number,
            card_id: data.card_id,
          })
        );
        axios
          .get(`/credit_cards`)
          .then((res) => {
            setAddresses(res.data);
            res.data.map(
              (data) => data.is_primary && setDefaultAddres(data.id)
            );

            setLoad(true);
          })
          .catch((err) => {
            setLoad(true);
            dispatch(alertActions.error("There are errors, please try again!"));
          });
      })
      .catch((err) => {
        dispatch(alertActions.error("There are errors, please try again!"));
        setLoad(true);
      });
  };

  const deleteAddress = (id, card_id) => {
    setLoad(false);
    axios
      .delete(`/stripe/delete_card`, { data: { card_id: card_id } })
      .then((data: any) => {
        axios
          .delete(`/credit_card/update`, { data: { id } })
          .then((res) => {
            axios
              .get(`/credit_cards`)
              .then((res) => {
                setAddresses(res.data);
                res.data.map((data) => {
                  if (data.is_primary) {
                    setDefaultAddres(data.id);
                    dispatch(
                      actions.set_card({
                        id: id,
                        card_number: data.card_number,
                        card_id: data.card_id,
                      })
                    );
                  }
                });
                if (res.data.length == 0) {
                  dispatch(actions.set_card(null));
                }
                setLoad(true);
              })
              .catch((err) => {
                setLoad(true);
                dispatch(
                  alertActions.error("There are errors, please try again!")
                );
              });
          })
          .catch((err) => {
            setLoad(true);
            dispatch(alertActions.error("There are errors, please try again!"));
          });
      })
      .catch((err) => {
        setLoad(true);
        dispatch(alertActions.error(err.data.error));
      });
  };

  return (
    <>
      {load && addresses.length > 0 ? (
        addresses.map((address: any, idx) => {
          return (
            <div
              className="h-form-restaurant h-bgr-fff"
              key={idx}
              style={{ marginTop: 15 }}
            >
              <div className="h-flex h-between">
                <div
                  className="h-flex h-flex__nowrap"
                  style={{
                    width: "calc(100% - 38px)",
                    padding: "0 18px",
                  }}
                  onClick={() =>
                    changeDefaultAddress(address.id, address.card_number)
                  }
                >
                  <div className="h-flex a-center">
                    <img
                      src={
                        defaultAddres != address.id
                          ? require("../../assets/img/icons/common/cardIcon_profile.svg").default
                          : require("../../assets/img/icons/common/cardIcon_profile_active.svg").default
                      }
                    />
                  </div>
                  <div
                    className="h-restaurant-address"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      minHeight: 55,
                      padding: "9px 0 11px 15px",
                    }}
                  >
                    <Label
                      className="h-bold"
                      style={{
                        display: "flex",
                        width: "100%",
                        marginBottom: "5px !important",
                      }}
                    >
                      {" "}
                      {address.card_holder}
                    </Label>
                    <Ellipsis className="h-color-707070">
                      {"**** **** **** " + address.card_number}
                    </Ellipsis>
                  </div>
                </div>

                <div
                  className="h-flex a-center h-mr-3"
                  style={{ width: 30, position: "relative" }}
                  onClick={() => deleteAddress(address.id, address.card_id)}
                >
                  <img
                    src={require("../../assets/img/icons/common/delete.svg").default}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : load && addresses.length == 0 ? (
        <div
          style={{
            marginTop: "calc(50vh - 250px)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col
            style={{
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <h4 className="font-weight-light text-center text-muted">You have no orders</h4> */}
            <img
              src={require("../../assets/img/icons/common/empty_credit_card.svg").default}
              style={{ height: 174, width: 174, marginBottom: 20 }}
              alt="pan"
            />
            <div style={{ fontSize: 22, color: "#707175" }}>
              You have no credit card
            </div>
            {/* <h1><IonIcon icon={receiptOutline} /></h1> */}
          </Col>
        </div>
      ) : (
        [...Array(1)].map((e, i) => <GeneralSkeletonText key={i} />)
      )}

      {addresses.length > 0 ? (
        <div className="h-form-restaurant h-bgr-fff" style={{ marginTop: 15 }}>
          <div
            style={{
              color: "#045ADE",
              height: 55,
              fontSize: 16,
              textAlign: "center",
              lineHeight: "55px",
            }}
            onClick={() => router.push("/credit_card/add", "forward", "push")}
          >
            +Add New Card
          </div>
        </div>
      ) : (
        <div
          className="h-form-restaurant h-bgr-fff"
          style={{
            marginTop: 15,
            position: "fixed",
            width: "calc(100% - 30px)",
            bottom: 20,
            left: 15,
          }}
        >
          <div
            style={{
              color: "#045ADE",
              height: 55,
              fontSize: 16,
              textAlign: "center",
              lineHeight: "55px",
            }}
            onClick={() => router.push("/credit_card/add", "forward", "push")}
          >
            +Add New Card
          </div>
        </div>
      )}
    </>
  );
};
const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
`;

const Label = styled.label`
  display: flex;
  width: 100%;
  margin-bottom: 5px !important;
`;

export default SavedAddress;
