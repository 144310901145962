import axios from "../helpers/axiosInterceptor";
import { cartConstants, authConstants } from "../helpers/constants";
import { alertActions } from "./alert";

import { useHistory } from "react-router-dom";

export const increment = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.ITEM_INCREMENT, payload: data });
};

export const decrement = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.ITEM_DECREMENT, payload: data });
};

export const addons_show = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.ADDONS_SHOW, payload: data });
};

export const addons_hide = () => async (dispatch) => {
  dispatch({ type: cartConstants.ADDONS_HIDE });
};

export const coupon_add = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.COUPON_ADD, payload: data });
};

export const coupon_remove = () => async (dispatch) => {
  dispatch({ type: cartConstants.COUPON_REMOVE });
};
export const change_tip = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_TIP, payload: data });
};
export const change_points = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_POINTS, payload: data });
};
export const change_coins = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_COINS, payload: data });
};
export const change_request = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_REQUEST, payload: data });
};
export const change_delivery_time = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_DELIVERY_TIME, payload: data });
};
export const change_delivery_to = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_DELIVERY_TO, payload: data });
};
export const grand_total = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.GRAND_TOTAL, payload: data });
};
export const changed = () => async (dispatch) => {
  dispatch({ type: cartConstants.SET_CHANGED });
};
export const change_restaurant_id = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_RESTAURANT_ID, payload: data });
};

export const change_cooking_time = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_COOKING_TIME, payload: data });
};

export const change_restaurant = () => async (dispatch) => {
  dispatch({ type: cartConstants.ORDER_SUCCESS });
};

export const change_time = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CHANGE_TIME, payload: data });
};
export const change_time_change = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.TIME_CHANGE, payload: data });
};
export const current_time = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.CURRENT_TIME, payload: data });
};
export const index_time = (data) => async (dispatch) => {
  dispatch({ type: cartConstants.INDEX_TIME, payload: data });
};
export const place_order = (formData, history, type) => async (dispatch) => {
  try {
    dispatch(alertActions.page_loader(true));
    dispatch({ type: cartConstants.PLACE_ORDER, payload: formData });
    try {
      let order = await axios.post("/order/place_order", formData);
      dispatch({ type: cartConstants.ORDER_SUCCESS, payload: order });
      dispatch({
        type: authConstants.REDEEM,
        payload: { discount: -Number(formData.coins) },
      });
      if (type == "COD")
        history.replace({ pathname: "/payment_success", state: order });
      else if (type == "CC")
        history.replace({ pathname: "/payment_success", state: order });
    } catch (error) {
      if (error.data?.errors) dispatch(alertActions.error(error.data.errors));
      else dispatch(alertActions.error("There are errors, please try again!"));
    }
    setTimeout(() => {
      dispatch(alertActions.page_loader(false));
    }, 500);
  } catch (e) {
    const response = e.response.data;
    dispatch(alertActions.error("There are errors, please try again!"));
  }
};

export default {
  increment,
  decrement,
  addons_show,
  addons_hide,
  coupon_add,
  coupon_remove,
  grand_total,
  place_order,
  change_tip,
  change_coins,
  change_points,
  change_request,
  change_delivery_time,
  change_delivery_to,
  changed,
  change_restaurant_id,
  change_cooking_time,
  change_time,
  change_restaurant,
  current_time,
  index_time,
  change_time_change,
};
