import React from "react";
import styled from "styled-components";

interface RouteProps {
  percent?: number;
}

const ProgressBar: React.FC<RouteProps> = (props: any) => {
  let progress: number[] = [];
  for (
    let i = 0;
    i < ((window.innerWidth - 40) * props.percent) / 100 / 13;
    i++
  ) {
    progress.push(i);
  }
  let element = progress.map((val, ind) => <Progress key={ind}></Progress>);
  return (
    <Border>
      <BorderWidth></BorderWidth>
      {element}
    </Border>
  );
};

const BorderWidth = styled.div`
  position: absolute;
  width: calc(100%);
  border-width: 3px 3px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 10px;
  height: calc(100% + 2px);
  top: -1px;
  left: 0px;
`;

const Border = styled.div`
  background-color: rgb(255, 255, 255);
  display: flex;
  border: 1px solid rgb(183, 183, 183);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 22px;
`;

const Progress = styled.div`
  width: 8px;
  background-color: blue;
  height: 20px;
  margin-right: 4px;
  margin-left: 1px;
`;

export default ProgressBar;
