import React from "react";

const Container: React.FC = (props: any) => {
  return (
    <div
      className="my-container"
      style={{
        //background: "url(" + Background + ") no-repeat center center fixed",

        padding: "10px 20px",
      }}
    >
      {props.children}
    </div>
  );
};

export default Container;
