import { authConstants, alertConstants } from "../helpers/constants";

export const alertActions = {
  success,
  error,
  clear,
  page_loader,
  page_loading,
};

function success(message) {
  return { type: authConstants.SUCCESS, message };
}

function error(message) {
  return { type: authConstants.ERROR, message };
}

function clear() {
  return { type: authConstants.CLEAR };
}

function page_loader(status) {
  return { type: alertConstants.FULLPAGE_LOADER, payload: status };
}

function page_loading(status) {
  return { type: alertConstants.FULLPAGE_LOADING, payload: status };
}
