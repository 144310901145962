import { infoConstants } from "../helpers/constants";

export const set_location = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_LOCATION, payload: data });
};

export const set_address = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_ADDRESS, payload: data });
};
export const set_restaurant_address = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_RESTAURANT_ADDRESS, payload: data });
};
export const set_type = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_TYPE, payload: data });
};
export const set_restaurant_id = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_RESTAURANT_ID, payload: data });
};
export const set_restaurant_phone = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_RESTAURANT_PHONE, payload: data });
};
export const set_addon_type = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_ADDON_TYPE, payload: data });
};
export const set_api_key = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_API_KEY, payload: data });
};
export const set_restaurant_street = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_RESTAURANT_STREET, payload: data });
};
export const set_restaurant = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_RESTAURANT, payload: data });
};
export const set_categories = (data) => async (dispatch) => {
  dispatch({ type: infoConstants.SET_CATEGORIES, payload: data });
};
export default {
  set_location,
  set_address,
  set_restaurant_address,
  set_type,
  set_restaurant_id,
  set_addon_type,
  set_api_key,
  set_restaurant_street,
  set_restaurant_phone,
  set_restaurant,
  set_categories
};
