export const apiConstants = {
  // baseUrl: process.env.NODE_ENV === 'production' ? 'http://YOUR-DOMAIN-BACKEND/api' : 'http://localhost:8000/api',
  //baseUrl: "http://127.0.0.1:8000/api",
  baseUrl: "https://www.chickochicken.net/public/api",
};

export const externalConstants = {
  google_map_api_key: "",
  PAYPAL_CLIENT_ID: "",
  RAZORPAY_ID: "",
  STRIPE_PUBLIC_KEY:
    "pk_live_51J6eAJJBTiOeimMtIgaM9OIhqe10v2Enn4d2nXNpsfBukfbCLA9TfOB5A9M8gCV6mM4T7fFrMb7lFaa3TjjgF3lm00JmNfgeGc",
};

export const authConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
  LOGOUT: "USERS_LOGOUT",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  VERIFY_REQUEST: "USERS_VERIFY_REQUEST",
  VERIFY_SUCCESS: "USERS_VERIFY_SUCCESS",
  VERIFY_FAILURE: "USERS_VERIFY_FAILURE",

  UPDATE_AVATAR: "UPDATE_AVATAR",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_POINT_PERCENTAGE: "UPDATE_POINT_PERCENTAGE",

  SET_DEFAULT_ADDRESS: "SET_DEFAULT_ADDRESS",
  SET_DEFAULT_CARD: "SET_DEFAULT_CARD",

  SET_FCM_TOKEN: "SET_FCM_TOKEN",

  GIFT_CARD_PAYMENT: "GIFT_CARD_PAYMENT",

  REDEEM: "REDEEM",
};

export const infoConstants = {
  SET_LOCATION: "SET_LOCATION",
  SET_ADDRESS: "SET_ADDRESS",
  SET_TYPE: "SET_TYPE",
  SET_RESTAURANT_ADDRESS: "SET_RESTAURANT_ADDRESS",
  SET_RESTAURANT_ID: "SET_RESTAURANT_ID",
  SET_ADDON_TYPE: "SET_ADDON_TYPE",
  SET_API_KEY: "SET_API_KEY",
  SET_RESTAURANT_STREET: "SET_RESTAURANT_STREET",
  SET_RESTAURANT_PHONE: "SET_RESTAURANT_PHONE",
  SET_RESTAURANT: "SET_RESTAURANT",
  SET_CATEGORIES: "SET_CATEGORIES",
};

export const cartConstants = {
  ITEM_INCREMENT: "ITEM_INCREMENT",
  ITEM_DECREMENT: "ITEM_DECREMENT",

  ADDONS_SHOW: "ADDONS_SHOW",
  ADDONS_HIDE: "ADDONS_HIDE",

  COUPON_ADD: "COUPON_ADD",
  COUPON_REMOVE: "COUPON_REMOVE",

  CHANGE_TIP: "CHANGE_TIP",
  CHANGE_POINTS: "CHANGE_POINTS",

  GRAND_TOTAL: "GRAND_TOTAL",

  PLACE_ORDER: "PLACE_ORDER",
  ORDER_SUCCESS: "ORDER_SUCCESS",
  CHANGE_COINS: "CHANGE_COINS",
  CHANGE_REQUEST: "CHANGE_REQUEST",
  CHANGE_DELIVERY_TIME: "CHANGE_DELIVERY_TIME",
  CHANGE_DELIVERY_TO: "CHANGE_DELIVERY_TO",
  SET_CHANGED: "SET_CHANGED",
  CHANGE_RESTAURANT_ID: "CHANGE_RESTAURANT_ID",
  CHANGE_COOKING_TIME: "CHANGE_COOKING_TIME",
  CHANGE_TIME: "CHANGE_TIME",

  CURRENT_TIME: "CURRENT_TIME",
  TIME_CHANGE: "TIME_CHANGE",

  INDEX_TIME: "INDEX_TIME",
};

export const alertConstants = {
  FULLPAGE_LOADER: "FULLPAGE_LOADER",
  FULLPAGE_LOADING: "FULLPAGE_LOADING",
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
  LOGOUT: "USERS_LOGOUT",
};

export default {
  authConstants,
  apiConstants,
  cartConstants,
  externalConstants,
  alertConstants,
};
