import {
  IonAlert,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  isPlatform,
} from "@ionic/react";
import { Plugins } from "@capacitor/core";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import actions from "../../actions/cart";
import GeneralSkeletonText from "../../components/skeleton_text/button_restaurant";
import Header from "../Shared/Header";

interface RouteProps {
  name?: string;
  history?: any;
  free?: any;
}
const { App } = Plugins;

const Addons: React.FC<RouteProps> = (props: any) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [stock, setStock] = useState(1);
  const [addReward, setAddReward] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [showAlert4, setShowAlert4] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [double, setDouble] = useState<any>([]);
  const [sauceQuantity, setSauceQuantity] = useState<any>([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [changed, setChanged] = useState(0);

  const currency_symbol = useSelector(
    (state: RootStateOrAny) => state.auth.currency_symbol
  );

  let history = useHistory();
  //Selectors
  const getDishCard = useSelector((state: RootStateOrAny) => state.cart) ?? [];

  let getAddonState =
    useSelector(
      (state: RootStateOrAny) =>
        state.cart.addons && state.cart.addons.addons_dish
    ) ?? [];

  const getDishState =
    useSelector(
      (state: RootStateOrAny) => state.cart.addons && state.cart.addons
    ) ?? {};

  // const [showModal, setShowModal] = useState(getAddonState.length > 0);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(actions.addons_hide());
  };

  // useEffect(() => {
  //   if (props.cart.addons) {
  //     setLoaded(false);
  //     axios
  //       .get("/stock", {
  //         params: {
  //           restaurant_id: props.cart.addons.restaurant_id,
  //           dish_id: props.cart.addons.dish_id,
  //         },
  //       })
  //       .then((res: any) => {
  //         setStock(res.quantity);
  //         setLoaded(true);
  //       })
  //       .catch((e) => {
  //         setLoaded(true);
  //       });
  //   }
  // }, [props.cart]);

  const addAddons = () => {
    let temp = false;
    let id_addons_category;
    let name_addons_category: any[] = [];
    getAddonState.forEach((v, i) => {
      if (v.addons_category.type == "DOUBLE") {
        temp = true;
        id_addons_category = v.addons_category_id;
        name_addons_category.push(v.addons_category.name);
        setDouble(name_addons_category);
      }
    });
    if (!props.user) {
      dispatch(actions.addons_hide());
      props.history.push("/auth/login");
    } else if (
      (temp && !selectedOption[id_addons_category]) ||
      (temp && selectedOption[id_addons_category].length != 2)
    ) {
      setShowAlert(true);
    } else if (getAddonState[0].addons_category.id == 18) {
      const {
        dish_id,
        price,
        name,
        is_veg,
        restaurant_id,
        addons_dish,
        free,
        points,
        image,
        id_user_reward,
        stock,
        restaurant_dish,
      } = getDishState;
      let amount = isNaN(price) ? parseFloat(price.substr(1)) : price;

      let total_sauce = 0;
      sauceQuantity.forEach((v, i) => {
        if (v > 0) {
          total_sauce += v;
          let option = { 18: getAddonState[0].addons_category.addons[i].id };
          let addons = [
            {
              id: 18,
              name: getAddonState[0].addons_category.name,
              addons: [getAddonState[0].addons_category.addons[i]],
            },
          ];

          dispatch(
            actions.increment({
              dish_id,
              name,
              is_veg,
              amount,
              restaurant_id,
              addons,
              quantity: v,
              selectedOption: option,
              free: free,
              // points,
              id_user_reward,
              restaurant_dish,
            })
          );
          setSauceQuantity([
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ]);
          setQuantity(1);
          dispatch(actions.addons_hide());
        } else {
          if (i == sauceQuantity.length - 1 && total_sauce == 0) {
            setShowAlert4(true);
          }
        }
      });
    } else if (getAddonState.length > 0 && props.user) {
      // getAddonState.addons_dish

      const {
        dish_id,
        price,
        name,
        is_veg,
        restaurant_id,
        addons_dish,
        free,
        points,
        image,
        id_user_reward,
        stock,
        restaurant_dish,
      } = getDishState;

      // let addons = getAddonState.map((d) => {
      // 	const v = d.addons_category;
      // 	if (selectedOption[v.id]){
      // 		if(v.type == "MULTIPLE"){
      // 			const selected_addons = v.addons.filter(ad => selectedOption[v.id].includes(ad.id))
      // 			return { id: v.id, name: v.name, addons: selected_addons };
      // 		}else
      // 			return { id: v.id, name: v.name, addons: v.addons };
      // 	}
      // });
      var addons = getAddonState.reduce(function (result, d) {
        const v = d.addons_category;
        if (selectedOption[v.id]) {
          if (v.type == "MULTIPLE" || v.type == "DOUBLE") {
            const selected_addons = v.addons.filter((ad) =>
              selectedOption[v.id].includes(ad.id)
            );
            result.push({ id: v.id, name: v.name, addons: selected_addons });
          } else {
            const selected_addons = v.addons.filter(
              (ad) => selectedOption[v.id] == ad.id
            );
            result.push({ id: v.id, name: v.name, addons: selected_addons });
            // result.push({ id: v.id, name: v.name, addons: v.addons });
          }
        }
        return result;
      }, []);
      let amount = isNaN(price) ? parseFloat(price.substr(1)) : price;

      var current_dish;
      props.cart.dishes.forEach((v) => {
        if (getDishState.restaurant_dish?.id == v.restaurant_dish?.id) {
          current_dish = v;
        }
      });

      if (
        current_dish &&
        current_dish.count + quantity > current_dish.restaurant_dish.quantity
      ) {
        setShowAlert3(true);
      } else {
        setQuantity(1);
        dispatch(
          actions.increment({
            dish_id,
            name,
            is_veg,
            amount,
            restaurant_id,
            addons,
            quantity,
            selectedOption,
            free: free,
            points,
            id_user_reward,
            restaurant_dish,
          })
        );
        dispatch(actions.addons_hide());
      }

      if (props.history?.location?.pathname == "/dashboard/rewards") {
        setTimeout(() => {
          props.history.push("/cart");
        }, 500);
      }
      // dispatch(actions.increment(getAddonState));
    }
  };

  useEffect(() => {
    let temp = {};
    getAddonState &&
      getAddonState.map((data, i) => {
        if (data.addons_category.id != 19) {
          if (data.addons_category.type == "SINGLE")
            temp[data.addons_category.id] = data.addons_category.addons[0].id;
          if (i == getAddonState.length - 1) setSelectedOption(temp);
        }
      });
  }, [getAddonState]);

  useEffect(() => {
    let temp = true;
    props.cart.dishes?.map((val, idx) => {
      if (val.free) {
        temp = false;
      }
    });
    setAddReward(temp);
  }, [props.cart]);

  const setSelected = (category_id, option_id, type) => {
    if (type === 2) {
      let addons = selectedOption[category_id];
      if (addons != undefined) {
        const index = (addons as any).findIndex(
          (addon: any) => addon === option_id
        );
        if (index !== -1) {
          addons.splice(index, 1);
          setSelectedOption({
            ...selectedOption,
            [category_id]: addons,
          } as any);
        } else
          setSelectedOption({
            ...selectedOption,
            [category_id]: [...selectedOption[category_id], option_id],
          } as any);
      } else
        setSelectedOption({
          ...selectedOption,
          [category_id]: [option_id],
        } as any);
    } else if (type === 1)
      setSelectedOption({ ...selectedOption, [category_id]: option_id } as any);
  };

  const RenderAddons = (data) => {
    const { id, type, name, addons } = data.addons_category;
    if (id == 18) {
      let render = addons.map((d, i) => {
        return (
          <div
            className="h-flex h-pt-5 h-pb-5  h-line-e3e3e3"
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 15px",
              height: 61,
            }}
          >
            <IonLabel>{d.name}</IonLabel>
            <div
              className="h-form-de-in h-flex a-center"
              style={{ height: 35, width: 95 }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  padding: 0,
                }}
                className="col-4"
                onClick={() => {
                  let current = sauceQuantity[i] - 1;
                  let new_list = sauceQuantity;

                  let change = changed + 1;
                  setChanged(change);
                  if (current >= 0) {
                    new_list[i] = current;
                    setSauceQuantity(new_list);
                    let total = 0;
                    new_list.forEach((v, i) => {
                      total += v;
                    });
                    setQuantity(total);
                  }
                }}
              >
                <label className="tru">-</label>
              </div>
              <div
                className="col-4 h-pt-5"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  padding: 0,
                }}
              >
                <label className="mot" style={{ fontSize: 19 }}>
                  {sauceQuantity[i]}
                </label>
              </div>
              <div
                className="col-4 h-flex a-center h-pt-2"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  padding: 0,
                }}
                onClick={() => {
                  let current = sauceQuantity[i] + 1;
                  let new_list = sauceQuantity;
                  new_list[i] = current;
                  let change = changed + 1;
                  setChanged(change);
                  setSauceQuantity(new_list);
                  let total = 0;
                  new_list.forEach((v, i) => {
                    total += v;
                  });
                  setQuantity(total);
                }}
              >
                <label className="cong">+</label>
              </div>
            </div>
            {/* <span style={{ marginRight: 10 }}>
              {d.price > 0 ? currency_symbol + d.price : null}
            </span> */}
            {/* <IonRadio slot="end" value={d.id} /> */}
          </div>
        );
      });
      return (
        <div className="h-flex pt-10 h-pb-10">
          <div className="col-12">
            <label htmlFor="asap" className="h-bold h-pb-10 h-font-18">
              {name}
            </label>
            <div
              className="h-form-ordertime h-bgr-fff"
              style={{ overflow: "hidden" }}
            >
              <IonRadioGroup
                value={selectedOption[id]}
                onIonChange={(e) => setSelected(id, e.detail.value, 1)}
                defaultValue={1}
              >
                {render}
              </IonRadioGroup>
            </div>
          </div>
        </div>
      );
    } else if (type === "SINGLE" && name != "No option") {
      let render = addons.map((d, i) => {
        return (
          <div
            className="h-flex h-pt-5 h-pb-5  h-line-e3e3e3"
            key={i}
            style={{ height: 61 }}
          >
            <IonItem>
              <IonLabel>{d.name}</IonLabel>
              <span style={{ marginRight: 10 }}>
                {d.price > 0 ? currency_symbol + d.price : null}
              </span>
              <IonRadio slot="end" value={d.id} />
            </IonItem>
          </div>
        );
      });
      return (
        <div className="h-flex pt-10 h-pb-10">
          <div className="col-12">
            <label htmlFor="asap" className="h-bold h-pb-10 h-font-18">
              {name}
            </label>
            <div
              className="h-form-ordertime h-bgr-fff"
              style={{ overflow: "hidden" }}
            >
              <IonRadioGroup
                value={selectedOption[id]}
                onIonChange={(e) => setSelected(id, e.detail.value, 1)}
                defaultValue={1}
              >
                {render}
              </IonRadioGroup>
            </div>
          </div>
        </div>
      );
    } else if (type !== "SINGLE" && name != "No option") {
      let render = addons.map((d, i) => {
        return (
          <div
            className="h-flex h-pt-5 h-pb-5  h-line-e3e3e3"
            key={i}
            style={{ height: 59 }}
          >
            <IonItem>
              <IonLabel>{d.name}</IonLabel>
              <IonCheckbox
                slot="end"
                color="primary"
                checked={
                  selectedOption[id] != undefined
                    ? selectedOption[id].filter((e) => e == d.id).length > 0
                    : false
                }
                onIonChange={(e) => setSelected(id, d.id, 2)}
              />
            </IonItem>
          </div>
        );
      });
      return (
        <div className="h-flex pt-10 h-pb-10">
          <div className="col-12">
            <label htmlFor="asap" className="h-bold h-pb-10 h-font-18">
              {name}
            </label>
            <div
              className="h-form-ordertime h-bgr-fff"
              style={{ overflow: "hidden" }}
            >
              {render}
            </div>
          </div>
        </div>
      );
    } else return <></>;
  };

  const options = useMemo(
    () => getAddonState.map((data, i) => <RenderAddons {...data} key={i} />),
    [getAddonState, selectedOption, changed]
  );
  const back = () => {
    closeModal();
    setTimeout(() => {
      setQuantity(1);
      setSauceQuantity([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
    }, 100);
  };
  return (
    <IonModal
      isOpen={getAddonState && getAddonState.length > 0}
      cssClass="my-custom-class"
    >
      <Header name={props.name} click={() => back()} back={true}></Header>

      {props.history?.location?.pathname == "/dashboard/rewards" ? (
        <IonContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundImage: "url(" + getDishState.image + ")",
                height: 220,
                width: 220,
                borderRadius: 110,
                margin: "20% 0 20px",
                backgroundSize: "cover",
                backgroundRepeat: "none",
                backgroundPosition: "center",
              }}
            ></div>
            <div style={{ fontSize: 31, color: "#030303", fontWeight: "bold" }}>
              {getDishState.name}
            </div>
            <div style={{ fontSize: 25, color: "#045ADE", fontWeight: "bold" }}>
              {getDishState.points ? getDishState.points + " POINTS" : null}{" "}
            </div>
            <div
              style={{
                maxWidth: 220,
                textAlign: "center",
                fontSize: 17,
                color: "#333333",
                marginTop: 15,
              }}
            >
              You can redeem this reward to get 1 {getDishState.name} for free
              on your next order.
            </div>
          </div>
        </IonContent>
      ) : (
        <IonContent scrollEvents={false}>
          <div
            style={{
              width: "100%",
              position: "absolute",
            }}
          ></div>
          <div>
            <div className="h-flex h-between pt-20">
              <div className="col-8">
                <label className="h-bold h-font-22 h-color-C81021">
                  {getDishState.name}
                </label>
              </div>
              <div className="col-4 h-flex j-end">
                <label className="h-bold h-font-22">
                  {props.history?.location?.pathname == "/dashboard/rewards"
                    ? "Free"
                    : getDishState.price
                    ? "$" + (getDishState.price * quantity).toFixed(2)
                    : null}
                </label>
              </div>
            </div>
            <div className="h-flex h-pb-10">
              <div className="col-12">
                <label className="h-font-16" style={{ color: "#565656" }}>
                  {getDishState.description}
                </label>
              </div>
            </div>
            {getAddonState && options}
            {props.history?.location?.pathname == "/dashboard/rewards" ||
            getAddonState[0]?.addons_category.id == 18 ? null : (
              <div className="h-flex j-center a-center pb-100 pt-10">
                <div className="h-form-de-in h-flex a-center">
                  <div
                    className="col-4"
                    onClick={() => {
                      let current = quantity - 1;
                      if (current > 0) setQuantity(current);
                    }}
                  >
                    <label className="tru">-</label>
                  </div>
                  <div className="col-4 h-pt-5">
                    <label className="mot" style={{ fontSize: 20 }}>
                      {quantity}
                    </label>
                  </div>
                  <div
                    className="col-4 h-flex a-center h-pt-2"
                    onClick={() => {
                      let current = quantity + 1;
                      // dispatch(actions.changed());
                      setQuantity(current);
                      if (current > stock) {
                        // setShowAlert2(true);
                      } else {
                      }
                    }}
                  >
                    <label className="cong">+</label>
                  </div>
                </div>
              </div>
            )}
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              cssClass="ht-alert"
              header={"Alert"}
              //subHeader={'Information'}
              message={
                // double.map((v, i) => {
                //   console.log(double);
                //   return v;
                // }) + " must choose two"
                "It says Choose Two Flavours"
              }
              buttons={["OK"]}
            />{" "}
            <IonAlert
              isOpen={showAlert2}
              onDidDismiss={() => setShowAlert2(false)}
              cssClass="ht-alert"
              header={"Alert"}
              //subHeader={'Information'}
              message={"This dish only has " + stock + " servings left. "}
              buttons={["OK"]}
            />
            <IonAlert
              isOpen={showAlert3}
              onDidDismiss={() => setShowAlert3(false)}
              cssClass="ht-alert"
              header={"Alert"}
              //subHeader={'Information'}
              message={"Not enough stock"}
              buttons={["OK"]}
            />
            <IonAlert
              isOpen={showAlert4}
              onDidDismiss={() => setShowAlert4(false)}
              cssClass="ht-alert"
              header={"Alert"}
              //subHeader={'Information'}
              message={"Your current amout is 0. Please add more!"}
              buttons={["OK"]}
            />
          </div>
        </IonContent>
      )}
      {!addReward &&
      props.history?.location?.pathname == "/dashboard/rewards" ? null : stock >
          0 || props.history?.location?.pathname == "/dashboard/rewards" ? (
        <div className="h-btn-alws-bottom button-inner">
          <button
            type="submit"
            className="btn-border-rdu-30"
            color="primary"
            onClick={() => addAddons()}
          >
            ADD TO CART
          </button>
        </div>
      ) : loaded ? (
        <div className="h-btn-alws-bottom button-inner">
          <button
            type="submit"
            className="btn-border-rdu-30"
            color="primary"
            style={{ backgroundColor: "#d1d1d1" }}
          >
            OUT OF STOCK
          </button>
        </div>
      ) : (
        <div className="h-btn-alws-bottom button-inner" style={{ height: 71 }}>
          <GeneralSkeletonText></GeneralSkeletonText>
        </div>
      )}
    </IonModal>
  );
};

const mapStateToProps = (state) => {
  return {
    addonType: state.info.addon_type,
    user: state.auth?.user,
    cart: state.cart,
    info: state.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Addons);
