import { IonAlert } from "@ionic/react";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import cartActions from "../../actions/cart";
import actions from "../../actions/info";

interface RouteProps {
  hideBottom?: boolean;
  address?: string;
  name?: string;
  distance?: number;
  click?: any;
  history?: any;
  restaurant?: any;
  favourite?: any;
  load?: any;
}

const RestaurantBar: React.FC<RouteProps> = (props: any) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);

  const {
    id,
    open_time,
    close_time,
    rating,
    cooking_time,
    for_two,
    is_veg,
    phone,
    time_change,
  } = props.restaurant;
  const history = useHistory();

  const addToFavourite = (id) => {
    if (props.user) props.load(id);
    else props.history.replace("/auth/login");
  };
  return (
    <div>
      <Container hideBottom={props.hideBottom} className="prevent">
        <div className="same first prevent">
          <img
            src={
              props.favourite
                ? require("../../assets/img/icons/common/heart-red.svg").default
                : require("../../assets/img/icons/common/heart.svg").default
            }
            alt=""
            onClick={() => {
              addToFavourite(id);
            }}
          />
        </div>
        <div className="mid prevent" onClick={() => props.click()}>
          <div className="title">
            {props.name}{" "}
            <span style={{ fontWeight: "normal" }}>
              {" (" + cooking_time + "mins)"}
            </span>
          </div>
          <div className="address">{props.address}</div>
          <div className="distance">{props.distance.toFixed(2)}km</div>
        </div>
        <div className="same last prevent">
          <span
            className="order"
            onClick={(e) => {
              e.stopPropagation();

              if (!props.cart.restaurant_id || props.cart.dishes.length == 0) {
                dispatch(actions.set_restaurant_address(props.name));
                dispatch(actions.set_restaurant_id(id));
                dispatch(cartActions.change_restaurant_id(id));
                dispatch(cartActions.change_cooking_time(cooking_time));
                dispatch(cartActions.change_time_change(time_change));
                dispatch(
                  cartActions.change_time({
                    open_time,
                    close_time,
                  })
                );
                dispatch(actions.set_restaurant_street(props.address));
                dispatch(actions.set_restaurant_phone(phone));
                history.replace(`/dashboard/home`);
              } else if (props.cart.restaurant_id != id) setShowAlert(true);
              else {
                history.replace(`/dashboard/home`);
              }
            }}
          >
            ORDER
          </span>
        </div>
      </Container>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="ht-alert"
        header={"Confirm"}
        message={"Your cart will be reset. Do you want to change restaurant?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Ok",
            handler: (e) => {
              dispatch(cartActions.change_restaurant());
              dispatch(actions.set_restaurant_address(props.name));
              dispatch(actions.set_restaurant_id(id));
              dispatch(cartActions.change_restaurant_id(id));
              dispatch(cartActions.change_cooking_time(cooking_time));
              dispatch(cartActions.change_time_change(time_change));
              dispatch(
                cartActions.change_time({
                  open_time,
                  close_time,
                })
              );
              dispatch(actions.set_restaurant_street(props.address));
              dispatch(actions.set_restaurant_phone(phone));
              history.replace(`/dashboard/home`);
            },
          },
        ]}
      />
    </div>
  );
};

const Container = styled.div`
  display: flex;
  border-bottom: ${(props) =>
    props.hideBottom ? "none" : "1px solid #eeeeee"};
  padding: 15px 0 10px;
  .same {
    display: flex;
    align-items: center;

    &.first {
      width: 40px;
    }
    &.last {
      justify-content: center;
      width: 80px;
      cursor: pointer;
      .order {
        background: #045ade;
        color: #fff;
        border-radius: 5px;
        padding: 3px 12px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.5px;
      }
    }
  }
  .mid {
    width: calc(100% - 110px);
    .title {
      font-weight: bold;
      font-size: 15px;
    }
    .address {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      color: #707070;
    }
    .distance {
      font-size: 15px;
      color: #707070;
    }
  }
`;
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantBar);
