import {
  IonAlert,
  IonContent,
  IonFab,
  IonInput,
  IonLabel,
  IonPage,
  IonToast,
  useIonRouter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, StaticRouter } from "react-router-dom";
import { Col, Container } from "reactstrap";
import actions from "../../actions/cart";
import CartBillings from "../../components/MenuCards/cart-billing";
import CartItems from "../../components/MenuCards/cart-items";
import Header from "../../components/Shared/Header";
import axios from "../../helpers/axiosInterceptor";
import { alertActions } from "../../actions/alert";
import authActions from "../../actions/auth";
import {
  CardElement,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { authConstants } from "../../helpers/constants";
import { async } from "q";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#333333",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "15px",
      "::placeholder": {
        color: "#A5A5A5",
      },
      marginBottom: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
function CardSection() {
  return (
    <div style={{ padding: "0 15px", marginTop: 20 }}>
      {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}
      <div className="card-form card-number">
        <label>Card Number</label>
        <CardNumberElement
          options={CARD_ELEMENT_OPTIONS}
          className="card-number"
        />
      </div>
      <div className="card-form card-exp">
        <label>EXP</label>
        <CardExpiryElement
          options={CARD_ELEMENT_OPTIONS}
          className="card-exp"
        />
      </div>
      <div className="card-form card-cvc">
        <label>CVC</label>
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} className="card-cvc" />
      </div>
    </div>
  );
}

const AddCard: React.FC = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showToast, setShowToast] = useState(false);
  const [postalCode, setPostalCode] = useState("");

  const dispatch = useDispatch();
  const router = useIonRouter();

  useEffect(() => {
    props.auth.errorMsg && setShowToast(true);
  }, [props.auth.errorMsg]);

  const addCart = (stripe_id) => {
    axios
      .post("/stripe/setup_intent", {
        id: stripe_id,
      })
      .then(async (data_client: any) => {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result: any = await stripe.confirmCardSetup(
          data_client.clientSecret,
          {
            payment_method: {
              card: elements!.getElement(CardNumberElement)!,
              // billing_details: {
              //   address: {
              //     postal_code: postalCode,
              //   },
              // },
            },
          }
        );

        if (result.error) {
          // Display result.error.message in your UI.
          console.log(result.error);
          dispatch({
            type: authConstants.LOGIN_FAILURE,
            payload: result.error.message,
          });
          dispatch(alertActions.page_loader(false));
        } else {
          // The setup has succeeded. Display a success message and send
          // result.setupIntent.payment_method to your server to save the
          // card to a Customer

          axios
            .post("/stripe/get_card", {
              card_id: result.setupIntent.payment_method,
            })
            .then((data: any) => {
              axios
                .post("/credit_card/add", {
                  card_id: result.setupIntent.payment_method,
                  last_4: data.card.last4,
                })
                .then((res) => {
                  if (!props.auth.default_card) {
                    dispatch(
                      authActions.set_card({
                        id: res.data.id,
                        card_id: res.data.card_id,
                        card_number: res.data.card_number,
                      })
                    );
                  }
                  elements!.getElement(CardNumberElement)!.clear();
                  elements!.getElement(CardExpiryElement)!.clear();
                  elements!.getElement(CardCvcElement)!.clear();
                  setPostalCode("");
                  if (props.history?.location?.state?.from) {
                    props.history.goBack();
                    setTimeout(() => {
                      dispatch(alertActions.page_loader(false));
                    }, 500);
                  } else router.push("/credit_cards", "back", "push");
                })
                .catch((e) => {
                  dispatch(alertActions.page_loader(false));
                  dispatch(
                    alertActions.error("There are errors, please try again!")
                  );
                });
            })
            .catch((e) => {
              dispatch(alertActions.page_loader(false));
              dispatch(alertActions.error(e.data.message));
            });
        }
      })
      .catch((e) => {
        dispatch(alertActions.page_loader(false));
        dispatch(alertActions.error(e.data.message));
      });
  };

  const handleSubmit = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    dispatch(alertActions.page_loader(true));

    if (!props.auth.user?.stripe_id) {
      axios
        .get("/stripe/create_customer")
        .then((data) => {
          axios
            .post("/user/profile/update_stripe", {
              stripe_id: data,
            })
            .then(async (res) => {
              await dispatch(authActions.update_user({ stripe_id: data }));
              addCart(data);
            });
        })
        .catch((e) => {
          dispatch(alertActions.page_loader(false));
          dispatch(alertActions.error("There are errors, please try again!"));
        });
    } else {
      addCart(props.auth.user?.stripe_id);
    }
  };

  return (
    <IonPage>
      <Header
        name={"Add Credit/Debit Card"}
        click={() => {
          elements!.getElement(CardNumberElement)!.clear();
          elements!.getElement(CardExpiryElement)!.clear();
          elements!.getElement(CardCvcElement)!.clear();
          setPostalCode("");
          if (props.history?.location?.state?.from) {
            props.history.goBack();
            dispatch(alertActions.page_loader(false));
          } else router.push("/credit_cards", "back", "push");
        }}
      ></Header>

      <IonContent>
        <form onSubmit={handleSubmit}>
          <CardSection />
          {/* <div className="col-6" style={{ width: "calc(100% - 15px)" }}>
            <IonLabel className="h-bold" color="dark" style={{ fontSize: 18 }}>
              Postal Code
            </IonLabel>
            <IonInput
              type="tel"
              className="h-input-form postal-code"
              placeholder="123456"
              maxlength={10}
              value={postalCode}
              onIonChange={(e: any) => setPostalCode(e.target.value)}
            ></IonInput>
          </div> */}
          <IonFab
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            className="ht-ion-fab"
          >
            <div
              className="h-btn-alws-bottom button-inner"
            >
              <button
                disabled={!stripe}
                className="btn-border-rdu-30"
                color="primary"
              >
                SAVE
              </button>
            </div>
          </IonFab>
        </form>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => {
            dispatch({ type: authConstants.LOGIN_FAILURE });
            setShowToast(false);
          }}
          message={props.auth.errorMsg}
          duration={5000}
          color="primary"
        />
        {/* <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <div
            className="h-btn-alws-bottom button-inner"
            style={{ position: "fixed" }}
          >
            <button
              disabled={processing || disabled || succeeded}
              id="submit"
              className="btn-border-rdu-30"
              color="primary"
            >
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "SAVE"
                )}
              </span>
            </button>
          </div> */}
        {/* Show any error that happens when processing the payment */}
        {/* {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )} */}
        {/* Show a success message upon completion */}
        {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
            Payment succeeded, see the result in your
            <a href={`https://dashboard.stripe.com/test/payments`}>
              {" "}
              Stripe dashboard.
            </a>{" "}
            Refresh the page to pay again.
          </p> */}
        {/* </form> */}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return {
    info: state.info,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCard);
