import React from "react";

import { IonSkeletonText } from "@ionic/react";
import { Row, Col } from "reactstrap";

class GeneralSkeletonText extends React.Component {
  render() {
    return (
      <Row className="mx-2" style={{ width: "100%" }}>
        <Col xs="12 p-1">
          <IonSkeletonText
            animated
            className="w-100"
            style={{ height: 45, borderRadius: "30px" }}
          ></IonSkeletonText>
        </Col>
      </Row>
    );
  }
}

export default GeneralSkeletonText;
