import { cartConstants } from "../helpers/constants";

const INITIAL_STATE = {
  restaurant_id: "",
  dishes: [] as any,
  coupon: {},
  tax: 0,
  coins: 0,
  points: 0,
  free: false,
  special_request: "",
  delivery_time: "ASAP",
  delivery_to: "",
  changed: 0,
  cooking_time: 0,
  time: {},
  current_time: "",
  index_time: {},
};

const objectsEqual = (o1, o2) =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cartConstants.ITEM_INCREMENT: {
      let dishes: any = state.dishes ?? [];

      let indexs: any[] = [];
      dishes.forEach((v, i) => {
        v.id === action.payload.dish_id && indexs.push(i);
      });

      let current: any[] = [];
      let add: any[] = [];
      let check = true;
      let index = -1;

      indexs.length > 0 &&
        !action.payload.id_cart &&
        indexs.forEach((ind, i) => {
          let total = 0;
          add = [];
          current = [];

          if (
            JSON.stringify(action.payload.selectedOption) ==
            JSON.stringify(dishes[ind].selectedOption)
          ) {
            if (ind >= 0 && !action.payload.free && !action.payload.id_cart) {
              for (const [key, value] of Object.entries(
                action.payload.selectedOption
              )) {
                add.push(`${value}`);
              }
              for (const [key, value] of Object.entries(
                dishes[ind].selectedOption
              )) {
                current.push(`${value}`);
              }

              current.forEach((v, ii) => {
                if (current[ii] != add[ii]) {
                } else {
                  total++;
                }
              });
              if (total == current.length) {
                index = indexs[i];
                check = true;
              } else {
                check = false;
              }
            }
          }
        });

      // if (state.restaurant_id !== action.payload.restaurant_id) dishes = [];

      if (action.payload.id_cart) {
        const index = dishes.findIndex(
          (dish: any) => dish.id_cart === action.payload.id_cart
        );
        dishes[index].count = dishes[index].count + action.payload.quantity;
      } else if (index === -1 || action.payload.free) {
        if (index >= 0) {
        }
        let id_cart = dishes[dishes.length - 1]
          ? dishes[dishes.length - 1].id_cart + 1
          : 1;
        dishes.push({
          count: action.payload.quantity,
          addons_dish: action.payload.addons ?? [],
          price: action.payload.free ? 0 : action.payload.amount,
          is_veg: action.payload.is_veg,
          name: action.payload.name,
          id: action.payload.dish_id,
          selectedOption: action.payload.selectedOption,
          id_cart,
          free: action.payload.free ? action.payload.free : false,
          points: action.payload.points,
          id_user_reward: action.payload.id_user_reward,
          restaurant_dish: action.payload.restaurant_dish,
        });
      } else if (!check) {
        let id_cart = dishes[dishes.length - 1]
          ? dishes[dishes.length - 1].id_cart + 1
          : 1;
        dishes.push({
          count: action.payload.quantity,
          addons_dish: action.payload.addons ?? [],
          price: action.payload.free ? 0 : action.payload.amount,
          is_veg: action.payload.is_veg,
          name: action.payload.name,
          id: action.payload.dish_id,
          selectedOption: action.payload.selectedOption,
          id_cart,
          free: action.payload.free ? action.payload.free : false,
          restaurant_dish: action.payload.restaurant_dish,
        });
      } else {
        if (action.payload.free) {
          dishes[index].count = dishes[index].count + action.payload.quantity;
        } else if (index >= 0) {
          if (
            dishes[index].count + action.payload.quantity >
            dishes[index].restaurant_dish.quantity
          ) {
            dishes[index].count = dishes[index].restaurant_dish.quantity;
          } else
            dishes[index].count = dishes[index].count + action.payload.quantity;
        } else {
          let id_cart = dishes[dishes.length - 1]
            ? dishes[dishes.length - 1].id_cart + 1
            : 1;
          dishes.push({
            count: action.payload.quantity,
            addons_dish: action.payload.addons ?? [],
            price: action.payload.amount,
            is_veg: action.payload.is_veg,
            name: action.payload.name,
            id: action.payload.dish_id,
            selectedOption: action.payload.selectedOption,
            id_cart,
            free: action.payload.free ? action.payload.free : false,
            restaurant_dish: action.payload.restaurant_dish,
          });
        }
      }

      return {
        ...state,
        restaurant_id: action.payload.restaurant_id,
        dishes,
      };
    }

    case cartConstants.ITEM_DECREMENT: {
      let dishes: any = state.dishes;
      const index = dishes.findIndex(
        (dish: any) => dish.id_cart === action.payload.id_cart
      );

      if (index !== -1) {
        if (dishes[index].count === 1) dishes.splice(index, 1);
        else dishes[index].count--;
      }
      return {
        ...state,
        restaurant_id: action.payload.restaurant_id,
        dishes,
      };
    }

    case cartConstants.ADDONS_SHOW: {
      return {
        ...state,
        addons: action.payload,
      };
    }

    case cartConstants.ADDONS_HIDE: {
      return {
        ...state,
        addons: [],
      };
    }

    case cartConstants.COUPON_ADD: {
      return {
        ...state,
        coupon: action.payload,
      };
    }

    case cartConstants.COUPON_REMOVE: {
      return {
        ...state,
        coupon: {},
      };
    }
    case cartConstants.CHANGE_TIP: {
      return {
        ...state,
        tip: action.payload,
      };
    }
    case cartConstants.CHANGE_COINS: {
      return {
        ...state,
        coins: action.payload,
      };
    }
    case cartConstants.CHANGE_REQUEST: {
      return {
        ...state,
        special_request: action.payload,
      };
    }
    case cartConstants.CHANGE_POINTS: {
      return {
        ...state,
        points: action.payload,
      };
    }
    case cartConstants.CHANGE_DELIVERY_TIME: {
      return {
        ...state,
        delivery_time: action.payload,
      };
    }
    case cartConstants.CHANGE_DELIVERY_TO: {
      return {
        ...state,
        delivery_to: action.payload,
      };
    }
    case cartConstants.GRAND_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case cartConstants.SET_CHANGED: {
      let temp = Number(state.changed) + 1;
      return {
        ...state,
        changed: temp,
      };
    }
    case cartConstants.CHANGE_RESTAURANT_ID: {
      return {
        ...state,
        restaurant_id: action.payload,
      };
    }
    case cartConstants.CHANGE_COOKING_TIME: {
      return {
        ...state,
        cooking_time: action.payload,
      };
    }
    case cartConstants.CHANGE_TIME: {
      return {
        ...state,
        time: action.payload,
      };
    }
    case cartConstants.CURRENT_TIME: {
      return {
        ...state,
        current_time: action.payload,
      };
    }
    case cartConstants.TIME_CHANGE: {
      return {
        ...state,
        time_change: action.payload,
      };
    }
    case cartConstants.INDEX_TIME: {
      return {
        ...state,
        index_time: action.payload,
      };
    }
    case cartConstants.ORDER_SUCCESS: {
      return {
        ...state,
        dishes: [],
        coupon: {},
        delivery_time: "ASAP",
        special_request: "",
        restaurant_id: "",
        changed: 0,
        index_time: {},
        trans_id: "",
        stripe_id: "",
        client_id: "",
        card_id: "",
      };
    }
    default:
      return state;
  }
}
