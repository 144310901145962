import { Clipboard } from "@ionic-native/clipboard";
import { IonContent, IonPage, IonToast } from "@ionic/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Header from "../../components/Shared/Header";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import black from "../../assets/img/icons/common/black-giftcard.svg";
import blue from "../../assets/img/icons/common/blue-giftcard.svg";
import red from "../../assets/img/icons/common/red-giftcard.svg";
import yellow from "../../assets/img/icons/common/yellow-giftcard.svg";

const GiftCardDetail: React.FC = (props: any) => {
  //const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [giftcard, setGiftcard] = useState<any>({});
  const [showToast, setShowToast] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const textAreaRef = useRef<any>(null);
  const colors = [red, blue, yellow, black];

  useEffect(() => {
    setLoaded(false);
    axios
      .get("coupons", {
        params: {
          id: props.match.params.id,
          coupon_code: props.history?.location?.state.coupon_code,
        },
      })
      .then((res) => {
        setGiftcard(res);
        setLoaded(true);
      });
  }, []);

  return (
    <IonPage>
      <Header
        name="Gift Cards Details"
        click={() => props.history.goBack()}
        back={true}
      ></Header>
      <IonContent className="ht-pt-80">
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Copied"
          animated
          duration={1000}
          cssClass="ht-toast"
        />
        {loaded ? (
          <GiftCard
            color={
              giftcard.discount == 20
                ? colors[0]
                : giftcard.discount == 30
                ? colors[1]
                : giftcard.discount == 50
                ? colors[2]
                : colors[3]
            }
          >
            <div className="p p1">
              <img
                src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
                alt=""
                className="logo"
              />
              <div className="description">
                You can use or share this gift card with a friend. This gift
                card can redeem{" "}
                <span className="coins">
                  {Number(giftcard.discount).toFixed(0)} COINS
                </span>
              </div>
            </div>
            <div className="p p2">
              <div className="price">
                <span>$</span>
                {Number(giftcard.price).toFixed(0)}
              </div>
              {/* <button className="purchase" onClick={()=>props.history.push('/gift_cards/')}>DETAILS</button> */}
            </div>
          </GiftCard>
        ) : (
          <div>
            <div style={{ margin: "43px auto 48px", maxWidth: 374 }}>
              <div style={{ margin: "0 -0.75rem" }}>
                <GeneralSkeletonText />
              </div>
            </div>
          </div>
        )}

        <GiftCardGuide>
          <div className="title">Terms and Conditions</div>
          <div className="content">
            You can use this gift card to get coins or share it with a friend.
            Coins can be used in all Chicko Chicken restaurants. Orders with
            coins can also get reward points. This gift card cannot be exchanged
            for cash or return after purchased.
          </div>
        </GiftCardGuide>
        <Direction>
          <div className="code">
            {props.history?.location?.state?.coupon_code}
          </div>
          <input
            id="code"
            type="text"
            onChange={() => {}}
            ref={textAreaRef}
            value={
              props.history?.location?.state?.coupon_code
                ? props.history?.location?.state?.coupon_code
                : ""
            }
            hidden
          />
          <button
            className="copy"
            onClick={() => {
              Clipboard.copy(props.history?.location?.state?.coupon_code);
              setShowToast(true);
            }}
          >
            Copy
          </button>
        </Direction>
      </IonContent>
    </IonPage>
  );
};

const Direction = styled.div`
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 15px;
  margin: 20px auto 10px;
  max-width: 374px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .code {
    font-size: 22px;
    color: #5d5d5d;
    border-bottom: 1px solid #707070;
  }
  .copy {
    width: 76px;
    height: 26px;
    background-color: #c81021;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
  }
`;

const GiftCardGuide = styled.div`
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 15px;
  margin: 20px auto 10px;
  max-width: 374px;
  .title {
    color: #707070;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-botton: 5px;
  }
  .content {
    color: #707070;
    text-align: justify;
  }
`;

const GiftCard = styled.div`
  border-radius: 20px;
  background-image: ${(props) => "url(" + props.color + ")"};
  background-size: 100% 100%;
  padding-left: 63px;
  padding-top: 5px;
  max-width: 374px;
  margin: 20px auto 10px;
  .p {
    display: flex;
    align-items: center;
    &.p1 {
      padding: 10px 0;
      .description {
        color: #707070;
        font-size: 13px;
        padding-right: 20px;
      }
      .coins {
        color: #3b7fd4;
        font-size: 15px;
        font-weight: bolder;
        margin-left: 3px;
      }
    }
    &.p2 {
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      .price {
        font-size: 35px;
        font-weight: bolder;
        span {
          font-size: 27px;
        }
      }
      .purchase {
        height: 35px;
        border-radius: 30px;
        background-color: ${(props) =>
          props.tab == 0 ? "#FF8039" : "#C81021"};
        color: #fff;
        padding: 0 20px;
        font-size: 15px;
      }
    }
  }
  .logo {
    height: 40px;
    margin: 10px 17px 10px 18px;
  }
`;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardDetail);
