import { infoConstants } from "../helpers/constants";

const INITIAL_STATE = {
  coordinate: {},
  address: "",
  type: "Pickup At",
  restaurant_address: "",
  restaurant_id: "",
  addon_type: "",
  api_key: "",
  restaurant_street: "",
  restaurant_phone: "",
  restaurant: {},
  categories: {}
};

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case infoConstants.SET_LOCATION: {
      return { ...state, coordinate: action.payload };
    }
    case infoConstants.SET_ADDRESS: {
      return { ...state, address: action.payload };
    }
    case infoConstants.SET_TYPE: {
      return { ...state, type: action.payload };
    }
    case infoConstants.SET_RESTAURANT_ADDRESS: {
      return { ...state, restaurant_address: action.payload };
    }
    case infoConstants.SET_RESTAURANT_ID: {
      return { ...state, restaurant_id: action.payload };
    }
    case infoConstants.SET_ADDON_TYPE: {
      return { ...state, addon_type: action.payload };
    }
    case infoConstants.SET_API_KEY: {
      return { ...state, api_key: action.payload };
    }
    case infoConstants.SET_RESTAURANT_STREET: {
      return { ...state, restaurant_street: action.payload };
    }
    case infoConstants.SET_RESTAURANT_PHONE: {
      return { ...state, restaurant_phone: action.payload };
    }
    case infoConstants.SET_RESTAURANT: {
      return { ...state, restaurant: action.payload };
    }
    case infoConstants.SET_CATEGORIES: {
      return { ...state, categories: action.payload };
    }
    default:
      return state;
  }
}
