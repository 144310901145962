import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { alertActions } from "../../actions/alert";
import Header from "../../components/Shared/Header";

const PaymentSuccess: React.FC = (props: any) => {
  const dispatch = useDispatch();

  let total, unique_id, rewards;
  total = props.history?.location?.state?.data?.total;
  unique_id = props.history?.location?.state?.data?.unique_id;
  rewards = props.history?.location?.state?.data?.rewards;

  return (
    <IonPage>
      <Header
        name={props.info.restaurant_address}
        click={() => (window.location.href = "/dashboard/home")}
        back={false}
      ></Header>
      <IonContent>
        <div className="h-flex mt-7 ">
          <div className="col-12 payment">
            <div className="h-form-ordertime h-bgr-fff   ">
              <div className="h-flex j-center h-height-70px ">
                <div className="h-logo-chicko-chicken-center "></div>
              </div>
              <div className="h-flex j-center pt-20">
                <div className="h-bold h-font-20">Thank You!</div>
              </div>
              <div className="h-flex j-center h-pb-40">
                <div className="h-font-20 h-bold">
                  {props.history?.location?.state?.data?.payment_mode == "COD"
                    ? "Order Successfull"
                    : "Payment Successfull"}
                </div>
              </div>
              <div className="h-flex h-flex__wrap pb-15 h-plr-15">
                <div className="h-max  h-line__bottom">
                  <div className="h-flex a-center h-pb-10 h-between ">
                    <div className="">
                      <label className="">Your Order Number</label>
                    </div>
                    <label>{unique_id}</label>
                  </div>
                </div>
                <div className="h-max  h-line__bottom">
                  <div className="h-flex a-center h-pb-10 pt-10 h-between ">
                    <div className="">
                      <label className="">
                        Ready for{" "}
                        {props.history?.location?.state?.data?.type == 0
                          ? "Delivery"
                          : "Pickup"}
                      </label>
                    </div>
                    <label>
                      {props.history?.location?.state?.data?.delivery_time}
                    </label>
                  </div>
                </div>
                <div className="h-max h-line__bottom">
                  <div className="h-flex a-center h-pb-10 pt-10 h-between ">
                    <div className="">
                      <label className="">Points Earned</label>
                    </div>
                    <label>{rewards} pts</label>
                  </div>
                </div>
                <div className="h-max">
                  <div className="h-flex a-center h-pb-10 pt-10 h-between ">
                    <div className="">
                      <label className="">Total Payment</label>
                    </div>
                    <label>${Number(total).toFixed(2)}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <div className="h-btn-alws-bottom button-inner">
        <button
          type="submit"
          className="btn-border-rdu-30"
          color="primary"
          onClick={() => {
            dispatch(alertActions.page_loader(true));
            // window.location.href = "/dashboard/orders";

            props.history.push("/dashboard/orders");
            setTimeout(() => {
              dispatch(alertActions.page_loader(false));
            }, 1000);
          }}
        >
          ORDER LIST
        </button>
      </div>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return {
    info: state.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
