import { useIonViewWillEnter } from "@ionic/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { Col } from "reactstrap";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/auth";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import axios from "../../helpers/axiosInterceptor";

interface RoutesIF {
  history: any; // Change the required prop to an optional prop.
}

const SavedAddress: React.FC<RoutesIF> = ({ history }) => {
  const [addresses, setAddresses] = useState([]);
  const [defaultAddres, setDefaultAddres] = useState(0);
  const [load, setLoad] = useState(false);

  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    setLoad(false);
    axios
      .get(`/user/addresses`)
      .then((res) => {
        setAddresses(res.data);
        res.data.map((data) => {
          if (data.is_primary) {
            setDefaultAddres(data.id);
            actions.set_address({
              id: data.id,
              label: data.label,
              full_address: data.street,
              lat: data.latitude,
              lng: data.longitude,
            });
          }
        });
        dispatch(alertActions.page_loader(false));
        setLoad(true);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const changeDefaultAddress = (id) => {
    axios
      .put(`/user/address`, { id })
      .then((res) => {
        const data = res.data;
        dispatch(
          actions.set_address({
            id: data.id,
            label: data.label,
            full_address: data.street,
            lat: data.latitude,
            lng: data.longitude,
          })
        );

        history.push("/choose_restaurant");
      })
      .catch((err) => {
        dispatch(alertActions.error("There are errors, please try again!"));
      });
  };

  const deleteAddress = (id) => {
    setLoad(false);
    axios
      .delete(`/user/address`, { data: { id } })
      .then((res) => {
        axios
          .get(`/user/addresses`)
          .then((res) => {
            setAddresses(res.data);
            if (res.data.length == 0) dispatch(actions.set_address(null));
            res.data.map((data) => {
              if (data.is_primary) {
                setDefaultAddres(data.id);
                console.log(data);
                dispatch(
                  actions.set_address({
                    id: data.id,
                    label: data.label,
                    full_address: data.street,
                    lat: data.latitude,
                    lng: data.longitude,
                  })
                );
              }
            });
            setLoad(true);
          })
          .catch((err) => {
            dispatch(alertActions.error("There are errors, please try again!"));
          });
      })
      .catch((err) => {
        dispatch(alertActions.error("There are errors, please try again!"));
      });
  };

  return (
    <>
      {load && addresses.length > 0 ? (
        addresses.map((address: any, idx) => {
          return (
            <div
              className="h-form-restaurant h-bgr-fff"
              key={idx}
              style={{ marginTop: 15 }}
            >
              <div className="h-flex h-between">
                <div
                  className="h-flex h-flex__nowrap"
                  style={{
                    width: "calc(100% - 38px)",
                    padding: "0 18px",
                  }}
                  onClick={() => changeDefaultAddress(address.id)}
                >
                  <div className="h-flex a-center">
                    <img
                      src={
                        defaultAddres != address.id
                          ? require("../../assets/img/icons/common/home.svg")
                          : require("../../assets/img/icons/common/home-active.svg")
                      }
                    />
                  </div>
                  <div
                    className="h-restaurant-address"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      minHeight: 80,
                      padding: "9px 0 11px 15px",
                    }}
                  >
                    <label
                      className="h-bold"
                      style={{ display: "flex", width: "100%" }}
                    >
                      {" "}
                      {address.label}
                    </label>
                    <Ellipsis className="h-color-707070">
                      {address.street}
                    </Ellipsis>
                  </div>
                </div>

                <div
                  className="h-flex a-center h-mr-3"
                  style={{ width: 30, position: "relative" }}
                  onClick={() => deleteAddress(address.id)}
                >
                  <img
                    src={require("../../assets/img/icons/common/delete.svg").default}
                  />
                </div>
              </div>
            </div>
            // <Row className="flex-row flex-wrap shadow-0 px-3 border-bottom" key={address.id} style={{ padding: "20px 0 10px" }}>
            // 	<Col className="p-0">
            // 		<div className="custom-control custom-radio mb-3">
            // 			<input
            // 				className="custom-control-input"
            // 				id={`customCheck_${address.id}`}
            // 				name="address"
            // 				type="radio"
            // 				value={address.id}
            // 				{...((defaultAddres === address.id) && { defaultChecked: true })}
            // 				//onChange={() => changeDefaultAddress(address.id)}
            // 				onClick={() => changeDefaultAddress(address.id)}
            // 			/>
            // 			<label className="custom-control-label w-100" htmlFor={`customCheck_${address.id}`}>
            // 				<CardBody className="pl-2 pr-0 py-0 h-restaurant">
            // 					<h6 className="my-0 font-weight-bold text-capitalize">{address.label} </h6>
            // 					<small className="text-capitalize item-name">{address.street}</small>
            // 				</CardBody>
            // 			</label>
            // 		</div>

            // 	</Col>
            // 	{defaultAddres !== address.id && (
            // 		<Col xs="2" className="p-0 pt-1">
            // 			<Badge color="secondary" pill className="text-danger" onClick={() => deleteAddress(address.id)}>Delete</Badge>
            // 		</Col>
            // 	)}

            // </Row>
          );
        })
      ) : load && addresses.length == 0 ? (
        <div
          style={{
            marginTop: "calc(50vh - 250px)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col
            style={{
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <h4 className="font-weight-light text-center text-muted">You have no orders</h4> */}
            <img
              src={require("../../assets/img/icons/common/empty_address.png").default}
              style={{ height: 174, width: 174, marginBottom: 20 }}
              alt="pan"
            />
            <div style={{ fontSize: 22, color: "#707175" }}>
              You have no address here
            </div>
            {/* <h1><IonIcon icon={receiptOutline} /></h1> */}
          </Col>
        </div>
      ) : (
        [...Array(1)].map((e, i) => <GeneralSkeletonText key={i} />)
      )}

      {addresses.length > 0 ? (
        <div className="h-form-restaurant h-bgr-fff" style={{ marginTop: 15 }}>
          <Link to="/choose-address">
            <div
              style={{
                color: "#045ADE",
                height: 55,
                fontSize: 16,
                textAlign: "center",
                lineHeight: "55px",
              }}
            >
              +Add New Address
            </div>
          </Link>
        </div>
      ) : (
        <div
          className="h-form-restaurant h-bgr-fff"
          style={{
            marginTop: 15,
            position: "fixed",
            width: "calc(100% - 30px)",
            bottom: 20,
          }}
        >
          <Link to="/choose-address">
            <div
              style={{
                color: "#045ADE",
                height: 55,
                fontSize: 16,
                textAlign: "center",
                lineHeight: "55px",
              }}
            >
              +Add New Address
            </div>
          </Link>
        </div>
      )}
    </>
  );
};
const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
`;

export default SavedAddress;
