import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { alertActions } from "../../actions/alert";
import Header from "../../components/Shared/Header";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import axios from "../../helpers/axiosInterceptor";

const Orders: React.FC = (props: any) => {
  const [orderList, setOrderList] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const currency_symbol = useSelector(
    (state: RootStateOrAny) => state.auth.currency_symbol
  );

  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  async function fetchData(temp) {
    axios
      .get("/orders?page=" + temp)
      .then(async (res: any) => {
        if (res && res.data && res.data.length > 0) {
          setOrderList([...orderList, ...res.data]);
          setDisableInfiniteScroll(res.data.length < 5);
        } else {
          setDisableInfiniteScroll(true);
        }
      })
      .catch((err) => console.error(err));
  }

  async function searchNext($event: CustomEvent<void>) {
    let temp = page + 1;
    setPage(temp);
    await fetchData(temp);

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  useIonViewDidEnter(() => {
    axios
      .get(`/orders`)
      .then((res) => {
        const data = res.data;

        setOrderList(data);
        setDisableInfiniteScroll(data.length < 5);
        setLoad(true);

        // setTimeout(() => {
        //   setLoad(false)
        // }, 30 * 1000)
      })
      .catch((err) => {
        dispatch(alertActions.error("There are errors, please try again!"));
        dispatch(alertActions.page_loader(false));
      });
  });

  useEffect(() => {
    if (!load) {
      axios
        .get(`/orders`)
        .then((res) => {
          const data = res.data;

          setOrderList(data);
          setDisableInfiniteScroll(data.length < 5);
          setLoad(true);

          // setTimeout(() => {
          //   setLoad(false)
          // }, 30 * 1000)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [load]);

  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }

  const addons_list = (addons_dish) =>
    addons_dish.map((d, i) => (
      <h6 className="font-weight-light item-name sub-info" key={i}>
        {/* {d.name}:  */}
        {d.addons.map((d1, i1) => {
          return d1.name + (i1 == d.addons?.length - 1 ? "" : ", ");
        })}
      </h6>
    ));

  return (
    <IonPage>
      <Header
        name="Orders"
        click={() => props.history.goBack()}
        back={false}
      ></Header>

      <IonContent fullscreen>
        {load ? (
          orderList.length == 0 ? (
            <Container fluid={true} className="h-100 d-flex align-items-center">
              <Col
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <h4 className="font-weight-light text-center text-muted">You have no orders</h4> */}
                <img
                  src={require("../../assets/img/icons/common/order.png").default}
                  style={{ height: 174, width: 174, marginBottom: 20 }}
                  alt="pan"
                />
                <div style={{ fontSize: 22, color: "#707175" }}>
                  You have no orders here
                </div>
                {/* <h1><IonIcon icon={receiptOutline} /></h1> */}
              </Col>
            </Container>
          ) : (
            <div>
              {orderList.map((data: any, key) => {
                return (
                  <div
                    className="h-flex pt-20"
                    key={key}
                    style={
                      key == orderList.length - 1 ? { marginBottom: 20 } : {}
                    }
                    onClick={() => props.history.push(`/order/${data.id}`)}
                  >
                    <div className="col-12">
                      <div className="h-form-ordertime h-bgr-fff">
                        <div
                          className="h-flex h-between h-payment__header a-center"
                          style={{ padding: "0 15px" }}
                        >
                          <div className="h-flex a-center">
                            <div className="h-okelelele h-pr-10">
                              <IonLabel
                                className="h-bold h-000E90"
                                style={{ fontSize: 16 }}
                              >
                                {data.unique_id}
                              </IonLabel>
                            </div>
                            <span className="h-date ">
                              {" "}
                              {data.created_at_restaurant}
                            </span>
                          </div>
                          <div className="h-flex j-end">
                            <button
                              className={
                                "h-button_payment_header " +
                                (data.status == "ORDERED"
                                  ? "btn-order"
                                  : data.status == "CONFIRMED"
                                  ? "btn-confirm"
                                  : data.status == "COMPLETED"
                                  ? "btn-complete"
                                  : "btn-cancel")
                              }
                              style={{ fontSize: 12 }}
                            >
                              {data.status}
                            </button>
                          </div>
                        </div>
                        {data.order_dishes.map((val, idx) => {
                          const {
                            name,
                            id,
                            price,
                            quantity,
                            is_veg,
                            order_adddons,
                            description,
                            image,
                          } = val;
                          return (
                            <div className="h-flex h-between pt-10 " key={idx}>
                              <div className="col-10 h-flex h-flex__nowrap">
                                <div
                                  className="h-body-img-product-payment1"
                                  style={{
                                    backgroundImage: "url(" + image + ")",
                                  }}
                                ></div>
                                <div
                                  className="h-pl-10 h-product-payment__text"
                                  style={{ width: "calc(100% - 70px)" }}
                                >
                                  <label style={{ width: "100%" }}>
                                    {quantity} x{" "}
                                    <label className="h-bold">{name}</label>
                                  </label>
                                  <label className="h-text-small">
                                    {order_adddons.map((d, i) => (
                                      <span
                                        className="font-weight-light item-name sub-info"
                                        key={i}
                                      >
                                        {d.name +
                                          (i == order_adddons?.length - 1
                                            ? ""
                                            : ", ")}
                                      </span>
                                    ))}
                                  </label>
                                </div>
                              </div>
                              <div className="col-2 h-pt-5 h-flex j-end">
                                <label>${(price * quantity).toFixed(2)}</label>
                              </div>
                            </div>
                          );
                        })}

                        <div
                          className="h-flex h-between pt-10 h-pb-10 a-center"
                          style={{
                            borderTop: "1px solid #F0F0F0",
                            marginTop: 10,
                          }}
                        >
                          <div className="col-10">
                            <label className="h-bold">Total</label>
                          </div>
                          <div className="col-2 h-flex j-end">
                            <label className="h-bold h-font-17">
                              {currency_symbol + data.total}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <IonInfiniteScroll
                threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
              >
                <IonInfiniteScrollContent loadingText="Loading..."></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </div>
          )
        ) : (
          [...Array(5)].map((e, i) => (
            <Container className="mt-3 p-0" key={i}>
              <GeneralSkeletonText />
            </Container>
          ))
        )}
      </IonContent>
    </IonPage>
  );
};
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
