import {
  IonContent,
  IonPage,
  IonToast,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { Input } from "reactstrap";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/info";
import Header from "../../components/Shared/Header";
import RestaurantBar from "../../components/Shared/RestaurantBar";
import Restaurant from "../../components/Shared/RestaurantMap";
import FullSkeletonText from "../../components/skeleton_text/full_restaurant";
import axios from "../../helpers/axiosInterceptor";

const ChooseRestaurant: React.FC = (props: any) => {
  const location = useSelector(
    (state: RootStateOrAny) => state.info?.coordinate
  );

  let _mapRef;
  const [apiKey, setApiKey] = useState("");
  const [center, setCenter] = useState({
    lat: 45.738880495333284,
    lng: -73.36816536632661,
  });
  const [searchAddress, setSearchAddress] = useState("");
  const [mapEnabled, setMapEnabled] = React.useState(true);
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [tab, setTab] = useState(0);
  const [restaurantNearby, setRestaurantNearby] = useState<any>([]);
  const [loadRestaurant, setLoadRestaurant] = useState(true);
  const [allRestaurant, setAllRestaurant] = useState<any>([]);
  const [favouriteRestaurants, setFavouriteRestaurant] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [keyCity, setKeyCity] = useState("");
  const [showList, setShowList] = useState(false);
  const [activeRestaurant, setActiveRestaurant] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  const slide = (e, dir) => {
    if (!e.event.target.parentElement.className.includes("prevent")) {
      if (dir == "Down") {
        setShowModal(false);
      } else if (dir == "Up") {
        setShowModal(true);
      }
    }
  };
  const handlers2 = useSwipeable({
    onSwipedDown: (e) => slide(e, "Down"),
    onSwipedUp: (e) => slide(e, "Up"),
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });
  const handlers = useSwipeable({
    onSwipedDown: (e) => slide(e, "Down"),
    onSwipedUp: (e) => slide(e, "Up"),
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const toggle = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };
  // const apiIsLoaded = (map, maps) => {
  //   const directionsService = new google.maps.DirectionsService();
  //   const directionsRenderer = new google.maps.DirectionsRenderer();
  //   directionsRenderer.setMap(map);
  //   const origin = { lat: 40.756795, lng: -73.954298 };
  //   const destination = { lat: 41.756795, lng: -78.954298 };

  //   directionsService.route(
  //     {
  //       origin: origin,
  //       destination: destination,
  //       travelMode: google.maps.TravelMode.DRIVING
  //     },
  //     (result, status) => {
  //       if (status === google.maps.DirectionsStatus.OK) {
  //         directionsRenderer.setDirections(result);
  //       } else {
  //         console.error(`error fetching directions ${result}`);
  //       }
  //     }
  //   );
  // };

  const reload = (id) => {
    setLoadRestaurant(true);
    let params =
      props.info.type == "Delivery To"
        ? {
          lat: props.auth.default_address?.lat
            ? props.auth.default_address?.lat
            : props.coordinate.lat,
          long: props.auth.default_address?.lng
            ? props.auth.default_address?.lng
            : props.coordinate.lng,
        }
        : {
          lat: props.coordinate.lat,
          long: props.coordinate.lng,
        };
    axios
      .post("/restaurant/favourite", {
        res_id: id,
      })
      .then((res) => {
        axios
          .get("/restaurants/all", {
            params,
          })
          .then((res: any) => {
            res.sort((a, b) => a.distance - b.distance);
            setAllRestaurant(res);
            setCenter({
              lat: res[0].addresses[0].latitude,
              lng: res[0].addresses[0].latitude,
            });
            setLoadRestaurant(false);
          });
        if (props.auth.user || props.history?.location?.state?.id) {
          axios.get("/restaurant/list_favourite").then((res) => {
            let temp: any[] = [];
            res.data.forEach((val: any, idx) => temp.push(val.id));
            setFavouriteRestaurant(temp);
          });
        } else {
          setFavouriteRestaurant([]);
        }
      });
  };
  useIonViewWillEnter(() => {
    setLoadRestaurant(true);
    setLoaded(false);
    let params =
      props.info.type == "Delivery To"
        ? {
          lat: props.auth.default_address?.lat
            ? props.auth.default_address?.lat
            : props.coordinate.lat,
          long: props.auth.default_address?.lng
            ? props.auth.default_address?.lng
            : props.coordinate.lng,
        }
        : {
          lat: props.coordinate.lat,
          long: props.coordinate.lng,
        };

    axios
      .get("/restaurants/all", {
        params: params,
      })
      .then((res: any) => {
        res.sort((a, b) => a.distance - b.distance);
        setAllRestaurant(res);
        setLoadRestaurant(false);
        setCenter({
          lat: res[0].addresses[0].latitude,
          lng: res[0].addresses[0].longitude,
        });
        setTimeout(() => {
          setLoaded(true);
        }, 1000);
        axios
          .get("/restaurants/all_cities", {
            params,
          })
          .then((res: any) => {
            setCities(res);
            if (props.auth.user || props.history?.location?.state?.id) {
              axios.get("/restaurant/list_favourite").then((res) => {
                let temp: any[] = [];
                res.data.forEach((val: any, idx) => temp.push(val.id));
                setFavouriteRestaurant(temp);
              });
            } else {
              setFavouriteRestaurant([]);
            }
          });
      });
  }, [props.info.type, props.user?.id, props.auth.default_address?.lat]);

  const reverseGeoCoder = async (lat: number, lng: number) => {
    await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      lat +
      "," +
      lng +
      "&key=" +
      apiKey
    )
      .then((response) => response.json())
      .then((responseJson) => {
        const results = responseJson.results;
        results.some((c) => {
          let foundAddress = false;
          switch (c.types[0]) {
            case "street_address":
              setSearchAddress(c.formatted_address);
              setCenter(c.geometry.location);
              foundAddress = true;
              break;
            case "route":
            case "locality":
              setSearchAddress(c.formatted_address);
              setCenter(c.geometry.location);
              foundAddress = true;
              break;
            default:
              break;
          }
          return foundAddress;
        });
        // console.log(results);
        setMapEnabled(true);
      });
  };

  const createMapOptions = function (maps) {
    return {
      disableDefaultUI: true,
    };
  };

  const onChooseRestaurant = function (index) {
    setActiveRestaurant(index);
    setCenter({
      lat: allRestaurant[index].addresses[0].latitude,
      lng: allRestaurant[index].addresses[0].longitude,
    });
    setShowModal(false);
  };

  const changeType = async () => {
    setShowAlert(true);
    // if (props.info.type == "Delivery To") {
    //   await dispatch(actions.set_type("Pickup At"));
    // } else {
    //   await dispatch(actions.set_type("Delivery To"));
    // }
  };
  return (
    <IonPage>
      <Header
        name={
          props.info.type == "Delivery To"
            ? "Choose Delivery Restaurant"
            : "Choose Pickup Restaurant"
        }
        back={false}
        click={() => props.history.goBack()}
      ></Header>



      <IonContent>
        <SwipeContainer {...handlers2} type={showModal.toString()}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: 20,
              top: 0,
              zIndex: 5,
            }}
            onClick={() => {
              toggle();
            }}
          ></div>
          <div className="gray-bar"></div>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setTab(0)}
                className={tab == 0 ? "tab active" : "tab"}
              >
                Nearby
              </div>
              <div
                onClick={() => setTab(1)}
                className={tab == 1 ? "tab active" : "tab"}
              >
                Favourites
              </div>
            </div>
          </div>
          {tab == 0 ? (
            <div className="list-restaurant prevent">
              {loadRestaurant ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={require("../../assets/img/icons/common/loading.gif").default}
                    alt=""
                    style={{ height: 150 }}
                  />
                </div>
              ) : (
                <>
                  {allRestaurant.map((val, idx) => {
                    if (!showList && keyCity != "" && val.city.name == keyCity) {
                      return (
                        <RestaurantBar
                          name={val.name}
                          address={val.addresses[0].street}
                          hideBottom={
                            idx == restaurantNearby.length - 1 ? true : false
                          }
                          key={idx}
                          distance={val.distance}
                          click={() => onChooseRestaurant(idx)}
                          history={props.history}
                          restaurant={val}
                          load={reload}
                          favourite={favouriteRestaurants.includes(val.id)}
                        ></RestaurantBar>
                      );
                    }
                  })}
                  {allRestaurant.map((val, idx) => {
                    if (!showList && keyCity != "" && val.city.name != keyCity) {
                      return (
                        <RestaurantBar
                          name={val.name}
                          address={val.addresses[0].street}
                          hideBottom={
                            idx == restaurantNearby.length - 1 ? true : false
                          }
                          key={idx}
                          distance={val.distance}
                          click={() => onChooseRestaurant(idx)}
                          history={props.history}
                          restaurant={val}
                          load={reload}
                          favourite={favouriteRestaurants.includes(val.id)}
                        ></RestaurantBar>
                      );
                    }
                  })}
                  {allRestaurant.map((val, idx) => {
                    if (keyCity == "") {
                      return (
                        <RestaurantBar
                          name={val.name}
                          address={val.addresses[0].street}
                          hideBottom={
                            idx == restaurantNearby.length - 1 ? true : false
                          }
                          key={idx}
                          distance={val.distance}
                          click={() => onChooseRestaurant(idx)}
                          history={props.history}
                          restaurant={val}
                          load={reload}
                          favourite={favouriteRestaurants.includes(val.id)}
                        ></RestaurantBar>
                      );
                    }
                  })}
                </>
              )}
            </div>
          ) : (
            <div className="list-restaurant prevent">
              {loadRestaurant ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={require("../../assets/img/icons/common/loading.gif").default}
                    alt=""
                    style={{ height: 150 }}
                  />
                </div>
              ) : favouriteRestaurants.length > 0 ? (
                <>
                  {allRestaurant.map((val, idx) => {
                    if (
                      !showList &&
                      keyCity != "" &&
                      val.city.name == keyCity &&
                      favouriteRestaurants.includes(val.id)
                    ) {
                      return (
                        <RestaurantBar
                          name={val.name}
                          address={val.addresses[0].street}
                          hideBottom={
                            idx == restaurantNearby.length - 1 ? true : false
                          }
                          key={idx}
                          distance={val.distance}
                          click={() => onChooseRestaurant(idx)}
                          history={props.history}
                          restaurant={val}
                          favourite={true}
                          load={reload}
                        ></RestaurantBar>
                      );
                    }
                  })}
                  {allRestaurant.map((val, idx) => {
                    if (
                      !showList &&
                      keyCity != "" &&
                      val.city.name != keyCity &&
                      favouriteRestaurants.includes(val.id)
                    ) {
                      return (
                        <RestaurantBar
                          name={val.name}
                          address={val.addresses[0].street}
                          hideBottom={
                            idx == restaurantNearby.length - 1 ? true : false
                          }
                          key={idx}
                          distance={val.distance}
                          click={() => onChooseRestaurant(idx)}
                          history={props.history}
                          restaurant={val}
                          favourite={true}
                          load={reload}
                        ></RestaurantBar>
                      );
                    }
                  })}
                  {allRestaurant.map((val, idx) => {
                    if (keyCity == "" && favouriteRestaurants.includes(val.id)) {
                      return (
                        <RestaurantBar
                          name={val.name}
                          address={val.addresses[0].street}
                          hideBottom={
                            idx == restaurantNearby.length - 1 ? true : false
                          }
                          key={idx}
                          distance={val.distance}
                          click={() => onChooseRestaurant(idx)}
                          history={props.history}
                          restaurant={val}
                          favourite={true}
                          load={reload}
                        ></RestaurantBar>
                      );
                    }
                  })}
                </>
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={require("../../assets/img/icons/common/heart.png").default}
                    alt=""
                    style={{ height: 30, marginBottom: 10, opacity: 0.5 }}
                  />
                  <div style={{ fontSize: 15, color: "rgb(112, 113, 117)" }}>
                    You don't have any favourite restaurant
                  </div>
                </div>
              )}
            </div>
          )}
          {/* HIDE DELIVERY PART */}
          {/* <div style={{ width: "100%", padding: "0 20px" }} className="prevent">
            <div
              className="choose-favourite prevent"
              onClick={() => changeType()}
            >
              <img
                src={
                  props.info.type == "Delivery To"
                    ? require("../../assets/img/icons/common/bag.svg").default
                    : require("../../assets/img/icons/common/delivery.svg").default
                }
                alt=""
                style={
                  props.info.type == "Delivery To"
                    ? { margin: "0 30px 0 20px" }
                    : { margin: "0 20px 0 10px" }
                }
              />
              
              <div className="mid prevent">
                <div className="title">
                  {props.info.type == "Delivery To"
                    ? "Change to pick up"
                    : "Change to delivery"}
                </div>
                <div>Choose favorite restaurant</div>
              </div>
              <div className="last prevent">
                <span className="arrow"></span>
              </div>
            </div>
          </div> */}
        </SwipeContainer>
        {loaded && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "calc(100% - 40px)",

              left: 20,
            }}
            className="input-status-bar"
          >
            <div>
              <Input
                type="text"
                name="city"
                autoComplete="off"
                value={keyCity}
                onChange={(e) => {
                  setKeyCity(e.target.value);
                  setShowList(true);
                }}
                onFocus={(e) => {
                  setShowList(true);
                  setShowModal(false);
                }}
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "0px 2px 6px #00000033",
                  color: "#000",
                }}
                required
                placeholder="Enter the city..."
                icon={require("../../assets/img/icons/common/lock.svg").default}
              ></Input>
              <div
                style={{
                  position: "absolute",
                  width: 40,
                  height: "100%",
                  right: 0,
                  top: 0,
                  paddingLeft: 12,
                }}
                onClick={() => {
                  if (showList) {
                    setShowList(false);
                    setShowModal(true);
                  } else {
                    setShowList(true);
                    setShowModal(false);
                  }
                }}
              >
                <ArrowList showList={showList}></ArrowList>
              </div>
            </div>

            {showList ? (
              <CitiesList>
                {cities.map((val, idx) => {
                  if (val.name.toLowerCase().includes(keyCity.toLowerCase())) {
                    return (
                      <div
                        className="city"
                        key={idx}
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log(val);
                          setCenter({
                            lat: +val.latitude,
                            lng: +val.longitude,
                          });
                          setKeyCity(val.name);
                          setShowModal(true);
                          setShowList(false);
                        }}
                      >
                        <div className="city-01">
                          <div className="title">{val.name}</div>
                          <div className="distance">
                            {val.distance.toFixed(2)}km
                          </div>
                        </div>
                        <div className="city-02">{val.count_r} restaurants</div>
                      </div>
                    );
                  }
                })}
              </CitiesList>
            ) : null}
          </div>
        )}
        <div
          style={
            showModal
              ? {
                height: "calc(100vh - 370px)",
                width: "100%",
                transition: "0.3s",
              }
              : { height: "calc(100vh)", width: "100%", transition: "0.3s" }
          }
        >
          {loaded ? (
            <GoogleMapReact
              options={createMapOptions}
              defaultCenter={center?.lat ? center : props.coordinate}
              center={center?.lat ? center : props.coordinate}
              defaultZoom={9}
              ref={(map) => (_mapRef = map)}
              onDragEnd={(map) => {
                const lat = map.getCenter().lat();
                const lng = map.getCenter().lng();
                setCenter({ lat, lng });
                reverseGeoCoder(lat, lng);
              }}
              onChildClick={(e) => {
                setActiveRestaurant(e);
                setCenter({
                  lat: allRestaurant[e].addresses[0].latitude,
                  lng: allRestaurant[e].addresses[0].longitude,
                });
                setShowModal(false);
              }}
              onClick={({ x, y, lat, lng, event }) => {
                if (activeRestaurant) {
                  setActiveRestaurant(null);
                }
              }}
            // yesIWantToUseGoogleMapApiInternals
            // onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            >
              {allRestaurant.map((val, idx) => (
                <Marker
                  lat={val.addresses[0].latitude}
                  lng={val.addresses[0].longitude}
                  key={idx}
                >
                  {activeRestaurant == idx ? (
                    <div className="info">
                      <Restaurant
                        src={val.image}
                        name={val.name}
                        address={val.addresses[0].street}
                        description={val.description}
                        price={val.price}
                        history={props.history}
                        restaurant={val}
                        lat={val.addresses[0].latitude}
                        lng={val.addresses[0].longitude}
                      ></Restaurant>
                    </div>
                  ) : null}
                </Marker>
              ))}
            </GoogleMapReact>
          ) : (
            <>
              <FullSkeletonText />
            </>
          )}
        </div>
        <IonToast
          isOpen={showAlert}
          onDidDismiss={() => {
            setShowAlert(false);
          }}
          message={"Coming Soon"}
          duration={3500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};
const ArrowList = styled.div`
  content: "";
  position: absolute;
  height: 9px;
  width: 9px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  -webkit-transform: ${(props) =>
    props.showList ? "rotate(45deg)" : "rotate(-45deg)"};
  -ms-transform: ${(props) =>
    props.showList ? "rotate(45deg)" : "rotate(-45deg)"};
  transform: ${(props) =>
    props.showList ? "rotate(45deg)" : "rotate(-45deg)"};
  top: 17px;
  transition: 0.3s;
`;
const CitiesList = styled.div`
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0px 15px;
  max-height: 208px;
  background-color: #fff;
  overflow: scroll;
  box-shadow: 0px 2px 6px #00000033;
  .city {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0;
    &:last-child {
      border-bottom: none;
    }
    .city-01 {
      display: flex;
      font-size: 14px;
      .title {
        font-weight: bold;
        margin-right: 10px;
        color: #000;
      }
      .distance {
        color: #707070;
      }
    }
    .city-02 {
      color: #707070;
      font-size: 14px;
    }
  }
`;
const Marker = styled.div`
  height: 20px;
  width: 20px;
  background: #045ade;
  border-radius: 10px;
  border: 2px solid #fff;
  position: relative;
  .info {
    width: calc(100vw - 40px);
    border-radius: 3px;
    padding: 10px;
    left: calc(-50vw + 18px);
    position: absolute;
    top: -158px;
    height: 173px;
    &:after {
      content: "";
      position: absolute;
      border-top: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      bottom: 9px;
      left: 50%;
      z-index: 2;
    }
  }
`;
const Arrow = styled.div`
  display: block;
  height: 9px;
  width: 9px;
  border-right: 1px solid #858585;
  border-bottom: 1px solid #858585;
  transform: rotate(45deg);
  position: relative;
  top: -5px;
`;
const SwipeContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.type.toString() == "true" ? "0" : "-334px")};
  width: 100%;
  background: #fff;
  z-index: 1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  height: 360px;
  .gray-bar {
    width: 34px;
    height: 4px;
    background-color: #bfbfbf;
    margin-top: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  .list-restaurant {
    width: 100%;
    padding: 0 20px;
    height: 275px;
    overflow-y: scroll;
    overflow-x: auto;
  }
  .tab {
    width: 50%;
    text-align: center;
    border-bottom: 2px solid #eeeeee;
    padding-bottom: 8px;
    font-size: 15px;
    &.active {
      color: #045ade;
      border-bottom: 2px solid #045ade;
      font-weight: bold;
    }
  }
  .choose-favourite {
    border: 1px solid #c9c9c9;
    border-radius: 15px;
    display: flex;
    padding: 15px;
    box-shadow: 0 3px 3px 0 #d1d1d1;
    img {
      margin: 0 20px 0 10px;
      height: 45px;
      max-width: 65px;
    }
    .mid {
      width: 100%;
      .title {
        font-weight: bold;
      }
    }
    .last {
      display: flex;
      align-items: center;
      .arrow {
        border-right: 1px solid #7e7e7e;
        border-bottom: 1px solid #7e7e7e;
        transform: rotate(-45deg);
        height: 9px;
        width: 9px;
        display: flex;
      }
    }
  }
`;
const mapStateToProps = (state) => {
  return {
    coordinate: state.info.coordinate,
    info: state.info,
    user: state.auth.user,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseRestaurant);
