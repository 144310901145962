import {
  IonAlert,
  IonContent,
  IonLoading,
  IonPage,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { Form } from "reactstrap";
import actions from "../../actions/auth";
import Container from "../../components/Shared/Container";
import Input from "../../components/Shared/Input";
import axios from "../../helpers/axiosInterceptor";
import { authConstants } from "../../helpers/constants";
import styled from "styled-components";

const RecoverPassword: React.FC = (props: any) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const passwordReset = async () => {
    setShowLoading(true);
    try {
      const response = await axios.post("/password/reset", { email });
      setShowAlert(true);
      setShowLoading(false);
    } catch (e) {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: "Please enter correct email!",
      });
      setError(true);
      setShowLoading(false);
    }
  };
  useEffect(() => {
    props.state.errorMsg &&
      props.history?.location?.pathname == "/auth/recover" &&
      setShowToast(true);
  }, [props.state.errorMsg]);
  return (
    <IonPage>
      <IonContent className="bg-secondary">
        <Header>
          <img
            src={require("../../assets/img/icons/common/back-blue.svg").default}
            alt=""
            style={{ height: 60 }}
            onClick={() => props.history.replace('/auth/login')}
          />
        </Header>
        <Container>
          <div
            className="text-center text-muted mb-5 px-5 login-web"
            style={{ marginTop: "20%" }}
          >
            <img
              src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
              alt="Chicko Chicken"
              onClick={() => props.history.push("/choose_restaurant")}
            />
          </div>
          <Form role="form" className="form-login-web">
            <Input
              placeholder="Enter your email to reset password"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              icon={require("../../assets/img/icons/common/mail.svg").default}
            ></Input>

            {/* {props.state?.errorMsg && (
							<UncontrolledAlert color="warning">
								<strong>{props.state.errorMsg}</strong>
							</UncontrolledAlert>
						)} */}

            <div className="text-center">
              <button
                type="button"
                style={{
                  backgroundColor: "#0058df",
                  color: "#fff",
                  width: "100%",
                  borderRadius: 25,
                  height: 48,
                  fontSize: 18,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
                onClick={passwordReset}
              >
                RESET PASSWORD
              </button>
            </div>
          </Form>

          <div className="text-center text-muted my-4">
            <span style={{ color: "#707070", fontSize: 15 }}>
              Don't have an account?
              <Link
                className="ml-2"
                style={{ color: "#0058df" }}
                to="/auth/register"
              >
                Sign up
              </Link>
            </span>
          </div>
        </Container>
        <IonToast
          isOpen={
            showToast && props.history?.location?.pathname == "/auth/recover"
          }
          onDidDismiss={() => {
            dispatch({ type: authConstants.LOGIN_FAILURE });
            setShowToast(false);
          }}
          message={props.state.errorMsg}
          duration={3500}
          color="primary"
        />
      </IonContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Password Reset"}
        message={
          "We have sent the new password to your email address: <strong>" +
          email +
          "</strong>"
        }
        buttons={[
          {
            text: "Login now",
            handler: () => {
              props.history.replace("/auth/login");
            },
          },
        ]}
      />
    </IonPage>
  );
};
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 15px 10px;
`;
const mapStateToProps = (state) => {
  return { state: state.auth };
};

export default connect(mapStateToProps, actions)(RecoverPassword);
