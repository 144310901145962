import { IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import actions from "../../actions/cart";

interface SubBillProps {
  currency_symbol?: string;
  sub_total?: any;
  restaurant_charge?: number;
  delivery_charge?: number;
  taxes?: number;
  charges?: any;
  coupon?: any;
  coins?: any;
  changed?: any;
  cart?: any;
  info?: any;
  tax?: any;
  auth?: any;
}

const CartBillings: React.FC<SubBillProps> = ({
  currency_symbol,
  sub_total,
  charges,
  coupon,
  coins,
  ...props
}) => {
  const dispatch = useDispatch();
  const [tip, setTip] = useState<number>(0);
  function subTotal() {
    let delivery_charges =
      props.info?.type == "Delivery To"
        ? parseFloat(charges.delivery_charges)
        : 0;
    const subTotal =
      parseFloat(sub_total) +
      parseFloat(charges.restaurant_charges) +
      delivery_charges;
    return subTotal;
  }

  function tax() {
    const tax = parseFloat(props.tax);

    return Math.round(tax * 100) / 100;
  }
  function tip_changre() {
    const tip_changre = (parseFloat(sub_total) * tip) / 100;
    return Math.round(tip_changre * 100) / 100;
  }

  function coupons() {
    // let sub_total = subTotal();

    // if (coupon.id) {
    //   if (coupon.discount_type === "PERCENTAGE")
    //     sub_total = sub_total * (coupon.discount / 100);
    //   else if (coupon.discount_type === "FIXED") sub_total = coupon.discount;

    //   return Math.round(sub_total * 100) / 100;
    // }
    // return 0;
    return Number(coins);
  }

  const grand_total = () => {
    let coupon = coupons();
    let sub_total = subTotal() - coupon;

    const total = tax() + sub_total + tip_changre();
    return Math.round(total * 100) / 100;
  };

  const point = () => {
    return Math.floor((sub_total * Number(props.auth.point_percentage)) / 100);
  };

  const Taxes = () => <>{tax()}</>;
  const Coupon = () => <>{coupons()}</>;
  const GrandTotal = () => <>{grand_total()}</>;
  const Tip = () => <>{tip_changre()}</>;
  const Point = () => <>{point()}</>;

  useEffect(() => {
    dispatch(actions.grand_total(grand_total()));
    dispatch(actions.change_tip(tip_changre()));
  }, [coupon, tip, props.changed, coins, props.cart.dishes.length]);

  return (
    <>
      <div className="h-form-ordertime h-bgr-fff h-flex h-flex__wrap h-between pt-20 pb-20 h-plr-15">
        <div className="col-12 h-plr-0">
          <IonLabel className="h-lh-18">Please select your tip</IonLabel>
        </div>
        <div className="col-12 h-plr-0 mg-b10">
          <div className="h-flex h-space-around pt-10">
            <div
              className={
                " h-flex j-center a-center h-select-tip" +
                (tip == 10 ? " active" : "")
              }
              onClick={() => {
                setTip(10);
              }}
            >
              <label className="h-bold">10%</label>
            </div>
            <div
              className={
                " h-flex j-center a-center h-select-tip" +
                (tip == 15 ? " active" : "")
              }
              onClick={() => {
                setTip(15);
              }}
            >
              <label className="h-bold">15%</label>
            </div>
            <div
              className={
                " h-flex j-center a-center h-select-tip" +
                (tip == 20 ? " active" : "")
              }
              onClick={() => {
                setTip(20);
              }}
            >
              <label className="h-bold">20%</label>
            </div>
            <div
              className={
                " h-flex j-center a-center h-select-tip" +
                (tip == 0 ? " active" : "")
              }
              onClick={() => {
                setTip(0);
              }}
            >
              <label className="h-bold">None</label>
            </div>
          </div>
        </div>
        <div className="col-12 h-flex h-between pt-10 h-pb-10 h-line-e3e3e3 h-plr-0">
          <div>
            <IonLabel>Subtotal</IonLabel>
          </div>
          <IonLabel>${Number.parseFloat(sub_total).toFixed(2)}</IonLabel>
        </div>

        <div className="col-12 h-flex h-between pt-10 h-pb-10 h-line-e3e3e3 h-plr-0">
          <div>
            <IonLabel>Tax</IonLabel>
          </div>
          <IonLabel>${tax().toFixed(2)}</IonLabel>
        </div>

        <div className="col-12 h-flex h-between pt-10 h-pb-10 h-line-e3e3e3 h-plr-0">
          <div>
            <IonLabel>Tip</IonLabel>
          </div>
          <IonLabel>${tip_changre().toFixed(2)}</IonLabel>
        </div>
        {props.info?.type == "Delivery To" ? (
          <div className="col-12 h-flex h-between pt-10 h-pb-10 h-line-e3e3e3 h-plr-0">
            <div>
              <IonLabel>Delivery Fee</IonLabel>
            </div>
            <IonLabel>
              $
              {props.info?.type == "Delivery To"
                ? parseFloat(charges.delivery_charges)
                : 0}
            </IonLabel>
          </div>
        ) : null}

        {coins != 0 && (
          <div className="col-12 h-flex h-between pt-10 h-pb-10 h-line-e3e3e3 h-plr-0">
            <div>
              <IonLabel>Gift Card Coins</IonLabel>
            </div>
            <IonLabel>
              -$
              {coupons().toFixed(2)}
            </IonLabel>
          </div>
        )}
        <div className="col-12 h-flex h-between pt-10 h-pb-10 h-line-e3e3e3 h-plr-0">
          <div>
            <IonLabel>Points</IonLabel>
          </div>
          <IonLabel>{point()} Points</IonLabel>
        </div>

        <div className="col-12 h-flex h-between pt-10 h-plr-0 a-center">
          <div>
            <IonLabel className="h-bold">Total</IonLabel>
          </div>
          <IonLabel className="h-bold h-font-24">
            ${grand_total().toFixed(2)}
          </IonLabel>
        </div>
      </div>
      {/* <Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
				<Col className="p-0">
					<h6 className="my-0 font-weight-light item-name">Item Sub-Total </h6>
					<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

					</div>
				</Col>
				<Col xs="3" className="p-0">
					<span className="text-dark text-right font-weight-light d-block">{currency_symbol}{sub_total}</span>
				</Col>
			</Row>
			{
				(charges.restaurant_charges != null && parseFloat(charges.restaurant_charges) !== 0) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Restaurant Packaging Fee</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol}{charges.restaurant_charges}</span>
						</Col>
					</Row>
				)
			}
			{
				(charges.delivery_charges != null && parseFloat(charges.delivery_charges) !== 0) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Delivery Fee</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol}{charges.delivery_charges}</span>
						</Col>
					</Row>
				)
			}
			{
				(coupon.discount) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light text-success item-name">Coupon Disount</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-success text-right font-weight-light d-block">(-) {currency_symbol}<Coupon /></span>
						</Col>
					</Row>
				)
			}
			{
				(charges.taxes != null && parseFloat(charges.taxes) !== 0) && (
					<Row className="flex-row flex-wrap shadow-0 pt-2 px-3 m-0 border-0">
						<Col className="p-0">
							<h6 className="my-0 font-weight-light item-name">Taxes and Charges ({charges.taxes}%)</h6>
							<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

							</div>
						</Col>
						<Col xs="3" className="p-0">
							<span className="text-dark text-right font-weight-light d-block">{currency_symbol}<Taxes /></span>
						</Col>
					</Row>
				)
			}
			<Row className="flex-row flex-wrap shadow py-2 px-3 mt-2 mx-0 border-top">
				<Col className="p-0">
					<h5 className="my-0 font-weight-bold">Grand Total</h5>
					<div className="d-flex align-items-center justify-content-between text-muted pr-2 pt-1">

					</div>
				</Col>
				<Col xs="3" className="p-0">
					<h5 className="text-dark text-right font-weight-bold pr-0">{currency_symbol}<GrandTotal /></h5>
				</Col>
			</Row> */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth?.user,
    changed: state.cart.changed,
    cart: state.cart,
    info: state.info,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CartBillings);
