import {
  IonAlert,
  IonContent,
  IonLabel,
  IonPage,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container } from "reactstrap";
import actions from "../../actions/cart";
import CartBillings from "../../components/MenuCards/cart-billing";
import CartItems from "../../components/MenuCards/cart-items";
import Header from "../../components/Shared/Header";
import axios from "../../helpers/axiosInterceptor";
import { alertActions } from "../../actions/alert";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const PaymentCredit: React.FC = (props: any) => {
  const [succeeded, setSucceeded] = useState<any>(false);
  const [error, setError] = useState<any>(null);
  const [processing, setProcessing] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(true);
  const [clientSecret, setClientSecret] = useState<any>("");
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const { restaurant_id, total, id } = props.history?.location?.state.data;
    fetch("https://www.chickochicken.net/api/create.php", {
      method: "POST",
      body: JSON.stringify({
        res_id: restaurant_id,
        price: total,
        id: id,
        name: "",
        items: [{ id: "xl-tshirt" }],
      }),
    })
      .then((response) => response.json())
      .then((data: any) => {
        console.log(data);
        setClientSecret(data.clientSecret);
        // var stripe = Stripe(
        //   "pk_test_51J6eAJJBTiOeimMtCqBVQmOmMjiHpOvfXobU9zfwx91TnR8tYQXMvZQoR6JEnw0HH9LvBhstCLp7tFzNFpQqBigi00o4eMscK5"
        // );
        // var elements = stripe.elements();
        // var style = {
        //   base: {
        //     color: "#32325d",
        //     fontFamily: "Arial, sans-serif",
        //     fontSmoothing: "antialiased",
        //     fontSize: "16px",
        //     "::placeholder": {
        //       color: "#32325d",
        //     },
        //   },
        //   invalid: {
        //     fontFamily: "Arial, sans-serif",
        //     color: "#fa755a",
        //     iconColor: "#fa755a",
        //   },
        // };
        // var card = elements.create("card", { style: style });
        // // Stripe injects an iframe into the DOM
        // card.mount("#card-element");
        // card.on("change", function (event) {
        //   // Disable the Pay button if there are no card details in the Element
        //   document.querySelector("button")!.disabled = event.empty;
        //   document.querySelector("#card-error")!.textContent = event.error
        //     ? event.error.message
        //     : "";
        // });
        // var form = document.getElementById("payment-form");
        // form!.addEventListener("submit", function (event) {
        //   event.preventDefault();
        //   // Complete payment when the submit button is clicked
        //   payWithCard(stripe, card, data.clientSecret);
        // });

        // // Calls stripe.confirmCardPayment
        // // If the card requires authentication Stripe shows a pop-up modal to
        // // prompt the user to enter authentication details without leaving your page.
        // var payWithCard = function (stripe, card, clientSecret) {
        //   loading(true);
        //   stripe
        //     .confirmCardPayment(clientSecret, {
        //       payment_method: {
        //         card: card,
        //       },
        //     })
        //     .then(function (result) {
        //       if (result.error) {
        //         // Show error to your customer
        //         showError(result.error.message);
        //       } else {
        //         // The payment succeeded!
        //         orderComplete(result.paymentIntent.id);
        //         axios
        //           .post("/order_update", {
        //             id: id,
        //           })
        //           .then((res) => {
        //             props.history.push({
        //               pathname: "/payment_success",
        //               state: res,
        //             });
        //           });
        //       }
        //     });
        // };
        // /* ------- UI helpers ------- */
        // // Shows a success message when the payment is complete
        // var orderComplete = function (paymentIntentId) {
        //   loading(false);
        //   // document
        //   //   .querySelector(".result-message a")!
        //   //   .setAttribute(
        //   //     "href",
        //   //     "https://dashboard.stripe.com/test/payments/" + paymentIntentId
        //   //   );
        //   // document.querySelector(".result-message")!.classList.remove("hidden");
        //   document.querySelector("button")!.disabled = true;
        // };
        // // Show the customer the error from Stripe if their card fails to charge
        // var showError = function (errorMsgText) {
        //   loading(false);
        //   var errorMsg = document.querySelector("#card-error");
        //   errorMsg!.textContent = errorMsgText;
        //   setTimeout(function () {
        //     errorMsg!.textContent = "";
        //   }, 4000);
        // };
        // // Show a spinner on payment submission
        // var loading = function (isLoading) {
        //   if (isLoading) {
        //     // Disable the button and show a spinner
        //     document.querySelector("button")!.disabled = true;
        //     document.querySelector("#spinner")!.classList.remove("hidden");
        //     document.querySelector("#button-text")!.classList.add("hidden");
        //   } else {
        //     document.querySelector("button")!.disabled = false;
        //     document.querySelector("#spinner")!.classList.add("hidden");
        //     document.querySelector("#button-text")!.classList.remove("hidden");
        //   }
        // };
      });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe!.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements!.getElement(CardElement)!,
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <IonPage>
      <Header
        name={props.info.restaurant_address}
        click={() => props.history.goBack()}
      ></Header>

      <IonContent>
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <div
            className="h-btn-alws-bottom button-inner"
            style={{ position: "fixed" }}
          >
            <button
              disabled={processing || disabled || succeeded}
              id="submit"
              className="btn-border-rdu-30"
              color="primary"
            >
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </button>
          </div>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
          {/* Show a success message upon completion */}
          {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
            Payment succeeded, see the result in your
            <a href={`https://dashboard.stripe.com/test/payments`}>
              {" "}
              Stripe dashboard.
            </a>{" "}
            Refresh the page to pay again.
          </p> */}
        </form>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return {
    info: state.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCredit);
