import {
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import axios from "axios";
import { type } from "os";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import actions from "../../actions/cart";
// reactstrap components
import Header from "../../components/Shared/Header";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import cartActions from "../../actions/cart";

const OrderTime: React.FC = (props: any) => {
  const [selected, setSelected] = useState<string>("ASAP");
  const [dates, setDates] = useState<any>([]);
  const dispatch = useDispatch();
  const [hourss, setHourss] = useState<any>("");
  const [minutess, setMinutess] = useState<any>("");
  const [typess, setTypess] = useState<any>("");
  const [hours_c, setHours_c] = useState<any>("");
  const [minutes_c, setMinutes_c] = useState<any>("");
  const [types_c, setTypes_c] = useState<any>("");
  const [times, setTimes] = useState<any>([]);
  const [loaded, setLoaded] = useState<any>(false);
  const [visible, setVisible] = useState<any>(false);

  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }

  useEffect(() => {
    setSelected(props.cart.delivery_time);
  }, [props.cart]);

  const get_time = function () {
    axios
      .get("/restaurant/open_time", {
        params: {
          id: props.cart?.restaurant_id,
        },
      })
      .then((res: any) => {
        let data = res.data;
        dispatch(
          cartActions.change_time({
            open_time: data.open_time,
            close_time: data.close_time,
          })
        );
        let fmt = new Intl.DateTimeFormat("en-US", {
          month: "long",
          day: "numeric",
        });
        dispatch(cartActions.change_cooking_time(data.cooking_time));

        let open_hour = data.open_time?.split(":")[0];
        let open_minutes = data.open_time?.split(":")[1].split(" ")[0];
        let open_type = data.open_time?.split(":")[1].split(" ")[1];

        let close_hour = data.close_time?.split(":")[0];
        let close_minutes = data.close_time?.split(":")[1].split(" ")[0];
        let close_type = data.close_time?.split(":")[1].split(" ")[1];

        let date = convertTZ(new Date(), props.auth.time_zone);

        let cooking_time = data.cooking_time;
        let hours = date.getHours();
        let minutes = date.getMinutes() + cooking_time;
        let type = "AM";
        let timestamp: any[] = [];

        let hours2 = hours,
          minutes2 = minutes,
          type2 = type;

        if (minutes2 > 59) {
          hours2 = hours2 + 1;
          minutes2 = minutes2 - 60;
        }
        if (hours2 > 12 && hours2 < 24) {
          hours2 = hours2 - 12;
          type2 = "PM";
        } else if (hours2 >= 24) {
          hours2 = hours2 - 24;
          type2 = "AM";
        }

        setHours_c(hours2);
        setMinutes_c(minutes2);
        setTypes_c(type2);
        dispatch(
          actions.current_time(
            (hours2 < 10 ? "0" + hours2 : hours2) +
              ":" +
              (minutes2 < 10 ? "0" + minutes2 : minutes2) +
              " " +
              type2 +
              ", " +
              fmt.format(new Date(date))
          )
        );

        if (minutes > 45) hours++;
        if (hours > 12 && hours < 24) {
          hours = hours - 12;
          type = "PM";
        } else if (hours >= 24) {
          hours = hours - 24;
          type = "AM";
        }

        if (minutes <= 15) minutes = 15;
        else if (minutes <= 30) minutes = 30;
        else if (minutes <= 45) minutes = 45;
        else if (minutes <= 60) {
          minutes = 0;
        } else if (minutes <= 75) {
          minutes = 15;
        } else if (minutes <= 90) {
          minutes = 30;
        } else if (minutes <= 105) {
          minutes = 45;
        } else if (minutes <= 120) {
          minutes = 0;
        }

        setHourss(hours);
        setMinutess(minutes);
        setTypess(type);

        for (let i = 0; i <= 12; i++) {
          if (open_type == "AM" && i == open_hour) {
            if (close_type == "PM") {
              for (let j = 0; j < 60; j += 15) {
                if (j >= open_minutes + cooking_time)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "AM" : "PM",
                  });
              }
            } else if (i < close_hour) {
              for (let j = 0; j < 60; j += 15) {
                if (j >= open_minutes + cooking_time)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "AM" : "PM",
                  });
              }
            } else if (i == close_hour) {
              for (let j = 0; j < 60; j += 15) {
                if (j >= open_minutes + cooking_time && j < close_minutes)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "AM" : "PM",
                  });
              }
            }
          } else if (open_type == "AM" && i > open_hour) {
            if (close_type == "PM") {
              for (let j = 0; j < 60; j += 15) {
                timestamp.push({
                  hour: i < 10 ? "0" + i : i,
                  minute: j < 10 ? "0" + j : j,
                  type: i != 12 ? "AM" : "PM",
                });
              }
            } else if (i < close_hour) {
              for (let j = 0; j < 60; j += 15) {
                timestamp.push({
                  hour: i < 10 ? "0" + i : i,
                  minute: j < 10 ? "0" + j : j,
                  type: i != 12 ? "AM" : "PM",
                });
              }
            } else if (i == close_hour) {
              for (let j = 0; j < 60; j += 15) {
                if (j < close_minutes)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "AM" : "PM",
                  });
              }
            }
          }
        }

        for (let i = 1; i < 12; i++) {
          if (close_type == "PM" && i < close_hour) {
            if (open_type == "AM") {
              for (let j = 0; j < 60; j += 15) {
                timestamp.push({
                  hour: i < 10 ? "0" + i : i,
                  minute: j < 10 ? "0" + j : j,
                  type: i != 12 ? "PM" : "AM",
                });
              }
            } else if (i > open_hour) {
              for (let j = 0; j < 60; j += 15) {
                timestamp.push({
                  hour: i < 10 ? "0" + i : i,
                  minute: j < 10 ? "0" + j : j,
                  type: i != 12 ? "PM" : "AM",
                });
              }
            } else if (i == open_hour) {
              for (let j = 0; j < 60; j += 15) {
                if (j > open_minutes)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "PM" : "AM",
                  });
              }
            }
          } else if (close_type == "PM" && i == close_hour) {
            if (open_type == "AM") {
              for (let j = 0; j < 60; j += 15) {
                if (j < open_minutes)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "PM" : "AM",
                  });
              }
            } else if (i > open_hour) {
              for (let j = 0; j < 60; j += 15) {
                if (j < close_minutes)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "PM" : "AM",
                  });
              }
            } else if (i == open_hour) {
              for (let j = 0; j < 60; j += 15) {
                if (j >= open_minutes && j < close_minutes)
                  timestamp.push({
                    hour: i < 10 ? "0" + i : i,
                    minute: j < 10 ? "0" + j : j,
                    type: i != 12 ? "PM" : "AM",
                  });
              }
            }
          }
        }

        setTimes(timestamp);

        // now use fmt.format(dateobject) as many times as you wish
        if (dates.length == 0)
          setDates([
            fmt.format(new Date(date)),
            fmt.format(new Date(date.getTime() + 3600 * 1000 * 24)),
            fmt.format(new Date(date.getTime() + 3600 * 1000 * 48)),
          ]);

        setLoaded(true);
        setTimeout(() => {
          setVisible(true);
        }, 100);
      });
  };

  useEffect(() => {
    get_time();
    let intervalId = setInterval(() => {
      get_time();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [props.auth]);

  useEffect(() => {}, []);

  return (
    <IonPage>
      <Header
        name={
          props.info.type == "Delivery To"
            ? "Start Delivery Time"
            : "Pickup Time"
        }
        click={() => props.history.goBack()}
      ></Header>
      <IonContent>
        {/* <div style={{ padding: "20px 15px 10px 15px" }}>
          Current time:{" "}
          {hourss + ":" + minutess + " " + typess + ", " + dates[0]}
          {" (" + props.auth.time_zone + ")"}
          <div>Cooking time: {props.cart.cooking_time + " Minutes"}</div>
        </div> */}
        <br></br>
        {loaded ? (
          <div
            className="h-flex"
            style={visible ? { display: "flex" } : { display: "none" }}
          >
            <div className="col-12">
              <div
                className="h-form-ordertime h-bgr-fff"
                style={{ overflow: "hidden" }}
              >
                <IonRadioGroup
                  value={selected}
                  onIonChange={(e) => setSelected(e.detail.value)}
                  class="oder-time"
                >
                  <div className="h-flex pt-10 pb-10  h-line-e3e3e3">
                    <IonItem>
                      <IonLabel>
                        {(hours_c < 10 ? "0" + hours_c : hours_c) +
                          ":" +
                          (minutes_c < 10 ? "0" + minutes_c : minutes_c) +
                          " " +
                          typess +
                          ", " +
                          dates[0]}
                      </IonLabel>
                      <IonRadio slot="end" value="ASAP" />
                    </IonItem>
                  </div>
                  {times.map((v, i) => (
                    <div
                      className="h-flex pt-10 pb-10  h-line-e3e3e3"
                      key={i}
                      style={
                        typess == "AM"
                          ? v.type == "PM"
                            ? v.hour == hourss && v.minute < minutess
                              ? { display: "none" }
                              : {}
                            : v.hour < hourss
                            ? { display: "none" }
                            : v.hour == hourss && v.minute < minutess
                            ? { display: "none" }
                            : {}
                          : v.type == "AM"
                          ? { display: "none" }
                          : v.hour < hourss
                          ? { display: "none" }
                          : hourss >= 1 && v.hour == 12
                          ? { display: "none" }
                          : v.minute < minutess
                          ? { display: "none" }
                          : {}
                      }
                    >
                      <IonItem>
                        <IonLabel>
                          {v.hour +
                            ":" +
                            v.minute +
                            " " +
                            v.type +
                            ", " +
                            dates[0]}
                        </IonLabel>
                        <IonRadio
                          slot="end"
                          value={
                            v.hour +
                            ":" +
                            v.minute +
                            " " +
                            v.type +
                            ", " +
                            dates[0]
                          }
                        />
                      </IonItem>
                    </div>
                  ))}

                  {times.map((v, i) => (
                    <div className="h-flex pt-10 pb-10  h-line-e3e3e3" key={i}>
                      <IonItem>
                        <IonLabel>
                          {v.hour +
                            ":" +
                            v.minute +
                            " " +
                            v.type +
                            ", " +
                            dates[1]}
                        </IonLabel>
                        <IonRadio
                          slot="end"
                          value={
                            v.hour +
                            ":" +
                            v.minute +
                            " " +
                            v.type +
                            ", " +
                            dates[1]
                          }
                        />
                      </IonItem>
                    </div>
                  ))}

                  {times.map((v, i) => (
                    <div
                      className="h-flex pt-10 pb-10  h-line-e3e3e3"
                      key={i}
                      style={
                        typess == "AM"
                          ? v.type == "PM"
                            ? { display: "none" }
                            : v.hour < hourss
                            ? {}
                            : v.hour == hourss && v.minute <= minutess
                            ? {}
                            : { display: "none" }
                          : v.type == "AM"
                          ? {}
                          : v.hour < hourss
                          ? {}
                          : hourss >= 1 && v.hour == 12
                          ? {}
                          : v.minute < minutess
                          ? {}
                          : { display: "none" }
                      }
                    >
                      <IonItem>
                        <IonLabel>
                          {v.hour +
                            ":" +
                            v.minute +
                            " " +
                            v.type +
                            ", " +
                            dates[2]}
                        </IonLabel>
                        <IonRadio
                          slot="end"
                          value={
                            v.hour +
                            ":" +
                            v.minute +
                            " " +
                            v.type +
                            ", " +
                            dates[2]
                          }
                        />
                      </IonItem>
                    </div>
                  ))}
                </IonRadioGroup>
              </div>
            </div>
          </div>
        ) : (
          [...Array(3)].map((e, i) => <GeneralSkeletonText key={i} />)
        )}
      </IonContent>
      <div className="h-btn-alws-bottom button-inner">
        <button
          type="button"
          className="btn-border-rdu-30"
          color="primary"
          onClick={() => {
            dispatch(actions.change_delivery_time(selected));
            props.history.goBack();
          }}
        >
          SAVE
        </button>
      </div>
    </IonPage>
  );
};
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.auth,
    info: state.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTime);
