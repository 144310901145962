import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { alertActions } from "../../actions/alert";
import Header from "../../components/Shared/Header";
import axios from "../../helpers/axiosInterceptor";

const RewardDetail: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [point, setPoint] = useState(0);
  const [src, setSrc] = useState(0);

  useEffect(() => {
    setName(props.history?.location?.state?.name);
    setPoint(props.history?.location?.state?.points);
    setSrc(props.history?.location?.state?.src);
    return () => {};
  }, []);

  const reemReward = () => {
    dispatch(alertActions.page_loader(true));
    axios
      .post("/reward/redeem", {
        reward_id: props.history?.location?.state?.id,
      })
      .then((res) => {
        props.history.push({ pathname: "/dashboard/rewards", tab: "1" });
      })
      .catch((e) => {
        dispatch(alertActions.error("There are errors, please try again!"));
        //dispatch(alertActions.page_loader(false));
      });
  };

  return (
    <IonPage>
      <Header
        name="Reward Details"
        click={() => props.history.goBack()}
        back={true}
      ></Header>
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundImage: "url(" + src + ")",
              height: 220,
              width: 220,
              borderRadius: 110,
              margin: "20% 0 20px",
              backgroundSize: "cover",
              backgroundRepeat: "none",
              backgroundPosition: "center",
            }}
          ></div>
          <div style={{ fontSize: 31, color: "#030303", fontWeight: "bold" }}>
            {name}
          </div>
          <div style={{ fontSize: 25, color: "#045ADE", fontWeight: "bold" }}>
            {point ? point + " POINTS" : null}{" "}
          </div>
          <div
            style={{
              maxWidth: 220,
              textAlign: "center",
              fontSize: 17,
              color: "#333333",
              marginTop: 15,
            }}
          >
            You can redeem this reward to get 1 {name} for free on your next
            order.
          </div>
        </div>

        <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
          <div className="h-btn-alws-bottom button-inner">
            <button
              type="submit"
              className="btn-border-rdu-30"
              color="primary"
              onClick={() => reemReward()}
            >
              REDEEM
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    restaurant_id: state.info.restaurant_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardDetail);
