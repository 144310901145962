import {
  IonContent,
  IonLoading,
  IonPage,
  IonToast,
  useIonRouter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { Container, Form } from "reactstrap";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/auth";
import Input from "../../components/Shared/Input";
import axios from "../../helpers/axiosInterceptor";
import { authConstants } from "../../helpers/constants";
import styled from "styled-components";

const Verify: React.FC = (props: any) => {
  const [code, setCode] = useState("");
  const [showToast, setShowToast] = useState(false);
  const router = useIonRouter();
  const auth = useSelector((state: RootStateOrAny) => state.auth);
  const errorMsg = useSelector((state: RootStateOrAny) => state.auth.errorMsg);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    const phone = auth.user.phone;
    const email = auth.user.email;

    dispatch(actions.verify({ code, phone, email }, props.history));
  };

  const resendOTP = async (e) => {
    try {
      const email = auth.user.email;
      dispatch(alertActions.page_loader(true));
      await axios.post("/resend_otp", { email: email });
      dispatch(alertActions.page_loader(false));
    } catch (e) {
      dispatch(alertActions.page_loader(false));
    }
  };
  useEffect(() => {
    props.state.errorMsg &&
      props.history?.location?.pathname == "/auth/verify" &&
      setShowToast(true);
  }, [props.state.errorMsg]);

  return (
    <IonPage>
      <IonContent className="bg-secondary">
        <Header>
          <img
            src={require("../../assets/img/icons/common/back-blue.svg").default}
            alt=""
            style={{ height: 60 }}
            onClick={() => router.push("/auth/register", "back", "push")}
          />
        </Header>
        <Container>
          <div
            className="text-center text-muted mb-5 px-5"
            style={{ marginTop: "20%" }}
          >
            <img
              src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
              alt="Chicko Chicken"
              onClick={() => props.history.push("/choose_restaurant")}
            />
          </div>
          <Form role="form" onSubmit={onSubmit}>
            <div className="text-center text-muted mb-4">
              <p className="h6" style={{ color: "#09070B" }}>
                We have sent a verification code to{" "}
                <span style={{ fontWeight: "bold" }}>
                  {auth.user && auth.user.email}
                </span>
              </p>
            </div>
            <Input
              placeholder="Enter Verification Code"
              type="number"
              name="code"
              onChange={(e) => setCode(e.target.value)}
              required
              icon={require("../../assets/img/icons/common/mail.svg").default}
            ></Input>

            {/* {props.state?.errorMsg && (
							<UncontrolledAlert color="warning">
								<strong>{props.state.errorMsg}</strong>
							</UncontrolledAlert>
						)} */}

            <div className="text-center">
              <button
                type="submit"
                style={{
                  backgroundColor: "#0058df",
                  color: "#fff",
                  width: "100%",
                  borderRadius: 25,
                  height: 48,
                  fontSize: 18,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                VERIFY
              </button>
            </div>
          </Form>

          <div className="text-center text-muted mt-4">
            <span style={{ color: "#707070", fontSize: 15 }}>
              Didn't received the code?
              <span
                className="ml-2"
                style={{ color: "#0058df" }}
                onClick={resendOTP}
              >
                Resend
              </span>
            </span>
          </div>
        </Container>
        {/* <Row className="justify-content-center">
					<Col lg="5">
						<div style={{ backgroundColor: "transparent" }}>
							<CardImg top width="65%" src={require('../../assets/img/intro/logo-chicko-chicken.png')} alt="Card image cap" style={{ margin: "auto", marginBottom: "10vh", width: "65%", marginLeft: "calc((100vw - 267px)/2)" }} onClick={() => props.history.push("/dashboard/home")} />
							<CardBody className="px-lg-5 py-lg-5 card-body mt-n5 rounded mx-3 shadow bg-secondary" style={{ backgroundColor: "#fff" }}>
								<div className="text-center text-muted mb-4">
									<p className="h4">Verify your Email</p>
									<p className="h6">We have sent you verification code to your ({auth.user && auth.user.email})</p>
								</div>
								{errorMsg && (
									<Alert color="warning">
										<strong>{errorMsg}</strong>
									</Alert>
								)}
								<Form role="form" onSubmit={onSubmit}>
									<FormGroup className="mb-3">
										<InputGroup className="input-group-alternative">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-lock-circle-open" />
												</InputGroupText>
											</InputGroupAddon>
											<Input placeholder="Verification Code" type="number" name="code" onChange={e => setCode(e.target.value)} required />
										</InputGroup>
									</FormGroup>
									<div className="text-center">
										<button
											type="submit"
											style={{
												backgroundColor: "#0058df",
												color: "#fff",
												width: "100%",
												borderRadius: 25,
												height: 48,
												fontSize: 18,
												fontWeight: "lighter",
											}}
										>
											VERIFY
              </button>
									</div>
								</Form>

								<div className="text-center text-muted my-4">
									<span>
										Didn't received the code? <span className="text-warning ml-2" onClick={resendOTP}>Resend</span>
									</span>
								</div>
							</CardBody>
						</div>
					</Col>
				</Row> */}
        <IonLoading isOpen={auth.request} message={"Please wait..."} />
        <IonToast
          isOpen={
            showToast && props.history?.location?.pathname == "/auth/verify"
          }
          onDidDismiss={() => {
            dispatch({ type: authConstants.LOGIN_FAILURE });
            setShowToast(false);
          }}
          message={props.state.errorMsg}
          duration={3500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 15px 10px;
`;
const mapStateToProps = (state) => {
  return { state: state.auth };
};

export default connect(mapStateToProps, actions)(Verify);
