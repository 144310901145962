import {
  IonContent,
  IonLabel,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import Header from "../../components/Shared/Header";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import axios from "../../helpers/axiosInterceptor";

const OrderDetail: React.FC = (props: any) => {
  const [order, setOrder] = useState<any>({});
  const [load, setLoad] = useState(false);
  const currency_symbol = useSelector(
    (state: RootStateOrAny) => state.auth.currency_symbol
  );

  useIonViewDidEnter(() => {
    axios
      .get(`/order`, {
        params: {
          order_id: props.match.params.id,
        },
      })
      .then((res) => {
        const data = res.data;
        setOrder(data);
        setLoad(true);

        // setTimeout(() => {
        //   setLoad(false)
        // }, 30 * 1000)
      })
      .catch((err) => {
        console.log(err);
      });
  });

  useEffect(() => {
    if (!load) {
      axios
        .get(`/order`, {
          params: {
            order_id: props.match.params.id,
          },
        })
        .then((res) => {
          const data = res.data;
          setOrder(data);

          setLoad(true);

          // setTimeout(() => {
          //   setLoad(false)
          // }, 30 * 1000)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [load]);

  return (
    <IonPage>
      {/* <IonHeader className="bg-white ion-no-border border-bottom">
          <IonToolbar color="white">
            <IonButtons slot="primary">
              <IonButton
                onClick={() => setLoad(false)}
                className="position-relative"
              >
                <IonIcon icon={refreshOutline} className="text-muted" /> */}
      {/* <span className="hint danger position-absolute"></span> */}
      {/* </IonButton>
            </IonButtons>
            <IonTitle className="font-weight-bold text-dark">My Orders</IonTitle>
          </IonToolbar>
        </IonHeader> */}
      <Header
        name="Order Details"
        click={() => props.history.goBack()}
        back={true}
      ></Header>
      <IonContent fullscreen>
        {/* <h5 className="font-weight-bold">Ongoing Orders</h5> */}
        <Row className="h-100">
          <Col>
            {load ? (
              <>
                <div className="h-flex pt-20" style={{ marginBottom: 20 }}>
                  <div className="col-12">
                    <div className="h-form-ordertime h-bgr-fff">
                      <div
                        className="h-flex h-between h-payment__header a-center"
                        style={{ padding: "13px 15px 10px 15px" }}
                      >
                        <div className="h-flex a-center">
                          <div className="h-okelelele h-pr-10">
                            <IonLabel
                              className="h-bold h-000E90"
                              style={{ fontSize: 16 }}
                            >
                              {order.unique_id}
                            </IonLabel>
                          </div>
                          <span className="h-date ">
                            {" "}
                            {order.created_at_restaurant}
                          </span>
                        </div>
                        <div className="h-flex j-end">
                          <button
                            className={
                              "h-button_payment_header " +
                              (order.status == "ORDERED"
                                ? "btn-order"
                                : order.status == "CONFIRMED"
                                ? "btn-confirm"
                                : order.status == "COMPLETED"
                                ? "btn-complete"
                                : "btn-cancel")
                            }
                            style={{ fontSize: 12 }}
                          >
                            {order.status}
                          </button>
                        </div>
                      </div>
                      <div className="h-pb-20">
                        {order.type == 1 ? (
                          <>
                            <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                              <div className="h-col-4">
                                <label className="h-font-15">Pickup At</label>
                              </div>
                              <div
                                className="h-col-8 h-flex j-end"
                                style={{ flexDirection: "column" }}
                              >
                                <div style={{ textAlign: "right" }}>
                                  {order.res.name}
                                </div>
                                <div
                                  style={{
                                    textAlign: "right",
                                    color: "#989898",
                                  }}
                                >
                                  {order.res.addresses[0].street}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                              <div className="h-col-4">
                                <label className="h-font-15">
                                  Delivery From
                                </label>
                              </div>
                              <div
                                className="h-col-8 h-flex j-end"
                                style={{ flexDirection: "column" }}
                              >
                                <div
                                  style={{
                                    textAlign: "right",
                                    fontSize: "15px",
                                  }}
                                >
                                  {order.res.name}
                                </div>
                                <div
                                  style={{
                                    textAlign: "right",
                                    color: "#989898",
                                    fontSize: "15px",
                                  }}
                                >
                                  {order.res.addresses[0].street}
                                </div>
                              </div>
                            </div>
                            <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                              <div className="h-col-4">
                                <label className="h-font-15">Delivery To</label>
                              </div>
                              <div className="h-col-8 h-flex j-end">
                                <div
                                  style={{ textAlign: "right", fontSize: 15 }}
                                >
                                  {order.delivery_to}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                          <div className="h-col-4">
                            <label className="h-font-15">
                              {order.type == 1
                                ? "Pickup Time"
                                : "Start Delivery"}
                            </label>
                          </div>
                          <div className="h-col-8 h-flex j-end">
                            <label htmlFor="">
                              {order.delivery_time}{" "}
                              {order.status == "CONFIRMED"
                                ? " (" + order.res.cooking_time + " mins)"
                                : ""}
                            </label>
                          </div>
                        </div>
                        <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                          <div className="h-col-10">
                            <label className="h-font-15">Order Type</label>
                          </div>
                          <div className="h-col-2 h-flex j-end">
                            <label htmlFor="" className="h-font-15">
                              {order.type == 1 ? "Pickup" : "Delivery"}
                            </label>
                          </div>
                        </div>
                        <div className="h-flex h-between pt-10 h-pb-10 a-center h-mlr-15 h-line__bottom">
                          <div className="h-col-6">
                            <label className="h-font-15">Payment Method</label>
                          </div>
                          <div className="h-col-6 h-flex j-end">
                            <label htmlFor="" className="h-font-15">
                              {order.payment_mode == "COD"
                                ? "Cash"
                                : "Credit/Debit Card"}
                            </label>
                          </div>
                        </div>

                        <div className="h-flex h-between pt-10 h-pb-10 a-center  h-mlr-15">
                          <div className="h-col-5">
                            <label className="h-font-15">
                              Special Requests
                            </label>
                          </div>
                          <div className="h-col-7 h-flex j-end">
                            <label htmlFor="" style={{ textAlign: "right" }}>
                              {order.request ? order.request : "No request"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-flex pt-20" style={{ marginBottom: 40 }}>
                  <div className="col-12">
                    <div className="h-form-ordertime h-bgr-fff">
                      <div className="h-flex h-payment__header a-center">
                        <label
                          htmlFor=""
                          className="h-bold h-000E90"
                          style={{ padding: "0 15px" }}
                        >
                          Items
                        </label>
                      </div>
                      {order.order_dishes?.map((val, idx) => {
                        const {
                          name,
                          id,
                          price,
                          quantity,
                          is_veg,
                          order_adddons,
                          description,
                        } = val;

                        return (
                          <div
                            className="h-flex h-between pt-10 h-mlr-15 "
                            key={idx}
                          >
                            <div className="h-col-10 h-flex h-flex__nowrap">
                              {/* <div className="h-body-img-product-payment1"></div> */}
                              <div className="h-product-payment__text">
                                <label style={{ width: "100%" }}>
                                  <span
                                    style={{ fontWeight: "bold", fontSize: 16 }}
                                  >
                                    {quantity}
                                  </span>{" "}
                                  x{" "}
                                  <label
                                    className="h-bold"
                                    style={{ fontSize: 16 }}
                                  >
                                    {name}
                                  </label>
                                </label>
                                <label
                                  className="h-text-small"
                                  style={{ fontSize: 15 }}
                                >
                                  {order_adddons.map((d, i) => (
                                    <span
                                      className="font-weight-light item-name sub-info"
                                      key={i}
                                    >
                                      {d.name +
                                        (i == order_adddons?.length - 1
                                          ? ""
                                          : ", ")}
                                    </span>
                                  ))}
                                </label>
                              </div>
                            </div>
                            <div className="h-col-2 h-pt-5 h-flex j-end">
                              <label className="h-font-16">
                                ${(price * quantity).toFixed(2)}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                      {order.order_reward?.map((val, idx) => {
                        const {
                          name,
                          id,
                          price,
                          quantity,
                          is_veg,
                          order_adddons,
                          description,
                          reward,
                        } = val;
                        return (
                          <div
                            className="h-flex h-between pt-10 h-mlr-15"
                            key={idx}
                          >
                            <div className="h-col-10 h-flex h-flex__nowrap">
                              {/* <div className="h-body-img-product-payment1"></div> */}
                              <div className="h-product-payment__text">
                                <label>
                                  <span
                                    style={{ fontWeight: "bold", fontSize: 16 }}
                                  >
                                    {1}
                                  </span>{" "}
                                  x{" "}
                                  <label
                                    className="h-bold"
                                    style={{ fontSize: 16 }}
                                  >
                                    {reward.name}
                                  </label>
                                </label>
                                <div
                                  className="h-text-small"
                                  style={{ fontSize: 15 }}
                                >
                                  {reward.description}
                                </div>
                              </div>
                            </div>
                            <div className="h-col-2 h-pt-5 h-flex j-end">
                              <label className="h-font-16">
                                ${reward.price * 1}
                              </label>
                            </div>
                          </div>
                        );
                      })}

                      <div className="h-flex h-between h-pb-10 a-center h-line__bottom h-line__top pt-10 h-mt-10  h-mlr-15">
                        <div className="h-col-10">
                          <label className="h-font-15">Subtotal</label>
                        </div>
                        <div className="h-col-2 h-flex j-end">
                          <label className="h-font-16" htmlFor="">
                            $
                            {order.order_dishes?.reduce((total, value) => {
                              return (
                                total + value.quantity * Number(value.price)
                              );
                            }, 0).toFixed(2)}
                          </label>
                        </div>
                      </div>

                      {/* <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                        <div className="h-col-10">
                          <label className="h-font-15">GST Tax</label>
                        </div>
                        <div className="h-col-2 h-flex j-end">
                          <label className="h-font-16" htmlFor="">
                            ${order.gst_tax}
                          </label>
                        </div>
                      </div>
                      <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                        <div className="h-col-10">
                          <label className="h-font-15">PST Tax</label>
                        </div>
                        <div className="h-col-2 h-flex j-end">
                          <label className="h-font-16" htmlFor="">
                            ${order.pst_tax}
                          </label>
                        </div>
                      </div>
                      <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                        <div className="h-col-10">
                          <label className="h-font-15">HST Tax</label>
                        </div>
                        <div className="h-col-2 h-flex j-end">
                          <label className="h-font-16" htmlFor="">
                            ${order.hst_tax}
                          </label>
                        </div>
                      </div> */}
                      <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                        <div className="h-col-10">
                          <label className="h-font-15">Total Tax</label>
                        </div>
                        <div className="h-col-2 h-flex j-end">
                          <label className="h-font-16" htmlFor="">
                            ${order.tax}
                          </label>
                        </div>
                      </div>
                      {order.tip != 0 ? (
                        <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                          <div className="h-col-10">
                            <label className="h-font-15">Tip</label>
                          </div>
                          <div className="h-col-2 h-flex j-end">
                            <label className="h-font-16" htmlFor="">
                              ${order.tip}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                        <div className="h-col-10">
                          <label className="h-font-15">Delivery Fee</label>
                        </div>
                        <div className="h-col-2 h-flex j-end">
                          <label className="h-font-16" htmlFor="">
                            ${parseInt(order.delivery_charge)}
                          </label>
                        </div>
                      </div> */}
                      {order.coupon_discount != 0 ? (
                        <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                          <div className="h-col-10">
                            <label className="h-font-15">Coupon Discount</label>
                          </div>
                          <div className="h-col-2 h-flex j-end">
                            <label className="h-font-16" htmlFor="">
                              -${order.coupon_discount}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {order.restaurant_charges != 0 ? (
                        <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                          <div className="h-col-10">
                            <label className="h-font-15">
                              Restaurant Charge
                            </label>
                          </div>
                          <div className="h-col-2 h-flex j-end">
                            <label className="h-font-16" htmlFor="">
                              ${order.restaurant_charges}
                            </label>
                          </div>
                        </div>
                      ) : null}

                      <div className="h-flex h-between pt-10 h-pb-10 a-center h-line__bottom h-mlr-15">
                        <div className="h-col-9">
                          <label className="h-font-15">Points Earned</label>
                        </div>
                        <div className="h-col-3 h-flex j-end">
                          <label className="h-font-16" htmlFor="">
                            {order.rewards} Points
                          </label>
                        </div>
                      </div>
                      <div className="h-flex h-between pt-10 h-pb-30 a-center h-mlr-15">
                        <div className="h-col-10">
                          <label className="h-bold h-font-17">Total</label>
                        </div>
                        <div className="h-col-2 h-flex j-end">
                          <label className="h-bold h-font-17">
                            {currency_symbol + order.total}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              [...Array(5)].map((e, i) => (
                <Container className="mt-3 p-0" key={i}>
                  <GeneralSkeletonText />
                </Container>
              ))
            )}
          </Col>
        </Row>
      </IonContent>
    </IonPage>
  );
};
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
