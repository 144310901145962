import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import actions from "../../actions/cart";
import PaypalCheckout from "./PaypalCheckout";
import RazorpayCheckout from "./RazorpayCheckout";
import axios from "../../helpers/axiosInterceptor";
import GeneralSkeletonText from "../skeleton_text/general_restaurant";
import { alertActions } from "../../actions/alert";
import { IonAlert, isPlatform } from "@ionic/react";

interface ListProps {
  history: any;
}
const PaymentMethods: React.FC<ListProps> = (props: any) => {
  const [paymentGateway, setPaymentGateway] = useState({} as any);
  const [loaded, setLoaded] = useState(false);
  const cart = useSelector((state: RootStateOrAny) => state.cart);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/settings/payment_options")
      .then((res) => {
        const payment_options = res.data;
        setPaymentGateway(payment_options);
        setLoaded(true);
      })
      .catch((e) => {
        dispatch(alertActions.page_loader(false));
        dispatch(alertActions.error("There are errors, please try again!"));
      });
  }, []);

  const onCODPurchase = (e) => {
    let cartForm = cart;
    cartForm.method = "COD";
    cartForm.platform = isPlatform("ios") ? "ios" : "android";
    if (props.cart.delivery_time == "ASAP" || props.cart.delivery_time == "") {
      cartForm.is_asap = true;
    } else cartForm.is_asap = false;
    cartForm.delivery_time =
      props.cart.delivery_time != "ASAP" && props.cart.delivery_time != ""
        ? props.cart.delivery_time
        : props.cart.current_time;
    cartForm.type = props.info.type == "Delivery To" ? 0 : 1;
    dispatch(actions.place_order(cartForm, props.history, "COD"));
  };

  const onCreditCard = () => {
    dispatch(alertActions.page_loader(true));
    let cartForm = cart;
    cartForm.method = "CARD";
    cartForm.platform = isPlatform("ios") ? "ios" : "android";
    if (props.cart.delivery_time == "ASAP" || props.cart.delivery_time == "") {
      cartForm.is_asap = true;
    } else cartForm.is_asap = false;
    cartForm.delivery_time =
      props.cart.delivery_time != "ASAP" && props.cart.delivery_time != ""
        ? props.cart.delivery_time
        : props.cart.current_time;

    cartForm.type = props.info.type == "Delivery To" ? 0 : 1;
    cartForm.stripe_id = props.auth.user.stripe_id;
    cartForm.card_id = props.auth.default_card.card_id;
    axios
      .post("/stripe/user_payment", cartForm)
      .then((data: any) => {
        if (data?.status == "fail") {
          if (cartForm.is_asap) cartForm.delivery_time = "ASAP";
          dispatch(alertActions.page_loader(false));
          dispatch(alertActions.error(data?.message));
        } else {
          cartForm.trans_id = data.id;
          cartForm.client_id = data.client_secret;
          dispatch(actions.place_order(cartForm, props.history, "CC"));
        }
      })
      .catch((e) => {
        if (cartForm.is_asap) cartForm.delivery_time = "ASAP";
        dispatch(alertActions.page_loader(false));
        dispatch(alertActions.error(e.data.message));
      });
  };

  return (
    <>
      {loaded ? (
        <>
          {/* {paymentGateway.pg_paypal_active === "1" && <PaypalCheckout history={history} client_id={paymentGateway.pg_paypal_key} currency_code={paymentGateway.currency_code} />}
                        {<CloverCheckout history={history} client_id={paymentGateway.pg_razorpay_client_key} currency_code={paymentGateway.currency_code} />}
                        {paymentGateway.pg_razorpay_active === "1" && <RazorpayCheckout history={history} client_id={paymentGateway.pg_razorpay_client_key} currency_code={paymentGateway.currency_code} />} */}

          {props.cart.total > 0 ? (
            <div
              className="col-12 pb-15"
              onClick={() => {
                if (props.auth.default_card?.card_number) setShowAlert(true);
                else setShowAlert2(true);
              }}
            >
              <div className="h-select__payment-method">
                <div className="h-flex">
                  <div className="h-flex a-center h-pd-30">
                    <img
                      src={require("../../assets/img/icons/common/payment_method_icon.svg").default}
                    />
                  </div>
                  <div className="h-flex h-flex-column j-center">
                    <label className="h-bold h-font-16">Payment Method</label>
                    <label className="h-pt-5"> Credit/Debit Card</label>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* {paymentGateway.pg_cod_active === "1" && (
            <div className="col-12 " onClick={onCODPurchase}>
              <div className="h-select__payment-method">
                <div className="h-flex">
                  <div className="h-flex a-center h-pd-30">
                    <img
                      src={require("../../assets/img/icons/common/cod_icon.svg")}
                    />
                  </div>
                  <div className="h-flex h-flex-column j-center">
                    <label className="h-bold h-font-16">COD</label>
                    <label className="h-pt-5">Cash on Delivery</label>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </>
      ) : (
        <Row
          className="my-0"
          style={{ width: "100%", marginLeft: 0, marginRight: 0 }}
        >
          <Col>
            {[...Array(2)].map((e, i) => (
              <GeneralSkeletonText key={i} />
            ))}
          </Col>
        </Row>
      )}
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="ht-alert"
        header={"Confirm"}
        //subHeader={'Information'}
        message={
          "Are you sure you want to pay <strong>$" +
          cart.total +
          "</strong> with this <strong>* " +
          props.auth?.default_card?.card_number +
          "</strong> card?"
        }
        buttons={[
          {
            text: "Cancel",
          },
          {
            text: "OK",
            handler: () => {
              onCreditCard();
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showAlert2}
        onDidDismiss={() => setShowAlert2(false)}
        cssClass="ht-alert"
        header={"Confirm"}
        //subHeader={'Information'}
        message={"Please add a credit card!"}
        buttons={[
          {
            text: "OK",
            handler: () => {
              props.history.push("/credit_card/add", {
                from: "gift_cards_checkout",
              });
            },
          },
        ]}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    info: state.info,
    cart: state.cart,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
