import React from "react";
import { IonHeader, IonTitle, IonToolbar, isPlatform } from "@ionic/react";

interface RouteProps {
  name?: string;
  click?: any;
  back?: boolean;
}

const Header: React.FC<RouteProps> = ({ name, click, back = true }) => {
  return (
    <IonHeader>
      <IonToolbar
        className={
          isPlatform("ios")
            ? "status-bar-gradient"
            : "status-bar-gradient not-ios"
        }
      >
        <IonTitle
          class="h-center-text-bold h-color-black"
          size="large"
          style={
            back
              ? {
                  fontSize: 18,
                  padding: "0 50px",
                  display: "flex",
                  alignItems: "center",
                }
              : {
                  fontSize: 18,
                  padding: "0 20px",
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          {name}
        </IonTitle>
        {back ? (
          <img
            src={require("../../assets/img/icons/common/back-arrow.svg").default}
            onClick={() => click()}
          />
        ) : null}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
