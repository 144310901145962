import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import SavedAddress from "../../components/Addresses/saved-address-items";
import Header from "../../components/Shared/Header";

const ManageAddress: React.FC = (props: any) => {
  return (
    <IonPage>
      <Header
        name="Manange Address"
        click={() => props.history.push("/choose_restaurant")}
      ></Header>

      <IonContent>
        <Container className="my-3 pb-6">
          <div className="font-weight-bold" style={{ fontSize: 22 }}>
            Address List
          </div>
          <small style={{ fontSize: 16, color: "#565656" }}>
            Select your delivery address
          </small>
          <Row>
            <Col lg="5">
              <SavedAddress history={props.history} />
            </Col>
          </Row>
        </Container>
        {/* <Button className="position-fixed fixed-bottom w-100 bg-success p-3 m-0 text-white" href="/add-address">Add new Address</Button> */}
      </IonContent>
    </IonPage>
  );
};

export default ManageAddress;
