import { Plugins } from "@capacitor/core";
import {
  IonBadge,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/cart";
import infoActions from "../../actions/info";
import Background from "../../assets/img/intro/background.png";
import Container from "../../components/Shared/Container";
import FoodCategory from "../../components/Shared/FoodCategory";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";
import axios from "../../helpers/axiosInterceptor";

const { Geolocation } = Plugins;

const Menu: React.FC = (props: any) => {
  const [address, setAddress] = useState("Loading your address...");
  const [restaurants, setRestaurants] = useState<any>([]);
  const [hasNotification, setHasNotification] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(actions.addons_hide());
    }, 500);

    return () => {};
  }, []);

  useEffect(() => {
    getRestaurant();
  }, [props.cart.changed]);

  useIonViewDidEnter(() => {
    getRestaurant();
  }, [props.restaurantID]);

  useEffect(() => {
    setLoaded(false);
  }, [props.restaurantID]);

  useEffect(() => {
    setAddress(props.addressState);
  }, [props.addressState]);

  const getRestaurant = () => {
    const storage = JSON.parse(localStorage.getItem("persist:storage") as any);
    if (storage) {
      // auth = storage.auth
      // auth_token = JSON.parse(auth).auth_token
      var info = JSON.parse(storage.info);
      var restaurant_id = info.restaurant_id;
    }
    axios
      .get("/categories")
      .then((res) => {
        dispatch(infoActions.set_categories(res));
        setRestaurants(res);
      })
      .catch((err) => {});
    restaurant_id &&
      axios
        .get(`/restaurant/${restaurant_id}`)
        .then((res) => {
          dispatch(infoActions.set_restaurant(res.data));
          dispatch(alertActions.page_loading(false));
          dispatch(alertActions.page_loader(false));
          setLoaded(true);
        })
        .catch((err) => {
          dispatch(alertActions.page_loader(false));
          dispatch(alertActions.page_loading(false));
          props.history.goBack();
          dispatch(alertActions.error("There are errors, please try again!"));
          console.log(err);
        });
  };

  const getDishState = (store) => store.cart.dishes;

  const getAllDishes = (store) => {
    const dishes = getDishState(store) ?? [];
    let total = 0,
      addons_cost = 0,
      count = 0;

    dishes.map((dish) => {
      count += dish.count;
      dish.addons_dish.map((d) => {
        d.addons.map((a) => (addons_cost += parseInt(a.price)));
      });
      return (total += dish.count * dish.price + addons_cost);
    });
    return { count, total };
  };

  const basket = useSelector((state: RootStateOrAny) => getAllDishes(state));

  let restaurantElement = restaurants.map((val: any, idx) => (
    <FoodCategory
      match={props.match}
      history={props.history}
      key={idx}
      src={val.image}
      name={val.name}
      description={val.description}
      food={val}
    ></FoodCategory>
  ));

  return (
    <IonPage style={{ zIndex: 1 }}>
      <>
        <TopHeader background={`${Background}`}>
          <div
            style={{ width: "calc(100% - 25px)", paddingLeft: "20px" }}
            className="ht-ios status-bar-gradient"
          >
            <Link to="/choose_restaurant" className="order-type">
              {props.typeOrder}
            </Link>
            <Link to="/choose_restaurant">
              <Ellipsis>
                {props.typeOrder == "Pickup At"
                  ? props.restaurantAddress
                  : props.addressState}
              </Ellipsis>
            </Link>
          </div>

          <div
            className="ht-ios status-bar-gradient"
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: "20px",
            }}
            onClick={() => {
              setHasNotification(false);
              props.history.push("/cart");
            }}
          >
            <div style={{ position: "relative" }}>
              <img
                src={require("../../assets/img/icons/common/cart-small.svg").default}
                alt=""
                style={{ height: 21 }}
              />
              <IonBadge color="danger" slot="start">
                {basket.count}
              </IonBadge>
            </div>
          </div>
        </TopHeader>
        <IonContent>
          <Container>
            <Banner
              className="banner"
              background={require("../../assets/img/intro/hero_image.jpg").default}
            ></Banner>
            {loaded ? (
              restaurantElement
            ) : (
              <>
                <GeneralSkeletonText></GeneralSkeletonText>
                <GeneralSkeletonText></GeneralSkeletonText>
                <GeneralSkeletonText></GeneralSkeletonText>
              </>
            )}
          </Container>
        </IonContent>
      </>
    </IonPage>
  );
};

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: bold;
  color: #222222;
  height: 24px;
`;
const Banner = styled.div`
  background-image: url("${(props) => props.background}");
  height: calc((100vw - 40px) * 0.8);
  background-size: 100% 100%;
  border-radius: 20px;
  margin: 0px 0px 20px;
`;
const TopHeader = styled.div`
  display: flex;
  align-items: center;
  top: 0;
  background-image: url(${(props) => props.background});
  padding: 0 0px;
  box-shadow: 0 0 5px #d0d0d0;
  z-index: 999;
  padding-bottom: 5px;
`;

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cart: state.cart,
    addressState: state.auth.default_address
      ? state.auth.default_address.full_address
      : state.info.address,
    restaurantAddress: state.info.restaurant_address,
    typeOrder: 'Pickup At',
    restaurantID: state.info.restaurant_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
