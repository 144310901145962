import { IonItem } from "@ionic/react";
import React from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import actions from "../../actions/cart";
import actionsLocation from "../../actions/info";

interface RouteProps {
  src?: string;
  name?: string;
  description?: string;
  price?: number;
  history?: any;
  addon?: any;
  match?: any;
  restaurant_id?: string;
  hide_addon?: any;
  addons_category_name?: string;
  restaurant_dish?: any;
  hide_image?: any;
}

const Arrow = styled.div`
  postition: relative;
  width: 8px;
  &:after {
    content: "";
    position: absolute;
    height: 9px;
    width: 9px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(-45deg);
    top: calc(50%);
  }
`;
const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
  font-size: 14px;
  color: #707070;
`;
const Price = styled.div`
  font-size: 15px;
  margin-top: 5px;
`;

const Addon: React.FC<RouteProps> = ({
  restaurant_id,
  hide_addon = false,
  ...props
}) => {
  var {
    id,
    price,
    name,
    is_veg,
    addons_dish,
    description,
    points,
    restaurant_dish,
    stock,
    new_addons_dish,
    active,
    hide_image,
  } = props.addon;
  price = restaurant_dish.price;
  stock = props.restaurant_dish.quantity;
  hide_image ? (hide_image = 1) : (hide_image = -1);
  //Selectors
  const getDishState = (store) => store.cart.dishes ?? [];

  const findDishById = (store, id) => {
    // const index = getDishState(store).findIndex((dish: any) => dish.id === id)
    // const index = getDishState(store).reduce((result, dish) => {
    //     if (dish.id == id)
    //         result.push(dish.id);
    //     return result
    // }, [])
    // let index: any = []
    // getDishState(store).map((dish: any) => {
    //     if (dish.id == id)
    //         index.push(dish.id);
    // })
    const index = getDishState(store)
      .map((dish, index) => (dish.id === id ? index : ""))
      .filter(String);
    const findIndex = getDishState(store)[index[0]];
    return index.length > 0 && findIndex ? findIndex.count * index.length : 0;
  };

  //Redux Hooks
  const count = useSelector((state: RootStateOrAny) => findDishById(state, id));
  const dispatch = useDispatch();

  const increment = () => {
    dispatch(actionsLocation.set_addon_type(props.addons_category_name));
    if (hide_addon == false) {
      dispatch(
        actions.addons_show({
          dish_id: id,
          name,
          is_veg,
          price,
          restaurant_id,
          addons_dish: new_addons_dish,
          description,
          points,
          stock: restaurant_dish.quantity,
          restaurant_dish,
        })
      );
    } else {
      const amount = isNaN(price) ? parseFloat(price.substr(1)) : price;
      dispatch(
        actions.increment({ id, name, is_veg, amount, restaurant_id, points })
      );
    }
  };

  const decrement = () => {
    if (count > 0) dispatch(actions.decrement({ id, restaurant_id }));
  };

  return (
    <IonItem className="ht-ion-item" href="javascript:void(0)" detail={false}>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 20,
          padding: "15px 10px",
          display: "flex",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
          width: "100%",
        }}
        onClick={
          restaurant_dish.active && active && stock > 0 ? increment : () => {}
        }
      >
        {!restaurant_dish.active || !active ? (
          <>
            {hide_image ? null : (
              <div
                style={{
                  width: 100,
                  backgroundColor: "rgba(0,0,0,0.3)",
                  position: "absolute",
                  height: 100,
                  borderRadius: 15,
                  backgroundPosition: "center",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: 15,
                    display: "block",
                    textAlign: "center",
                    lineHeight: "95px",
                  }}
                >
                  HIDDEN
                </span>
              </div>
            )}
            <div
              style={
                hide_image
                  ? {
                      position: "absolute",
                      height: "100%",
                      width: "calc(100% - 0px)",
                      backgroundColor: "#fff",
                      opacity: 0.5,
                      zIndex: 5,
                      left: "0px",
                    }
                  : {
                      position: "absolute",
                      height: "100%",
                      width: "calc(100% - 115px)",
                      backgroundColor: "#fff",
                      opacity: 0.5,
                      zIndex: 5,
                      left: "115px",
                    }
              }
            ></div>
          </>
        ) : null}

        {restaurant_dish.active && stock <= 0 ? (
          <>
            <div
              style={{
                width: 100,
                backgroundColor: "rgba(0,0,0,0.3)",
                position: "absolute",
                height: 100,
                borderRadius: 15,
                backgroundPosition: "center",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 15,
                  display: "block",
                  textAlign: "center",
                  lineHeight: "95px",
                }}
              >
                SOLD OUT
              </span>
            </div>
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "calc(100% - 115px)",
                backgroundColor: "#fff",
                opacity: 0.5,
                zIndex: 5,
                left: "115px",
              }}
            ></div>
          </>
        ) : null}

        {hide_image ? null : (
          <div
            style={{
              backgroundImage: "url(" + props.src + ")",
              height: 100,
              width: 100,
              backgroundSize: "cover",
              borderRadius: 15,
              backgroundPosition: "center",
            }}
          ></div>
        )}
        <div
          style={
            hide_image
              ? { width: "calc(100% - 20px)", padding: "0 10px 0 15px" }
              : { width: "calc(100% - 118px)", padding: "0 10px 0 15px" }
          }
        >
          <h6 style={{ marginBottom: 2, fontWeight: "bold", fontSize: 18 }}>
            {props.name}
          </h6>
          <Ellipsis>{props.description}</Ellipsis>
          {props.price ? <Price>${props.price}</Price> : null}
        </div>
        <Arrow></Arrow>
      </div>
    </IonItem>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Addon);
