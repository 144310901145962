import { Plugins } from "@capacitor/core";
import {
  IonAlert,
  IonContent,
  IonLoading,
  IonPage,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import actions from "../../actions/info";
import Container from "../../components/Shared/Container";
import { push_register } from "../../helpers/push_notification_ionic";
// import { fcmInitialization } from "./helpers/fcm-init";
// reactstrap components

const { Geolocation } = Plugins;
const { Device } = Plugins;

const ChooseType: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);

  const onChooseDelivery = () => {
    // dispatch(actions.set_type("Delivery To"));
    // if (props.auth.user) {
    //   props.history.push("/address");
    // } else {
    //   props.history.push("/choose_restaurant");
    // }
    setShowAlert(true);
  };

  useEffect(() => {
    pushNotifications();
    // PushNotifications.requestPermission().then((result) => {
    //   if (result.granted) {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // Show some error
    //   }
    // });
    // // On success, we should be able to receive notifications
    // PushNotifications.addListener(
    //   "registration",
    //   (token: PushNotificationToken) => {
    //     dispatch(authActions.set_push_token(token.value));
    //     alert("Push registration success, token: " + token.value);
    //   }
    // );

    // // Some issue with our setup and push will not work
    // PushNotifications.addListener("registrationError", (error: any) => {
    //   alert("Error on registration: " + JSON.stringify(error));
    // });

    // // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener(
    //   "pushNotificationReceived",
    //   async (notification: PushNotification) => {
    //     // const notifs = await LocalNotifications.schedule({
    //     //   notifications: [
    //     //     {
    //     //       title: "Title",
    //     //       body: "Body",
    //     //       id: 1,
    //     //       schedule: { at: new Date(Date.now() + 1000 * 5) },
    //     //       actionTypeId: "",
    //     //       extra: null,
    //     //     },
    //     //   ],
    //     // });
    //     // alert("Push received: " + JSON.stringify(notification));
    //   }
    // );

    // // Method called when tapping on a notification
    // PushNotifications.addListener(
    //   "pushNotificationActionPerformed",
    //   (notification: PushNotificationActionPerformed) => {
    //     alert("Push action performed: " + JSON.stringify(notification));
    //   }
    // );
  }, [props.auth?.user?.id]);

  const pushNotifications = async () => {
    const info = await Device.getInfo();
    if (info.platform === "web") {
      //Web FCM
      // fcmInitialization();
    } else {
      //iOS/Android
      push_register(props.history);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <Container>
          <img src="" alt="" />
          <div
            className="text-center text-muted mb-5 px-5 pt-6"
            style={{ marginTop: "calc((100vh - 666px)/2)" }}
          >
            {/* <p className="h3">Welcome</p> */}
            <img
              src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
              alt="Chicko Chicken"
              style={{ maxHeight: "300px" }}
            />
          </div>
          <div
            onClick={() => {
              dispatch(actions.set_type("Pickup At"));
              props.history.push({
                pathname: "/choose_restaurant",
                state: {
                  id: props.auth?.user?.id,
                },
              });
            }}
          >
            <Type backgroundColor="#ae101e">
              <Image
                src={require("../../assets/img/icons/common/bag.svg").default}
                alt=""
              />
              <div>
                <Title>Pickup</Title>
                <div>Pick up at restaurant</div>
              </div>
            </Type>
          </div>

          {/* <Link
            to="/choose-address"
            onClick={() => {
              dispatch(actions.set_type("Delivery To"));
            }}
          > */}
          {/* HIDE DELIVERY PART */}
          {/* <div onClick={onChooseDelivery}>
            <Type backgroundColor="#124898">
              <Image2
                src={require("../../assets/img/icons/common/delivery.svg").default}
                alt=""
              />
              <div>
                <Title>Delivery</Title>
                <div>Delivery to your home</div>
              </div>
            </Type>
          </div> */}

          {/* </Link> */}
        </Container>
        <IonLoading isOpen={props.auth.request} message={"Please wait..."} />
        <IonToast
          isOpen={showAlert}
          onDidDismiss={() => {
            setShowAlert(false);
          }}
          message={"Coming Soon"}
          duration={3500}
          color="primary"
        />
        {/* <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="ht-alert"
          header={"Alert"}
          //subHeader={'Information'}
          message={
            // double.map((v, i) => {
            //   console.log(double);
            //   return v;
            // }) + " must choose two"
            "Coming Soon"
          }
          buttons={["OK"]}
        />{" "} */}
      </IonContent>
    </IonPage>
  );
};

const Type = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  border-radius: 20px;
  padding: 25px 25px 25px 10px;
  display: flex;
  margin: 0px 10px 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  position: relative;
  margin-bottom: 8px;
  &:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 1px;
    background-color: #fff;
    left: 0;
    bottom: -4px;
  }
`;

const Image = styled.img`
  height: 55px;
  margin-right: 15px;
  width: 100px;
  margin-left: 5px;
`;

const Image2 = styled.img`
  width: 75px;
  margin-left: 17px;
  margin-right: 27px;
`;

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    info: state.info,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseType);
