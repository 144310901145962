import {
  IonAlert,
  IonContent,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import styled from "styled-components";
import { alertActions } from "../../actions/alert";
import actions from "../../actions/auth";
import black from "../../assets/img/icons/common/black-giftcard.svg";
import blue from "../../assets/img/icons/common/blue-giftcard.svg";
import red from "../../assets/img/icons/common/red-giftcard.svg";
import yellow from "../../assets/img/icons/common/yellow-giftcard.svg";
import Header from "../../components/Shared/Header";
import GeneralSkeletonText from "../../components/skeleton_text/general_restaurant";

const GiftCards: React.FC = (props: any) => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const [giftcards, setGiftCards] = useState([]);
  const [giftcardsUser, setGiftCardsUser] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [tab, setTab] = useState(0);
  const colors = [red, blue, yellow, black];
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  useIonViewDidEnter(() => {
    // setLoaded(false);
    axios
      .get("coupon/all", {
        params: {
          email: props.user?.email,
        },
      })
      .then((res: any) => {
        setGiftCards(res[0]);
        setGiftCardsUser(res[1]);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        dispatch(alertActions.error("There are errors, please try again!"));
      });
  });
  useEffect(() => {
    setLoaded(false);
    axios
      .get("coupon/all", {
        params: {
          email: props.user?.email,
        },
      })
      .then((res: any) => {
        setGiftCards(res[0]);
        setGiftCardsUser(res[1]);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        dispatch(alertActions.error("There are errors, please try again!"));
      });
  }, [props.user]);

  useEffect(() => {
    if (props.location.tab) {
      setTab(props.location.tab);
      scrollToTop();
      dispatch(alertActions.page_loader(false));
    }
  }, [giftcardsUser]);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  return (
    <IonPage>
      <Header
        name="Gift Cards"
        click={() => props.history.goBack()}
        back={false}
      ></Header>
      <IonContent className="ht-pt-80" ref={contentRef} scrollEvents={true}>
        <div className="padding-status-bar"></div>
        <TopGiftCards className="padding-status-bar">
          <div className="p1">
            <div className={tab == 0 ? "active" : ""} onClick={() => setTab(0)}>
              Purchase
            </div>
            <div className={tab == 1 ? "active" : ""} onClick={() => setTab(1)}>
              My Wallet
            </div>
          </div>
          <div className="p2">
            <div className="p2-1">
              <div className="p2-1-1">Total Balance</div>
              <div className="p2-1-2">{props.user?.coins} COINS</div>
            </div>
            <div className="p2-2">
              <button
                className="p2-2-1"
                onClick={() => {
                  setShowAlert(true);
                }}
              >
                REDEEM
              </button>
            </div>
          </div>
        </TopGiftCards>
        <div style={{ padding: "10px 20px", marginTop: 160 }}>
          {loaded ? (
            tab == 0 ? (
              giftcards.map((val: any, idx) => (
                <GiftCard
                  color={
                    val.discount == 20
                      ? colors[0]
                      : val.discount == 30
                        ? colors[1]
                        : val.discount == 50
                          ? colors[2]
                          : colors[3]
                  }
                  key={idx}
                  tab={tab}
                >
                  <div className="p p1">
                    <img
                      src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
                      alt=""
                      className="logo"
                    />
                    <div className="description">
                      You can use or share this gift card with a friend. This
                      gift card can redeem{" "}
                      <span className="coins">{val.discount} COINS</span>
                    </div>
                  </div>
                  <div className="p p2">
                    <div className="price">
                      <span>$</span>
                      {parseFloat(val.price).toFixed(0)}
                    </div>
                    <button
                      className="purchase"
                      onClick={() =>
                        props.history.push({
                          pathname: "/gift_cards_checkout",
                          state: val,
                        })
                      }
                    >
                      Purchase
                    </button>
                  </div>
                </GiftCard>
              ))
            ) : giftcardsUser.length > 0 ? (
              giftcardsUser.map((val: any, idx) => {
                if (!val.pivot.is_used)
                  return (
                    <GiftCard
                      color={
                        val.discount == 20
                          ? colors[0]
                          : val.discount == 30
                            ? colors[1]
                            : val.discount == 50
                              ? colors[2]
                              : colors[3]
                      }
                      key={idx}
                      tab={tab}
                    >
                      <div className="p p1">
                        <img
                          src={require("../../assets/img/intro/logo-chicko-chicken.png").default}
                          alt=""
                          className="logo"
                        />
                        <div className="description">
                          You can use or share this gift card with a friend.
                          This gift card can redeem{" "}
                          <span className="coins">{val.discount} COINS</span>
                        </div>
                      </div>
                      <div className="p p2">
                        <div className="price">
                          <span>$</span>
                          {parseFloat(val.price).toFixed(0)}
                        </div>
                        <button
                          className="detail"
                          onClick={() =>
                            props.history.push({
                              pathname: "/gift_cards/" + val.id,
                              state: {
                                coupon_code: val.pivot.coupon_code,
                              },
                            })
                          }
                        >
                          DETAILS
                        </button>
                      </div>
                    </GiftCard>
                  );
              })
            ) : (
              <div
                style={{
                  marginTop: "calc(50vh - 250px)",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <h4 className="font-weight-light text-center text-muted">You have no orders</h4> */}
                  <img
                    src={require("../../assets/img/icons/common/empty_giftcard.png").default}
                    style={{ height: 174, width: 174, marginBottom: 20 }}
                    alt="pan"
                  />
                  <div style={{ fontSize: 22, color: "#707175" }}>
                    You have no gift cards here
                  </div>
                  {/* <h1><IonIcon icon={receiptOutline} /></h1> */}
                </Col>
              </div>
            )
          ) : (
            <>
              <GeneralSkeletonText />
              <GeneralSkeletonText />
              <GeneralSkeletonText />
              <GeneralSkeletonText />
              <GeneralSkeletonText />
            </>
          )}
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="ht-alert"
          header={"REDEEM"}
          inputs={[
            {
              name: "code",
              type: "text",
              placeholder: "Type your Giftcard Code",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "Ok",
              handler: (e) => {
                dispatch(
                  actions.redeem(
                    { coupon_code: e.code, user_id: user.id },
                    props.history
                  )
                );
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};
const TopGiftCards = styled.div`
  background-color: #fff;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 50px;
  .p1 {
    display: flex;
    border-bottom: 1px solid #f0f0f0;

    justify-content: center;
    > div {
      width: 115px;
      text-align: center;
      color: #999999;
      padding: 10px 0;
      &.active {
        color: #045ade;
        font-weight: bold;
        border-bottom: 2px solid #045ade;
      }
    }
  }
  .p2 {
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 3px #0000001a;
    .p2-1-2 {
      font-size: 28px;
      font-weight: bold;
    }
    .p2-2-1 {
      color: #fff;
      background-color: #045ade;
      height: 38px;
      width: 122px;
      border-radius: 20px;
    }
  }
`;

const GiftCard = styled.div`
  border-radius: 20px;
  background-image: ${(props) => "url(" + props.color + ")"};
  background-size: 100% 100%;
  padding-left: 63px;
  padding-top: 5px;
  max-width: 374px;
  margin: 0 auto 10px;
  .p {
    display: flex;
    align-items: center;
    &.p1 {
      padding: 10px 0;
      .description {
        color: #707070;
        font-size: 13px;
        padding-right: 20px;
      }
      .coins {
        color: #3b7fd4;
        font-size: 15px;
        font-weight: bolder;
        margin-left: 3px;
      }
    }
    &.p2 {
      padding: 0 25px 0 20px;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      .price {
        font-size: 34px;
        font-weight: bolder;
        span {
          font-size: 27px;
        }
      }
      .purchase {
        height: 35px;
        border-radius: 22px;
        background-color: ${(props) =>
    props.tab == 0 ? "#FF8039" : "#C81021"};
        color: #fff;
        padding: 0 20px;
        font-size: 15px;
      }
      .detail {
        height: 29px;
        width: 90px;
        border-radius: 5px;
        background-color: ${(props) =>
    props.tab == 0 ? "#FF8039" : "#C81021"};
        color: #fff;
        font-size: 13px;
      }
    }
  }
  .logo {
    height: 40px;
    margin: 10px 17px 10px 18px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
