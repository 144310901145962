import {
  IonAlert,
  IonContent,
  IonLabel,
  IonPage,
  IonToast,
  IonDatetime,
  IonItem,
  IonSlides,
  IonSlide,
  IonBackdrop,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonFab,
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container } from "reactstrap";
import actions from "../../actions/cart";
import CartBillings from "../../components/MenuCards/cart-billing";
import CartItems from "../../components/MenuCards/cart-items";
import Header from "../../components/Shared/Header";
import axios from "../../helpers/axiosInterceptor";
import authActions from "../../actions/auth";
import cartActions from "../../actions/cart";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";

const Cart: React.FC = (props: any) => {
  const [data, setData] = useState({
    details: {},
    charges: {},
    address: "",
  } as any);
  const [load, setLoad] = useState(false);
  const [coupon, setCoupon] = useState({
    code: "",
    request: false,
    returnData: false,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [showAlert4, setShowAlert4] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [coins, setCoins] = useState<any>(0);
  const [request, setRequest] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [addReward, setAddReward] = useState(true);
  const [total, setTotal] = useState(0);
  const [hourss, setHourss] = useState<any>("");
  const [minutess, setMinutess] = useState<any>("");
  const [typess, setTypess] = useState<any>("AM");
  const [datess, setDatess] = useState<any>("");
  const [index, setIndex] = useState<any>({
    date: 0,
    hour: 10,
    minute: 6,
    type: 0,
  });
  const [newIndex, setNewIndex] = useState<any>({
    date: 0,
    hour: 10,
    minute: 6,
    type: 0,
  });

  const [showModal, setShowModal] = useState(false);
  const dateRef = useRef<any>(null);
  const hourRef = useRef<any>(null);
  const minuteRef = useRef<any>(null);
  const typeRef = useRef<any>(null);
  // const [dateSwiper, setDateSwiper] = useState<any>({});
  var intervalId;
  const date_list: any[] = [];
  const hour_list: any[] = [];
  const minute_list: any[] = [];
  const type_list: any[] = ["AM", "PM"];
  const year_list: any[] = [];

  for (let i = 0; i < 7; i++) {
    let cooking_time = props.cart.cooking_time;
    let asap = new Date();
    let time_change = props.cart.time_change.split(":");

    let minute_add = Number(time_change[0] * 60) + Number(time_change[1]);
    asap.setMinutes(asap.getMinutes() + Number(cooking_time) + 1 + minute_add);
    let temp_date = convertTZ(asap, props.auth.time_zone);
    temp_date.setDate(temp_date.getDate() + i);
    var dd: any = temp_date.getDate();
    var mm: any = temp_date.getMonth() + 1;
    var yyyy: any = temp_date.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    date_list.push(mm + "/" + dd);
    year_list.push(yyyy);
  }
  for (let i = 0; i < 13; i++) {
    hour_list.push(i < 10 ? "0" + i : i);
  }
  for (let i = 0; i < 60; i++) {
    minute_list.push(i < 10 ? "0" + i : i);
  }
  // const init = async function (this: any) {
  //   setDateSwiper(await this.getSwiper());
  //   console.log(await this.getSwiper());
  // };

  const onDateChange = async (e) => {
    e.target.getActiveIndex().then((res) => {
      setDatess(date_list[res]);
      setNewIndex({ ...newIndex, date: res });
      // dispatch(cartActions.index_time({ ...newIndex, date: res }));
    });
  };

  const onHourChange = (e) => {
    e.target.getActiveIndex().then((res) => {
      setHourss(hour_list[res]);
      setNewIndex({ ...newIndex, hour: res });
      // dispatch(cartActions.index_time({ ...newIndex, hour: res }));
    });
  };

  const onMinuteChange = (e) => {
    e.target.getActiveIndex().then((res) => {
      setMinutess(minute_list[res]);
      setNewIndex({ ...newIndex, minute: res });
      // dispatch(cartActions.index_time({ ...newIndex, minute: res }));
    });
  };

  const onTypeChange = (e) => {
    e.target.getActiveIndex().then((res) => {
      setTypess(type_list[res]);
      setNewIndex({ ...newIndex, type: res });
      // dispatch(cartActions.index_time({ ...newIndex, type: res }));
    });
  };

  const toggle = async () => {
    if (!showModal) {
      let date_swiper = await dateRef.current?.getSwiper();
      let hour_swiper = await hourRef.current?.getSwiper();
      let minute_swiper = await minuteRef.current?.getSwiper();
      let type_swiper = await typeRef.current?.getSwiper();
      if (date_swiper && hour_swiper && minute_swiper && type_swiper) {
        date_swiper.slideTo(index.date);
        hour_swiper.slideTo(index.hour);
        minute_swiper.slideTo(index.minute);
        type_swiper.slideTo(index.type);
      }
    }
    showModal ? setShowModal(false) : setShowModal(true);
  };
  const slide = (e, dir) => {
    if (!e.event.target.parentElement.className.includes("prevent")) {
      if (dir == "Down") {
        setShowModal(false);
      } else if (dir == "Up") {
        setShowModal(true);
      }
    }
  };
  const handlers2 = useSwipeable({
    onSwipedDown: (e) => slide(e, "Down"),
    onSwipedUp: (e) => slide(e, "Up"),
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });
  //Selectors
  const getDishState = (store) => store.cart.dishes;

  // Get index
  const getAllDishes = (store) => {
    const dishes = getDishState(store) ?? [];
    let total = 0;
    let tax = 0;
    dishes.map((dish) => {
      let addons_cost = 0;
      dish.addons_dish.map((d) =>
        d.addons.map((d) => (addons_cost += parseInt(d.price)))
      );
      if (!dish.free)
        return (total += dish.count * dish.restaurant_dish.price + addons_cost);
    });
    dishes.map((dish) => {
      return (tax +=
        (dish.restaurant_dish?.gst_tax
          ? (dish.restaurant_dish.gst_tax / 100) *
            Number(dish.restaurant_dish.price) *
            dish.count
          : 0) +
        (dish.restaurant_dish?.hst_tax
          ? (dish.restaurant_dish.hst_tax / 100) *
            Number(dish.restaurant_dish.price) *
            dish.count
          : 0) +
        (dish.restaurant_dish?.pst_tax
          ? (dish.restaurant_dish.pst_tax / 100) *
            Number(dish.restaurant_dish.price) *
            dish.count
          : 0));
    });
    return { count: dishes.length, total, tax };
  };

  //Redux Hooks
  const basket_restaurant_id = useSelector(
    (state: RootStateOrAny) => state.cart.restaurant_id
  );
  const couponStore = useSelector((state: RootStateOrAny) => state.cart.coupon);
  const sub_total = useSelector((state: RootStateOrAny) => getAllDishes(state));
  const default_address = useSelector(
    (state: RootStateOrAny) => state.auth.default_address
  );
  const currency_symbol = useSelector(
    (state: RootStateOrAny) => state.auth.currency_symbol
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (basket_restaurant_id === "" || basket_restaurant_id === undefined)
      return;
    props.auth?.user?.id &&
      axios
        .get(`/cart/${basket_restaurant_id}`)
        .then((res) => {
          const data = res.data;
          setData({
            details: data.restaurant_details,
            charges: data.charges,
            address: data.address,
          });
          setLoad(true);
        })
        .catch((err) => {
          setLoad(true);
          console.log(err);
        });
  }, [basket_restaurant_id]);

  // const couponSubmit = () => {
  //   if (coupon.code.trim() === "") return;

  //   axios
  //     .post(`/coupon/verify`, { coupon_code: coupon.code })
  //     .then((res) => {
  //       const data = res.data;
  //       setCoupon({ ...coupon, request: true, returnData: res.data });
  //       if (data) dispatch(actions.coupon_add(res.data));
  //       else dispatch(actions.coupon_remove());
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    let temp = true;
    props.cart.dishes?.map((val, idx) => {
      if (val.free) {
        temp = false;
      }
    });
    setAddReward(temp);
    if (coins == 0) {
      setTotal(props.cart.total);
    }
    if (props.cart.dishes.length == 0) {
      setCoins(0);
      setRequest("");
    }

    if (props.cart.total < 0) {
      let temp = Number(coins) + Number(props.cart.total);
      setCoins(Math.floor(temp).toFixed(0));
    }
  }, [props.cart]);

  useEffect(() => {
    setCoins(0);
  }, [props.user?.coins]);

  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }
  const set_time = function () {
    setIndex({
      ...newIndex,
    });
    dispatch(
      cartActions.index_time({
        ...newIndex,
      })
    );

    dispatch(
      actions.change_delivery_time(
        hour_list[newIndex.hour] +
          ":" +
          minute_list[newIndex.minute] +
          " " +
          type_list[newIndex.type] +
          " " +
          date_list[newIndex.date]
      )
    );
  };

  const get_time = function () {
    props.cart?.restaurant_id &&
      axios
        .get("/restaurant/open_time", {
          params: {
            id: props.cart?.restaurant_id,
          },
        })
        .then(async (res: any) => {
          let data = res.data;
          dispatch(
            cartActions.change_time({
              open_time: data.open_time,
              close_time: data.close_time,
            })
          );
          dispatch(authActions.update_point_percentage(data.point_percentage));
          let cooking_time = data.cooking_time;
          dispatch(cartActions.change_cooking_time(data.cooking_time));
          dispatch(cartActions.change_time_change(data.time_change));

          let time_change = data.time_change.split(":");

          let minute_add = Number(time_change[0] * 60) + Number(time_change[1]);

          let asap = new Date();
          asap.setMinutes(
            asap.getMinutes() + Number(cooking_time) + 1 + minute_add
          );
          let date = convertTZ(asap, data.time_zone);
          let day = date.getDate();
          let month = date.getMonth() + 1;

          let hours = date.getHours();
          let minutes = date.getMinutes();
          let type = "AM";

          if (minutes > 59) {
            hours++;
            minutes = minutes - 60;
          }
          if (hours == 12 && hours < 24) {
            type = "PM";
          }
          if (hours > 12 && hours < 24) {
            hours = hours - 12;
            type = "PM";
          }
          if (hours == 24) {
            hours = 12;
            type = "AM";
          } else if (hours > 24) {
            hours = hours - 24;
            type = "AM";
          }
          if (props.cart.delivery_time == "ASAP") {
            setIndex({
              ...index,
              date: 0,
              hour: hours,
              minute: minutes,
              type: type == "AM" ? 0 : 1,
            });
            setNewIndex({
              ...index,
              date: 0,
              hour: hours,
              minute: minutes,
              type: type == "AM" ? 0 : 1,
            });
            let date_swiper = await dateRef.current?.getSwiper();
            let hour_swiper = await hourRef.current?.getSwiper();
            let minute_swiper = await minuteRef.current?.getSwiper();
            let type_swiper = await typeRef.current?.getSwiper();
            if (date_swiper && hour_swiper && minute_swiper && type_swiper) {
              date_swiper.slideTo(0);
              hour_swiper.slideTo(hours);
              minute_swiper.slideTo(minutes);

              type_swiper.slideTo(type == "AM" ? 0 : 1);
            }
          } else {
            setIndex(props.cart.index_time);
            setNewIndex(props.cart.index_time);
            let date_swiper = await dateRef.current?.getSwiper();
            let hour_swiper = await hourRef.current?.getSwiper();
            let minute_swiper = await minuteRef.current?.getSwiper();
            let type_swiper = await typeRef.current?.getSwiper();
            if (date_swiper && hour_swiper && minute_swiper && type_swiper) {
              date_swiper.slideTo(props.cart.index_time?.date);
              hour_swiper.slideTo(props.cart.index_time?.hour);
              minute_swiper.slideTo(props.cart.index_time?.minute);

              type_swiper.slideTo(props.cart.index_time?.type);
            }
          }

          //let fmt = new Intl.DateTimeFormat("en-US", {
          // month: "long",
          // month: "numeric",
          //day: "numeric",
          //});
          dispatch(
            actions.current_time(
              (hours < 10 ? "0" + hours : hours) +
                ":" +
                (minutes < 10 ? "0" + minutes : minutes) +
                " " +
                type +
                " " +
                (month < 10 ? "0" + month : month) +
                "/" +
                (day < 10 ? "0" + day : day)
            )
          );
        });
  };
  // useIonViewDidEnter(() => {
  //   get_time();

  //   intervalId = setInterval(() => {
  //     get_time();
  //   }, 60000);
  //   // if (props.cart.index_time?.date >= 0) {
  //   //   setIndex(props.cart.index_time);
  //   // }
  // }, [props.cart.delivery_time, props.cart.restaurant_id]);

  // useIonViewDidLeave(() => {
  //   clearInterval(intervalId);
  // }, [props.cart.delivery_time, props.cart.restaurant_id]);

  useEffect(() => {
    get_time();
    if (!intervalId) {
      intervalId = setInterval(() => {
        get_time();
      }, 60000);
    }

    // if (props.cart.index_time?.date >= 0) {
    //   setIndex(props.cart.index_time);
    // }

    return () => {
      clearInterval(intervalId);
    };
  }, [props.cart.delivery_time, props.cart.restaurant_id]);
  const onPay = () => {
    let cooking_time = props.cart.cooking_time;

    let time_change = props.cart.time_change.split(":");
    let minute_add = Number(time_change[0] * 60) + Number(time_change[1]);

    let current_datetime = convertTZ(new Date(), props.auth.time_zone);
    current_datetime.setMinutes(current_datetime.getMinutes() + minute_add);

    let hours = current_datetime.getHours();
    let minutes = current_datetime.getMinutes();
    let year = current_datetime.getFullYear();
    let day = current_datetime.getDate();
    let month = current_datetime.getMonth() + 1;
    let type = "AM";

    if (hours >= 12) {
      type = "PM";
      if (hours > 12) hours = hours - 12;
    }

    let open_hour = props.cart.time?.open_time?.split(":")[0];

    let open_minutes: any =
      Number(props.cart.time?.open_time?.split(":")[1].split(" ")[0]) +
      Number(cooking_time);
    if (open_minutes >= 60) {
      open_minutes = Number(open_minutes) - 60;
      open_hour = Number(open_hour) + 1;
    }
    let open_type = props.cart.time?.open_time?.split(":")[1].split(" ")[1];

    if (open_hour > 12) {
      open_hour = open_hour - 12;
    }
    open_hour = open_hour < 10 ? "0" + open_hour : open_hour;
    open_minutes = open_minutes < 10 ? "0" + open_minutes : open_minutes;

    let open_hour_no_cooking = props.cart.time?.open_time?.split(":")[0];
    let open_type_no_cooking = props.cart.time?.open_time
      ?.split(":")[1]
      .split(" ")[1];
    let open_minutes_no_cooking = Number(
      props.cart.time?.open_time?.split(":")[1].split(" ")[0]
    );

    let open_time =
      month +
      "/" +
      day +
      "/" +
      year +
      " " +
      open_hour +
      ":" +
      open_minutes +
      " " +
      open_type;

    let open_time_no_cooking =
      month +
      "/" +
      day +
      "/" +
      year +
      " " +
      open_hour_no_cooking +
      ":" +
      (open_minutes_no_cooking < 10
        ? open_minutes_no_cooking + "0"
        : open_minutes_no_cooking) +
      " " +
      open_type_no_cooking;

    let close_time =
      month + "/" + day + "/" + year + " " + props.cart.time?.close_time;
    let choose_time =
      month +
      "/" +
      day +
      "/" +
      year +
      " " +
      hourss +
      ":" +
      minutess +
      " " +
      (index.type == "0" ? "AM" : "PM");

    let asap_datetime = props.cart.current_time + "/" + year;
    asap_datetime =
      asap_datetime.split(" ")[2] +
      " " +
      asap_datetime.split(" ")[0] +
      " " +
      asap_datetime.split(" ")[1];

    if (props.cart.dishes.length == 1 && props.cart.dishes[0].free) {
      setShowAlert(true);
    } else if (
      props.cart.delivery_time == "" ||
      props.cart.delivery_time == "ASAP"
    ) {
      console.log(Date.parse(asap_datetime), Date.parse(open_time));
      if (Date.parse(open_time) == Date.parse(close_time)) {
        dispatch(actions.change_coins(coins));
        dispatch(actions.change_delivery_to(props.addressState));
        props.history.push("/payment");
      } else if (Date.parse(open_time_no_cooking) < Date.parse(close_time)) {
        if (
          Date.parse(asap_datetime) < Date.parse(open_time_no_cooking) ||
          Date.parse(asap_datetime) > Date.parse(close_time)
        ) {
          setShowAlert2(true);
        } else if (Date.parse(asap_datetime) < Date.parse(open_time)) {
          setShowAlert4(true);
        } else if (Date.parse(asap_datetime) < Date.parse(close_time)) {
          dispatch(actions.change_coins(coins));
          dispatch(actions.change_delivery_to(props.addressState));
          props.history.push("/payment");
        }
      } else {
        if (
          Date.parse(asap_datetime) < Date.parse(open_time_no_cooking) &&
          Date.parse(asap_datetime) > Date.parse(close_time)
        ) {
          setShowAlert2(true);
        } else if (
          Date.parse(asap_datetime) > Date.parse(open_time_no_cooking) &&
          Date.parse(asap_datetime) < Date.parse(open_time)
        ) {
          setShowAlert4(true);
        } else {
          dispatch(actions.change_coins(coins));
          dispatch(actions.change_delivery_to(props.addressState));
          props.history.push("/payment");
        }
      }
    } else {
      if (
        Date.parse(choose_time) + current_datetime.getSeconds() * 1000 <
          current_datetime.getTime() &&
        index.date == 0
      ) {
        setShowAlert3(true);
      } else if (Date.parse(open_time) == Date.parse(close_time)) {
        dispatch(actions.change_coins(coins));
        dispatch(actions.change_delivery_to(props.addressState));
        props.history.push("/payment");
      } else if (Date.parse(open_time_no_cooking) < Date.parse(close_time)) {
        if (Date.parse(choose_time) < Date.parse(open_time_no_cooking)) {
          setShowAlert2(true);
        } else if (
          Date.parse(choose_time) >= Date.parse(open_time_no_cooking) &&
          Date.parse(choose_time) < Date.parse(open_time)
        ) {
          setShowAlert4(true);
        } else if (
          Date.parse(choose_time) >= Date.parse(open_time) &&
          Date.parse(choose_time) <= Date.parse(close_time)
        ) {
          if (
            Date.parse(choose_time) < Date.parse(asap_datetime) &&
            index.date == 0
          ) {
            setShowAlert4(true);
          } else {
            dispatch(actions.change_coins(coins));
            dispatch(actions.change_delivery_to(props.addressState));
            props.history.push("/payment");
          }
        } else {
          setShowAlert2(true);
        }
      } else {
        if (
          Date.parse(choose_time) > Date.parse(close_time) &&
          Date.parse(choose_time) < Date.parse(open_time_no_cooking)
        ) {
          setShowAlert2(true);
        } else if (
          Date.parse(choose_time) >= Date.parse(open_time_no_cooking) &&
          Date.parse(choose_time) < Date.parse(open_time)
        ) {
          setShowAlert4(true);
        } else {
          if (
            Date.parse(choose_time) < Date.parse(asap_datetime) &&
            index.date == 0
          ) {
            setShowAlert4(true);
          } else {
            dispatch(actions.change_coins(coins));
            dispatch(actions.change_delivery_to(props.addressState));
            props.history.push("/payment");
          }
        }
      }
    }
  };

  return (
    <IonPage>
      <Header
        name={props.info.restaurant_address}
        click={() => props.history.goBack()}
      ></Header>
      <IonContent style={{ position: "relative" }}>
        <IonBackdrop visible={showModal} onIonBackdropTap={() => toggle()} />
        {basket_restaurant_id === "" ||
        basket_restaurant_id === undefined ||
        (sub_total.count === 0 && sub_total.total === 0) ? (
          <Container fluid={true} className="h-100 d-flex align-items-center">
            <Col
              style={{
                flexDirection: "column",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <h4 className="font-weight-light text-center text-muted">You have no orders</h4> */}
              <img
                src={require("../../assets/img/icons/common/cart.png").default}
                style={{ height: 174, width: 174, marginBottom: 20 }}
                alt="pan"
              />
              <div style={{ fontSize: 22, color: "#707175" }}>
                No items in your cart
              </div>
              {/* <h1><IonIcon icon={receiptOutline} /></h1> */}
            </Col>
          </Container>
        ) : (
          <>
            <div className="h-flex h-flex__wrap  pt-20 pb-20">
              <div className="col-12 h-pb-5">
                <IonLabel className="h-font-17 h-bold ">Restaurant</IonLabel>
              </div>
              <div className="col-12">
                <div className="h-form-ordertime h-bgr-fff h-flex h-flex__nowrap h-between pt-20 pb-20 h-plr-15">
                  <div className="h-flex h-flex-column">
                    <IonLabel class="h-bold mg-b5">
                      {props.info.restaurant_address}
                    </IonLabel>
                    <IonLabel class="sub-info">
                      {props.info.restaurant_street}
                    </IonLabel>
                  </div>
                  <div className="">
                    <button
                      className="h-button-red h-button_payment_header"
                      onClick={() => props.history.push("/choose_restaurant")}
                    >
                      CHANGE
                    </button>
                  </div>
                </div>
              </div>
              {props.info.type == "Delivery To" ? (
                <>
                  <div className="col-12  pt-15 h-pb-5">
                    <IonLabel className="h-font-17 h-bold">
                      Delivery To
                    </IonLabel>
                  </div>
                  <div
                    className="h-form-ordertime h-plr-15"
                    style={{
                      backgroundColor: "#fff",
                      margin: "0 15px",
                      overflow: "hidden",
                      zIndex: 1,
                    }}
                  >
                    <div className="h-bgr-fff h-flex h-flex__nowrap h-line-e3e3e3 h-between pt-20 pb-20 ">
                      <div className="h-flex h-flex-column">
                        <IonLabel class="h-bold mg-b5">
                          Delivery Address
                        </IonLabel>
                        <IonLabel class="sub-info">
                          {props.user
                            ? props.auth.default_address.full_address
                            : props.info.address}
                        </IonLabel>
                      </div>
                      <div className="">
                        <button
                          className="h-button-red h-button_payment_header"
                          onClick={() => props.history.push("/address")}
                        >
                          CHANGE
                        </button>
                      </div>
                    </div>
                    <div className="pt-20 h-bgr-fff h-flex h-flex__nowrap h-between pb-20 ">
                      <div className="h-flex h-flex-column">
                        <IonLabel class="h-bold mg-b5">
                          Start Delivery Time
                        </IonLabel>
                        <IonLabel class="sub-info">
                          {props.cart.delivery_time != "ASAP"
                            ? props.cart.delivery_time
                            : props.cart.current_time
                            ? props.cart.current_time
                            : ""}
                        </IonLabel>
                      </div>
                      <div className="">
                        <button
                          className="h-button-red h-button_payment_header"
                          onClick={() => toggle()}
                        >
                          CHANGE
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12  pt-15 h-pb-5">
                    <IonLabel className="h-font-17 h-bold">
                      Pickup At Restaurant
                    </IonLabel>
                  </div>
                  <div
                    className="h-form-ordertime"
                    style={{
                      backgroundColor: "#fff",
                      margin: "0 15px",
                      overflow: "hidden",
                      zIndex: 1,
                    }}
                  >
                    <div className=" h-bgr-fff h-flex h-flex__nowrap h-between pt-20 pb-20 h-plr-15">
                      <div className="h-flex h-flex-column">
                        <IonLabel class="h-bold mg-b5">Pickup Time</IonLabel>
                        <IonLabel class="sub-info">
                          {props.cart.delivery_time != "ASAP"
                            ? props.cart.delivery_time
                            : props.cart.current_time
                            ? props.cart.current_time
                            : ""}
                        </IonLabel>
                      </div>
                      <div className="">
                        <button
                          className="h-button-red h-button_payment_header"
                          onClick={() => toggle()}
                        >
                          CHANGE
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="col-12  pt-15 h-pb-5">
                <IonLabel className="h-font-17 h-bold">Item In Cart</IonLabel>
              </div>
              <div className="col-12">
                <div className="h-form-ordertime h-bgr-fff h-flex h-flex__wrap pb-20 h-plr-15">
                  <CartItems currency_symbol={currency_symbol} />
                  {addReward ? (
                    <Link
                      to={{
                        pathname: "/dashboard/rewards",
                        state: {
                          tab: 0,
                        },
                      }}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "1px solid #DDDDDD",
                        paddingTop: 15,
                        marginTop: 10,
                        color: "#045ADE",
                        alignItems: "center",
                        fontSize: 15,
                      }}
                    >
                      <div style={{}}>ADD A REWARD</div>
                      <img
                        src={require("../../assets/img/icons/common/plus.svg").default}
                        alt=""
                      />
                    </Link>
                  ) : null}
                </div>
              </div>
              <div className="col-12  pt-15 h-pb-5">
                <IonLabel className="h-font-17 h-bold">
                  Special Requests
                </IonLabel>
              </div>
              <div className="col-12">
                <div className="h-form-ordertime h-bgr-fff h-flex h-flex__nowrap h-between pt-20 pb-20 h-plr-15">
                  <div
                    className="h-flex h-flex-column j-center2"
                    style={{ width: "100%" }}
                  >
                    <textarea
                      placeholder="Ex. Remove cucumber from BC roll. Additional change may apply"
                      value={
                        props.cart.special_request
                          ? props.cart.special_request
                          : request
                      }
                      onChange={(e) => {
                        setRequest(e.target.value);
                        dispatch(actions.change_request(e.target.value));
                      }}
                      style={{
                        border: "none",
                        backgroundColor: "#fff",
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="">
                    {/* <button className="h-button-red h-button_payment_header">
                      USE
                    </button> */}
                  </div>
                </div>
              </div>
              {props.user && (
                <>
                  {" "}
                  <div className="col-12  pt-15 h-pb-5">
                    <IonLabel className="h-font-17 h-bold">
                      Gift Card Coins
                      <span
                        style={{
                          fontSize: 15,
                          marginLeft: 10,
                          fontWeight: "normal",
                        }}
                      >
                        (Available:{" "}
                        <span style={{ color: "#045ADE" }}>
                          {props.user?.coins ? props.user?.coins : 0}
                        </span>
                        )
                      </span>
                    </IonLabel>
                  </div>
                  <div className="col-12">
                    <div className="h-form-ordertime h-bgr-fff h-flex h-flex__nowrap h-between pt-20 pb-20 h-plr-15">
                      <div className="h-flex h-flex-column j-center">
                        <input
                          type="text"
                          placeholder="Enter amount of coins"
                          value={coins == 0 ? "" : coins}
                          pattern="^[0-9]"
                          min="0"
                          step="1"
                          onChange={(e: any) => {
                            let value = e.target.value
                              .toString()
                              .replace(".", "");

                            if (value > total) {
                              setShowToast2(true);
                            } else if (value <= props.user.coins) {
                              setCoins(value);
                              dispatch(actions.changed());
                              dispatch(actions.change_coins(value));
                            } else {
                              setShowToast(true);
                            }
                          }}
                          style={{ border: "none", backgroundColor: "#fff" }}
                          className="ht-placeholder"
                        />
                      </div>
                      <div className="">
                        {/* <button className="h-button-red h-button_payment_header">
                      USE
                    </button> */}
                      </div>
                    </div>
                  </div>{" "}
                </>
              )}

              <div className="col-12  pt-15 h-pb-5">
                <IonLabel className="h-font-17 h-bold">
                  Invoice Details
                </IonLabel>
              </div>
              <div className="col-12  pb-8">
                {load && (
                  <CartBillings
                    coins={coins}
                    coupon={couponStore}
                    sub_total={sub_total.total}
                    currency_symbol={currency_symbol}
                    charges={data.charges}
                    tax={sub_total.tax}
                  />
                )}
              </div>
            </div>
            <IonFab
              vertical="bottom"
              horizontal="end"
              slot="fixed"
              className="ht-ion-fab"
            >
              <div className="h-btn-alws-bottom button-inner" onClick={onPay}>
                <button
                  type="button"
                  className="btn-border-rdu-30"
                  color="primary"
                >
                  PAYMENT
                </button>
              </div>
            </IonFab>
          </>
        )}
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={"You entered your excess amount."}
          color="primary"
          animated
          duration={3500}
        />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="ht-alert"
          header={"Alert"}
          //subHeader={'Information'}
          message={
            "You can't pay only with a reward in cart. Please add more dishes!"
          }
          buttons={["OK"]}
        />

        <IonAlert
          isOpen={showAlert2}
          onDidDismiss={() => setShowAlert2(false)}
          cssClass="ht-alert"
          header={"Alert"}
          //subHeader={'Information'}
          message={
            "Restaurant is closed at Pickup Time, please choose another order time."
          }
          buttons={["OK"]}
        />
        <IonAlert
          isOpen={showAlert3}
          onDidDismiss={() => setShowAlert3(false)}
          cssClass="ht-alert"
          header={"Alert"}
          //subHeader={'Information'}
          message={
            "You can't select past time, please choose another order time."
          }
          buttons={["OK"]}
        />
        <IonAlert
          isOpen={showAlert4}
          onDidDismiss={() => setShowAlert4(false)}
          cssClass="ht-alert"
          header={"Alert"}
          //subHeader={'Information'}
          message={
            "Restaurant isn't ready at this time, please choose another order time."
          }
          buttons={["OK"]}
        />
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message={"You entered in excess of your order total."}
          color="primary"
          animated
          duration={3500}
        />
      </IonContent>
      <SwipeContainer {...handlers2} type={showModal.toString()}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 20,
            top: 0,
            zIndex: 5,
          }}
          onClick={() => {
            toggle();
          }}
        ></div>
        {/* <div className="gray-bar"></div> */}
        <div className="ht-header-datetime">
          <div className="ht-title">
            Choose{" "}
            {props.info.type == "Delivery To"
              ? "Start Delivery Time"
              : "Pickup Time"}
          </div>
          <div style={{ fontSize: 14, color: "#5d5d5d" }}>
            {"Opening hours: " +
              props.cart.time.open_time +
              " - " +
              props.cart.time.close_time}
          </div>
          <div style={{ fontSize: 14, color: "#5d5d5d" }}>
            {"Cooking time: " + props.cart.cooking_time + "mins"}
          </div>
          <div style={{ fontSize: 14, color: "#5d5d5d" }}>
            ASAP: {props.cart.current_time}
          </div>
        </div>
        <div className="ht-datetime">
          <div className="ht-date">
            <IonSlides
              // onIonSlidesDidLoad={init}
              ref={dateRef}
              pager={true}
              options={{
                initialSlide: props.cart.index_time?.date
                  ? props.cart.index_time.date
                  : index.date,
                loop: false,
                speed: 500,
                pagination: false,
                direction: "vertical",
              }}
              className="ht-slide"
              onIonSlideDidChange={(e) => onDateChange(e)}
            >
              {date_list.map((v, i) => (
                <IonSlide key={i}>
                  <span>{v}</span>
                </IonSlide>
              ))}
            </IonSlides>
          </div>

          <div className="ht-time">
            <IonSlides
              ref={hourRef}
              pager={true}
              options={{
                initialSlide: props.cart.index_time?.hour
                  ? props.cart.index_time.hour
                  : index.hour,
                loop: false,
                speed: 500,
                pagination: false,
                direction: "vertical",
              }}
              className="ht-slide"
              onIonSlideDidChange={(e) => onHourChange(e)}
            >
              {hour_list.map((v, i) => (
                <IonSlide key={i}>
                  <span>{v}</span>
                </IonSlide>
              ))}
            </IonSlides>
            <IonSlides
              ref={minuteRef}
              pager={true}
              options={{
                initialSlide: props.cart.index_time?.minute
                  ? props.cart.index_time.minute
                  : index.minute,
                loop: false,
                speed: 500,
                pagination: false,
                direction: "vertical",
              }}
              className="ht-slide"
              onIonSlideDidChange={(e) => onMinuteChange(e)}
            >
              {minute_list.map((v, i) => (
                <IonSlide key={i}>
                  <span>{v}</span>
                </IonSlide>
              ))}
            </IonSlides>
            <IonSlides
              ref={typeRef}
              pager={true}
              options={{
                initialSlide: props.cart.index_time?.type
                  ? props.cart.index_time.type
                  : index.type,
                loop: false,
                speed: 500,

                pagination: false,

                direction: "vertical",
              }}
              className="ht-slide"
              onIonSlideDidChange={(e) => onTypeChange(e)}
            >
              <IonSlide key={0}>
                <span>AM</span>
              </IonSlide>
              <IonSlide key={1}>
                <span>PM</span>
              </IonSlide>
            </IonSlides>
          </div>
        </div>
        <div className="ht-choose">
          <button
            onClick={() => {
              toggle();
              dispatch(actions.change_delivery_time("ASAP"));
            }}
          >
            USE ASAP
          </button>
          <button
            onClick={() => {
              toggle();
              set_time();
            }}
          >
            CONFIRM
          </button>
        </div>
      </SwipeContainer>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth?.user,
    info: state.info,
    cart: state.cart,
    addressState: state.auth.default_address
      ? state.auth.default_address.full_address
      : state.info.address,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const SwipeContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.type.toString() == "true" ? "0" : "-414px")};
  width: 100%;
  background: #fff;
  z-index: 2200;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  height: 340px;
  .gray-bar {
    width: 43px;
    height: 4px;
    background-color: #bfbfbf;
    margin-top: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
